import {
  AvaliabilityResponse,
  CatalogueItem,
  DeliveryProspectResponse,
  PaginationResource,
  QuantityAvailabilityPerDate,
  RecentSearchResponse
} from 'types'
import Cookies from 'universal-cookie'
import { api, responseErrorCheck } from './api'

export default class Catalogue {
  //Get list of recent searched items
  static recentSearch() {
    const cookies = new Cookies()
    return api
      .get<RecentSearchResponse[]>('catalogue/erpusers/' + cookies.get('loggedErpUser') + '/recentsearch')
      .then((res) => responseErrorCheck(res))
  }

  //Add item to list of recent searched items
  static postRecentSearch(itemId: string, itemDescription: string) {
    const cookies = new Cookies()
    return api
      .post<RecentSearchResponse>('catalogue/erpusers/' + cookies.get('loggedErpUser') + '/recentsearch', {
        itemId,
        itemDescription,
      })
      .then((res) => responseErrorCheck(res))
  }

  //Delete searched item from list of recent searched items
  static deleteRecentSearch(itemId: string) {
    const cookies = new Cookies()
    return api
      .delete<string>('catalogue/erpusers/' + cookies.get('loggedErpUser') + '/recentsearch', {
        itemId,
      })
      .then((res) => responseErrorCheck(res))
  }

  //Get FULL catalogue
  static fullCatalogueSearch(page: Number, itemPerPage: Number, searchString: string) {
    var url =
      'catalogue/items?page=' + page +
      '&itemPerPage=' + itemPerPage +
      '&searchString=' + searchString
    var url1 = url.replace('[', '%5B')
    var url2 = url1.replace(']', '%5D')
    return api
      .get<PaginationResource<CatalogueItem>>(url2)
      .then((res) => responseErrorCheck(res))
  }

  //Get LIMITED catalogue
  static limitedCatalogueSearch(page: Number, itemPerPage: Number, searchString: string) {
    const cookies = new Cookies()
    var searchString1 = searchString.replace('[', '%5B')
    var searchString2 = searchString1.replace(']', '%5D')
    return api
      .get<PaginationResource<CatalogueItem>>(
        'catalogue/erpuser/' + cookies.get('loggedErpUser') +
        '/items?page=' + page +
        '&itemPerPage=' + itemPerPage +
        '&searchString=' + searchString2
      )
      .then((res) => responseErrorCheck(res))
  }

  //Get technical sheet in selected language
  static getTechnicalSheet(itemCode: string, language: string) {
    return api
      .get<string>('catalogue/item/' + itemCode + '/technicalsheet/' + language)
      .then((res) => responseErrorCheck(res))
  }

  //Get current lots and bulk meters availability
  static getCurrentAvailability(
    itemCode?: string,
    variantCode?: string,
    erpUserCode?: string,
    limitedCatalogue?: boolean) {
    var url =
      'catalogue/item/' + itemCode + '/currentavailability' +
      '?variantCode=' + variantCode +
      '&erpUserCode=' + erpUserCode +
      '&limitedCatalogue=' + limitedCatalogue
    return api
      .get<AvaliabilityResponse>(url)
      .then((res) => responseErrorCheck(res))
  }

  //Get future lots and bulk meters availability 
  static getFutureAvailability(
    itemCode?: string,
    variantCode?: string,
    erpUserCode?: string,
    limitedCatalogue?: boolean) {
    var url =
      'catalogue/item/' + itemCode + '/futureavailability' +
      '?variantCode=' + variantCode +
      '&erpUserCode=' + erpUserCode +
      '&limitedCatalogue=' + limitedCatalogue
    return api
      .get<QuantityAvailabilityPerDate[]>(url)
      .then((res) => responseErrorCheck(res))
  }


  // static getDeliveryProspect(
  //   itemCode?: string,
  //   variantCode?: string,
  //   erpUserCode?: string,
  //   bulkMeters?: number,
  //   limitedCatalogue?: boolean,
  //   futureAvailability?: boolean) {
  //   var url =
  //     'catalogue/item/' + itemCode + '/bulkDeliveryProspect' +
  //     '?variantCode=' + variantCode +
  //     '&erpUserCode=' + erpUserCode +
  //     '&bulkMeters=' + bulkMeters +
  //     '&limitedCatalogue=' + limitedCatalogue +
  //     '&futureAvailability=' + futureAvailability
  //   return api
  //     .get<DeliveryProspectResponse>(url)
  //     .then((res) => responseErrorCheck(res))
  // }


  //Get availability prospect based on bulk meters requested
  static getDeliveryProspect(
    itemCode?: string,
    availability?: QuantityAvailabilityPerDate[],
    meters?: number) {
    var url =
      'catalogue/item/' + itemCode + '/bulkDeliveryProspect'
    return api
      .post<DeliveryProspectResponse>(url, { availability, meters })
      .then((res) => responseErrorCheck(res))
  }
}
