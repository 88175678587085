import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import Spacer from 'components/Spacer';
import { Drawer, Select } from 'antd';
import Orders from 'api/Orders';
import { AllOrderResponse, OrderByDomain, ORDER_STATUSES, PaginationResource } from 'types';
import { EmptyRow, OrderRow } from 'components';
import { OrderHistoryDrawer } from 'components/Drawer';

import { ReactComponent as LoadingIcons } from 'assets/loading.svg';
import i18n from 'translations/i18n';
import { showError } from 'utils';

export default function OrderHistoryPage() {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const [myOrders, setMyOrders] = useState<PaginationResource<AllOrderResponse> | undefined>(undefined);
  const [sortModifier, setSortModifier] = useState<string>('DESC');
  const [clickedValue, setClickedValue] = useState<string>();
  const [shipmentValue, setShipmentValue] = useState<string>();
  const [externalDocumentNo, setExternalDocumentNo] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [visible, setVisible] = useState<boolean>(false);

  const [clientHeight, setClientHeight] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [maxPageNumber, setMaxPageNumber] = useState<number>(0);

  const [retrieving, setRetrieving] = useState<boolean>(false);

  const getOrders = async (pageNumber: number, sortModifier?: string) => {
    try {
      setLoading(true);
      setRetrieving(true);
      const res = await Orders.getOrders(pageNumber, 50, sortModifier);

      if (res) setMaxPageNumber(Number(res.totalPageNumber) - 1);
      setLoading(false);

      if (pageNumber !== 0 && myOrders && res) {
        let _pagResource: PaginationResource<AllOrderResponse> = myOrders;
        _pagResource.itemPerPage = res.itemPerPage;
        _pagResource.pageNumber = res.pageNumber;
        _pagResource.totalItemNumber = res.totalItemNumber;
        _pagResource.totalPageNumber = res.totalPageNumber;
        _pagResource.elements = myOrders?.elements.concat(res.elements);
        setMyOrders(_pagResource);
        setLoading(false);

        if (scrollRef && scrollRef.current){
          if (clientHeight !== 0) {
            scrollRef.current.scrollTop = clientHeight;
          }
        }

      } else setMyOrders(res);
      setRetrieving(false);
      setLoading(false);
    } catch (error) {
      showError(i18n.t('orderHistoryPage.error_retrieve'));
      setLoading(false);
    };
  };

  useEffect(() => {
    getOrders(0, sortModifier); 
    // eslint-disable-next-line
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const getOrderStatus = (value: string): any => {
    switch (value) {
      case ORDER_STATUSES.SPEDITO:
        return '' + i18n.t('orderHistoryPage.orderStatus.spedito');
      case ORDER_STATUSES.INSERITO:
        return '' + i18n.t('orderHistoryPage.orderStatus.inserito');
      case ORDER_STATUSES.SPEDITO_PARZIALE:
        return '' + i18n.t('orderHistoryPage.orderStatus.spedito_parziale');
      case ORDER_STATUSES.APPROVATO:
        return '' + i18n.t('orderHistoryPage.orderStatus.approvato');
      case ORDER_STATUSES.PROMESSA:
      case ORDER_STATUSES.RICHIESTA:
        return '' + i18n.t('orderHistoryPage.orderStatus.in_reparazione');
      default:
        return '-';
    };
  };

  const handleScroll = (e: any) => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= 1;
    setClientHeight(e.target.scrollTop);
    if (bottom && pageNumber < maxPageNumber && !retrieving) {
      setRetrieving(true);
      setPageNumber(pageNumber + 1);
      getOrders(pageNumber + 1, sortModifier);
    };
  };

  return (
    <Container>
      <Content>
        <Title>{i18n.t('orderHistoryPage.page_title')}</Title>
        <Spacer /> <Spacer /> <Spacer />
        <SelectRow>
          <SelectOrderBy
            showSearch
            placeholder={i18n.t('orderHistoryPage.select.order_by.placeholder')}
            optionFilterProp="children"
            onChange={(value: string) => {
              if (!value.match(sortModifier)) {
                setPageNumber(0);
                setMyOrders(undefined);
              }
              setSortModifier(value);
              getOrders(0, value);
            }}
            defaultValue={OrderByDomain[1].value}
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            { OrderByDomain.map((o, index) => {
              return (<Option key={index} value={o.value}>{i18n.t(o.label)}</Option>);
            })}
          </SelectOrderBy>
        </SelectRow>

        <HeadersContainer>
          <TextBox><HeaderColName>{i18n.t('orderHistoryPage.header_columns.order')}</HeaderColName></TextBox>
          <TextBox><HeaderColName>{i18n.t('orderHistoryPage.header_columns.delivery_date')}</HeaderColName></TextBox>
          <TextBox><HeaderColName>{i18n.t('orderHistoryPage.drawer.externalDocumentNo')}</HeaderColName></TextBox>
          <TextBox><HeaderColName>{i18n.t('orderHistoryPage.header_columns.status')}</HeaderColName></TextBox>
        </HeadersContainer>

        <Scrollbar ref={scrollRef} onScroll={handleScroll}>
          {!loading &&
            myOrders &&
            myOrders.elements.length > 0 &&
            myOrders.elements.map((n, index) => {
              return (
                <OrderRow
                  key={index}
                  onClick1={() => {
                    showDrawer()
                    setClickedValue(n.documentNumber)
                    setShipmentValue(n.expressCourierShipment)
                    setExternalDocumentNo(n.externalDocumentNo)
                    setEmail(n.email)
                  }}
                  number={n.documentNumber}
                  date={n.documentDate}
                  mode={getOrderStatus(n.status)}
                  externalDocumentNo={n.externalDocumentNo}
                />
              )
            })}
          {loading && <LoadingIcons />}
          {!loading && myOrders && myOrders.elements.length === 0 && <EmptyRow text="No Orders" />}
        </Scrollbar>
      </Content>
      <Drawer title="" width={'30vw'} height={'410px'} placement="right" onClose={onClose} visible={visible}>
        <OrderHistoryDrawer
          documentNumber={clickedValue}
          expressShipment={shipmentValue}
          externalDocumentNo={externalDocumentNo}
          email={email}
        />
      </Drawer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const Content = styled.div`
  background-color: white;
  margin-left: 9.444vw;
  margin-top: 8vh;
`;

const HeadersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  width: 51vw;
  margin-bottom: 1vw;
`;

const TextBox = styled.div`
  width: 13vw;
  text-align: center;
`;

const Title = styled.h3`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;

  display: flex;
  align-items: center;

  color: #000000;
  margin-top: 56px;
  margin-left: 2.222vw;
`;

const HeaderColName = styled.h3`
  width: 13vw;
  margin: auto;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw;
  text-align: center;
  color: #000000;
`;

const SelectOrderBy = styled(Select)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //padding: 0px 8px;
  padding: 1.5%;
  width: 10.278vw;
  height: 5vh;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 24px;
  align-items: center;
  margin-left: 2.222vw;
  cursor: pointer;
`;

const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

const Scrollbar = styled.div`
  overflow-Y: scroll; 
  height: 50vh;
  width: 53vw;
`;