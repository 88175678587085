import styled from 'styled-components'
import { AutoComplete, Input } from 'antd'
import { useEffect, useRef, useState } from 'react'
import back from 'assets/backbutton.png'
import { EmptyRow, Spacer } from 'components'
import Catalogue from 'api/Catalogue'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import { CatalogueItem, CATALOGUE_VISIBILITY, PaginationResource, RecentSearchResponse } from 'types'
import { AppStore } from 'utils'
import i18n from 'translations/i18n'
import legenda from 'assets/legenda.png'
import { Subject, debounceTime } from 'rxjs'
import RecentSearchComponent from 'components/RecentSearchComponent'
import ProductListComponent from 'components/ProductListComponent'


const searchObservable = new Subject<string>();
const searchChange$ = searchObservable.asObservable();

export default function DisponibilityPage() {
  const [loading, setLoading] = useState(false)
  const [recentSearch, setRecentSearch] = useState<RecentSearchResponse[]>()
  const [fullCatalogue, setFullCatalogue] = useState<PaginationResource<CatalogueItem>>()

  const [isDefaultSearch, setIsDefaultSearch] = useState<boolean>(true)
  const [searchedValue, setSearchedValue] = useState<string>('')

  const [options, setOptions] = useState<{ value: string }[]>([])
  const [value, setValue] = useState<string>('')
  const [clientHeight, setClientHeight] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [maxPageNumber, setMaxPageNumber] = useState(0)
  const [retrieving, setRetrieving] = useState<boolean>(false)

  const mockVal = (str: string, key: string) => ({
    key: key,
    value: str,
  })

  const onSearch = (searchText: string) => {
    setPageNumber(0)
    setMaxPageNumber(0)
    setFullCatalogue(undefined)
    if (!searchText) return
    setValue(searchText)
    setIsDefaultSearch(false)
    setSearchedValue(searchText)
    getFullCatalogue(0, searchText)
  }

  const onChange = (searchText: string) => {
    setValue(searchText)
    searchObservable.next(searchText)
  }

  const scrollRef = useRef<null | HTMLDivElement>(null)

  const handleScroll = (e: any) => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= 1
    //mi salvo l'altezza per scrollare a quel punto
    setClientHeight(e.target.scrollTop)
    if (bottom && pageNumber < maxPageNumber && !retrieving) {
      setRetrieving(true)
      //incremento il numero di pagina
      setPageNumber(pageNumber + 1)
      //contatto il backend per avere la pagina numero "pageNumber"
      getFullCatalogue(pageNumber + 1, value)
    }
  }

  const getOptionsToDisplay = async (numberPage: number, searchText: string) => {
    if (!searchText) return
    setValue(searchText)
    setIsDefaultSearch(false)
    setSearchedValue(searchText)
    try {
      var res
      if (AppStore.loggedUser.catalogueVisibility !== CATALOGUE_VISIBILITY.NORMAL)
        res = await Catalogue.fullCatalogueSearch(numberPage, 50, searchText)
      else res = await Catalogue.limitedCatalogueSearch(numberPage, 50, searchText)
      setOptions(
        res.elements.map((v: CatalogueItem, index) => {
          return mockVal(v.matched === 'C' && v.crossreferenceNo ? v.crossreferenceNo : v.variantDescription, index)
        })
      )
    } catch (error) { }
  }

  // limited indica la CATALOGUE_VISIBILITY, isAutoCompleteRequest indica se la risposta va salvata sullo stato di AutoComplete o meno
  const getFullCatalogue = async (numberPage: number, value: string) => {
    try {
      setLoading(true)
      var res
      if (AppStore.loggedUser.catalogueVisibility !== CATALOGUE_VISIBILITY.NORMAL)
        res = await Catalogue.fullCatalogueSearch(numberPage, 50, value)
      else res = await Catalogue.limitedCatalogueSearch(numberPage, 50, value)

      if (res) setMaxPageNumber(Number(res.totalPageNumber) - 1)

      if (numberPage !== 0 && fullCatalogue) {
        var _pagResource: PaginationResource<CatalogueItem> = fullCatalogue
        _pagResource.itemPerPage = res.itemPerPage
        _pagResource.pageNumber = res.pageNumber
        _pagResource.totalItemNumber = res.totalItemNumber
        _pagResource.totalPageNumber = res.totalPageNumber
        _pagResource.elements = fullCatalogue?.elements.concat(res.elements)
        setFullCatalogue(_pagResource)
        setLoading(false)

        if (scrollRef && scrollRef.current) {
          if (clientHeight !== 0) {
            scrollRef.current.scrollTop = clientHeight
          }
        }

      } else setFullCatalogue(res)
      setRetrieving(false)
      setLoading(false)
    } catch (error) { }
  }

  const getRecentSearch = async () => {
    try {
      setLoading(true)
      const res = await Catalogue.recentSearch()
      setRecentSearch(res)
      setLoading(false)
    } catch (error) { }
  }

  const postRecentSearch = async (itemId: string, itemDescription: string) => {
    try {
      await Catalogue.postRecentSearch(itemId, itemDescription)
    } catch (error) { }
  }

  const getWebDisponibilitySearch = (searchText: string) => {
    if (searchText === undefined) {
      return
    }
    if (searchText.length >= 3) {
      getOptionsToDisplay(0, searchText)
      getFullCatalogue(0, searchText)
    } else {
      setOptions([])
    }
  }

  const handleClickProductFromRecentSearch = (itemId: string, itemDescription: string): void => {
    setFullCatalogue(undefined)
    getFullCatalogue(0, itemDescription)
    setSearchedValue(itemDescription)
    setIsDefaultSearch(false)
    setValue(itemDescription)
    postRecentSearch(itemId, itemDescription)
  }

  useEffect(() => {
    const subscription = searchChange$.pipe(debounceTime(500)).subscribe(term => getWebDisponibilitySearch(term))
    setPageNumber(0)
    setMaxPageNumber(0)
    getRecentSearch()
    setSearchedValue('')
    setIsDefaultSearch(true)
    return () => {
      return subscription.unsubscribe()
    }
    // eslint-disable-next-line
  }, [])


  return (
    <>
      <Container>
        <div>
          {/* la legenda ora deve apparire solo nelle defaultSearch */}
          {!isDefaultSearch && <Legenda src={legenda}></Legenda>}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isDefaultSearch && (
              <BackButtonContainer
                onClick={() => {
                  setPageNumber(0)
                  setMaxPageNumber(0)
                  setFullCatalogue(undefined)
                  getRecentSearch()
                  setIsDefaultSearch(true)
                  setValue('')
                }}
              >
                <BakcButton src={back}></BakcButton>
              </BackButtonContainer>
            )}
            {isDefaultSearch && (
              <>
                <Spacer /><Spacer /><Spacer /><Spacer /><Spacer />
              </>
            )}
            <AutoComplete
              allowClear
              value={value}
              onSelect={(e) => {
                onSearch(e)
              }}
              onChange={(e) => {
                onChange(e)
              }}
              dropdownMatchSelectWidth={500}
              options={options}
            >
              <HeaderInput
                onSearch={(e) => {
                  getOptionsToDisplay(0, e)
                }}
                onPressEnter={(e) => {
                  onSearch(e.target.value)
                }}
                size="large"
                placeholder={i18n.t('availabilityPage.main.input_placeholder')}
              />
            </AutoComplete>
          </div>
          {/* SONO NELLE RICERCHE RECENTI */}
          {isDefaultSearch && (
            <>
              <HeaderText>{i18n.t('availabilityPage.main.title_1')}</HeaderText>
              <div style={{ overflowY: 'scroll', height: '65vh', width: '40vw' }}>
                {!loading &&
                  <RecentSearchComponent
                    recentSearch={recentSearch!}
                    handleClickProductFromRecentSearch={handleClickProductFromRecentSearch}
                  />
                }
                {!loading && recentSearch?.length === 0 && <EmptyRow text="Nessuna ricerca recente" />}
                {loading && <LoadingIcons />}
              </div>
            </>
          )}
          {/* SONO NELLA LISTA DELLE VARIANTI DEL PRODOTTO */}
          {!isDefaultSearch && (
            <>
              <HeaderText>{i18n.t('availabilityPage.main.title_2') + searchedValue}</HeaderText>
              {/* <div
                ref={scrollRef}
                onScroll={handleScroll}
                style={{ overflowY: 'scroll', height: '65vh', width: '40vw' }}
              >
                {!loading &&
                  fullCatalogue?.elements.map((n: CatalogueItem, index) => (
                    <ProductRow
                      key={index}
                      onClick={() => {
                        showDrawer()
                        setDrawerValue(n)
                        postRecentSearch(
                          n.articleNo,
                          n.matched === 'C' && n.crossreferenceNo ? n.crossreferenceNo : n.articleDescription
                        )
                      }}
                      element={n}
                    />
                  ))}
                {!loading && fullCatalogue?.elements.length === 0 && (
                  <p style={{ marginLeft: '40px' }}>
                    <EmptyRow text="Nessun valore trovato" />
                  </p>
                )}
                {loading && <LoadingIcons />}
              </div> */}
              <div
                ref={scrollRef}
                onScroll={handleScroll}
                style={{ overflowY: 'scroll', height: '65vh', width: '40vw', paddingBottom: '1%' }}
              >
                <ProductListComponent
                  loading={loading}
                  productList={fullCatalogue!}
                  itemToSearch={value}
                  postRecentSearch={postRecentSearch}
                  handlePageNumber={setPageNumber}
                  getFullCatalogue={getFullCatalogue}
                  handleScroll={handleScroll}
                />
              </div>
            </>
          )}
        </div>
      </Container>
    </>
  )
}

const Container = styled.div`
  background-color: white;
  margin-left: 10vw;
  margin-top: 8vh;
`
const HeaderText = styled.h3`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 56px;
  margin-left: 35px;

  width: 38vw; 
  overflow-wrap: anywhere;
  line-height: 1.2;
`
const HeaderInput = styled(Input.Search)`
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 0.7vw 1.4vw !important;
  width: 33.958vw !important;
  margin-left: 0.8vw;

  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16) !important;
  border-radius: 2vw !important;

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;
  color: #28293d;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: red !important;
  }
  :-ms-input-placeholder {
    color: red !important;
  }
`
const BakcButton = styled.img`
  width: 0.7vw;
`
const Legenda = styled.img`
  position: absolute;
  top: 5vw;
  left: 75vw;
  width: 10vw;
`
const BackButtonContainer = styled.div`
  cursor: pointer;
  width: 3vw;
  height: 3vw;

  display: inherit;
  justify-content: center;
  place-items: center;

  background: #ffffff;
  /* Drop Base */

  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 2vw;

  text-align: center;
`
