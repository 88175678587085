import { DatePicker } from 'antd'
import 'moment/locale/it'
import 'moment/locale/es'
import 'moment/locale/en-gb'
import localeIT from "antd/es/date-picker/locale/it_IT"
import localeEN from 'antd/es/date-picker/locale/en_GB'
import localeES from 'antd/es/date-picker/locale/es_ES'
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { getBusinessDays, getDefaultDateValue } from 'utils/deliveryDateCalculator'

export default function DatePickerComponent({
    leadTime,
    onChange,
    startDate,
    bulkMetersCovered
}: {
    leadTime?: number,
    onChange?: (dateSelected) => void,
    startDate?: Date,
    bulkMetersCovered?: boolean
}) {

    const cookies = new Cookies()

    if (bulkMetersCovered === undefined) {
        return (
            <>
                {/* Datepicker for lot batches */}
                <StyledDatePicker
                    locale={cookies.get('language') === 'it' ? localeIT : (cookies.get('language') === 'en' ? localeEN : localeES)}
                    format={'DD/MM/YYYY'}
                    showToday={false}
                    allowClear={false}
                    defaultValue={moment(new Date()).add(getBusinessDays(3), 'days')}
                    disabledDate={
                        (current: moment.Moment) => {
                            return current > moment(new Date()).add(getBusinessDays(45), 'days') ||
                                current < moment(new Date()).add(getBusinessDays(2), 'days') ||
                                moment(current).day() === 0 ||
                                moment(current).day() === 6;
                        }
                    }
                    onChange={onChange}
                />
            </>
        )
    }

    return (
        <>
            {/* Datepicker for bulk meters in case availability covers the entire request */}
            {bulkMetersCovered && (
                <StyledDatePicker
                    locale={cookies.get('language') === 'it' ? localeIT : (cookies.get('language') === 'en' ? localeEN : localeES)}
                    format={'DD/MM/YYYY'}
                    showToday={false}
                    allowClear={false}
                    defaultValue={getDefaultDateValue(startDate!, 3)}
                    disabledDate={
                        (current: moment.Moment) => {
                            return current < getDefaultDateValue(startDate!, 2) ||
                                moment(current).day() === 0 ||
                                moment(current).day() === 6 ||
                                current > getDefaultDateValue(startDate!, 45);
                        }
                    }
                    onChange={onChange}
                />)
            }
            {/* Datepicker for bulk meters in case availability doesn't cover the entire request */}
            {!bulkMetersCovered && (
                <StyledDatePicker
                    locale={cookies.get('language') === 'it' ? localeIT : (cookies.get('language') === 'en' ? localeEN : localeES)}
                    format={'DD/MM/YYYY'}
                    showToday={false}
                    allowClear={false}
                    defaultValue={getDefaultDateValue(startDate!, leadTime!)}
                    disabledDate={
                        (current: moment.Moment) => {
                            return current < getDefaultDateValue(startDate!, leadTime! - 1) ||
                                moment(current).day() === 0 ||
                                moment(current).day() === 6 ||
                                current > getDefaultDateValue(startDate!, leadTime!).add(45, 'days');
                        }
                    }
                    onChange={onChange}
                />)
            }

        </>
    )
}

const StyledDatePicker = styled(DatePicker)`
    margin-top: -2%;
    border-radius: 12px;
    padding: 5px;
`