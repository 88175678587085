/* eslint-disable import/no-anonymous-default-export */
export default {
  login_page: {
    login_card: {
      title: 'Bienvenidos',
      input_username: {
        label: 'Codigo de usuario',
        value: 'Ingrese su código de usuario aquí',
      },
      input_password: {
        label: 'Contraseña',
        value: 'Ingrese su contraseña aquí',
      },
      forgotten_password: 'Contraseña olvidada? Solicitar una nueva',
      contact_support: '¿Necesitas ayuda con algo? Póngase en contacto con asistencia aquí',
      button: 'Acceder',
    },
    forgotten_password: {
      title: 'Solicitud de contraseñad',
      success: 'Contraseña solicitada con éxito!',
      input_name: {
        label: 'Primer nombre',
        value: 'Tu nombre',
      },
      input_surname: {
        label: 'Apellido',
        value: 'Tu apellido',
      },
      input_company: {
        label: 'Sociedad',
        value: 'La empresa para la que trabajas',
      },
      input_email: {
        label: 'Email',
        value: 'Tu email',
      },
      button: 'Solicitud de contraseña',

      error_user_not_found:"No se puede encontrar el usuario",
      error_email_format: 'Formato de correo electrónico incorrecto',
      error_form_not_complete: 'Rellene todos los campos'
    },
    contact_us: {
      title: 'Contáctenos',
      success: 'Contraseña solicitada con éxito!',
      success_send_mail: 'Solicitud de asistencia enviada con éxito!',
      input_name: {
        label: 'Primer nombre',
        value: 'Tu nombre',
      },
      input_surname: {
        label: 'Apellido',
        value: 'Tu apellido',
      },
      input_company: {
        label: 'Sociedad',
        value: 'La empresa para la que trabajas',
      },
      input_email: {
        label: 'Dirección de correo electrónico',
        value: 'Tu correo electrónico',
      },
      input_type_support: {
        label: 'Tipo de asistencia solicitada',
        value: "El asunto del correo electrónico",
      },
      subject_options: {
        forgot_password: 'Solicitar una nueva contraseña',
        new_user: 'Registro de nuevo usuario',
        other: 'Otro',
      },
      input_message: {
        label: 'Mensaje',
        value: 'Tu mensaje',
      },
      button: 'Envía',

      error_fill_form: 'Rellenar todos los campos',
      error_email_format: 'Formato de el email incorrecto',
      error_user_not_found: 'No se puede encontrar el usuario entre los usuarios registrados',
      error_support_mail: '¡Ha ocurrido un error! No se puede enviar el correo electrónico',
      error_no_subject_or_msg: 'No hay asunto o mensaje insertado',
    },
    erp_user: {
      title: 'Bienvenidos',
      select_erp_user: {
        label: 'Utilidades ERP',
        value: 'Codigo',
      },
      button: 'Acceder',

      error_no_selection:'Elija un usuario ERP para proceder'
    }
  },

  sidebar: {
    title: 'Hola',
    menu: {
      home: 'Home',
      dashboard: 'Dashboard',
      availability: 'Disponibilidad web',
      cart: 'Mis pedidos',
      order_history: 'Pedidos antiguos',
      documentary: 'Documental',
      logistic_documents: 'Documentos logísticos',
      logout: 'Logout',
      admin: 'Funcionalidad de administrador',
    },
  },
  dashboard_page: {
    title: 'Tu dashboard',
    subtitle: 'Aquí puede ver el progreso de sus pedidos',
    select_filter_by: {
      placeholder: 'Filtrar por',
      option_amount: 'Monto',
      option_quantity: 'Cantidad'
    },
    select_filter_by_code: {
      placeholder: 'Filtrar por código'
    },
    select_filter_by_purchase: {
      placeholder: 'Filtrar por compra'
    }
  },

  orderHistoryPage: {
    page_title: 'Tus pedidos antiguos',
    error_retrieve: '¡Se ha producido un error! No puede recuperar sus pedidos anteriores',
    select: {
      search: 'Buscar',
      order_by: {
        placeholder: 'Ordenar por',
        asc: 'Fecha ascendente',
        desc: 'Fecha descendente',
      },
    },
    header_columns: {
      order: 'Pedido',
      delivery_date: 'Fecha de pedido',
      status: 'Estado del pedido',
    },
    orderStatus: {
      inserito: 'Enterado',
      spedito_parziale: 'Parcialmente enviado',
      approvato: 'Aprobado',
      spedito: 'Enviado',
      in_preparazione: 'En elaboración',
    },
    deliveryDateStatus: {
      estimated_date: 'Fecha estimada',
      not_available: 'no disponible'
    },
    drawer: {
      title: 'Pedido n.',
      product_column: 'Producto',
      delivery_date_column: 'Fecha de entrega',
      meters_column: 'Metros',
      total_meters: 'Metros totales',
      express_shipment: 'Envío a través de mensajerío expreso',
      standard_shipment: 'Envío standard',
      externalDocumentNo: 'N. documento externo',
    },
  },

  availabilityPage: {
    main: {
      title_1: 'Tus búsquedas recientes',
      input_placeholder: 'Buscar articulos',
      left_text_button: 'búsqueda',
      title_2: 'Resultados de: ',
      delete_message: '¿Quieres eliminar esta búsqueda?',

      error_delete_recent_search: ' No se puede eliminar la línea de búsqueda',
      error_item_availability: "¡Se ha producido un error! No se puede recuperar la disponibilidad del artículo",
    },
    drawer: {
      title: 'Tela ',
      title_1: 'Descarga la ficha comercial',
      title_2: 'Piezas disponibles',
      label_short_lots: 'Piezas disponibles con metros limitados',
      button_2: 'Ver piezas disponibles',
      button_2_1: 'No piezas disponibles',

      subtitle_2: '',
      title_3: 'En llegada',
      button_3: 'Ver disponibilidad futura',
      button_3_1: 'No se encontró disponibilidad futura',
      left_button: 'Metros',
      right_button: 'Disponibilidad',
      //button: 'Añadir a pedidos ',
      button: 'Continuar',
      label_batches: 'Lotes seleccionados',
      label_meters: 'Metros',
      label_discount: '!! 30% de descuento !!',

      error_msg: 'Ningún lote seleccionado o metros insertado',

      //modal_content: 'La disponibilidad no cubre el pedido deseado.',
    },
    sub_drawer_1: {
      title: 'Piezas totales disponibles: ',
      subtitle_1: 'N. de partida',
      subtitle_2: 'N. de pieza',
      subtitle_3: 'Metros',
      column_support: 'Altura completa',
      button: 'Añadir <n> metros',
      qty_selectable: 'Cantidad seleccionable: ',
      qty_selected: 'Cantidad seleccionada: ',

      modal_title: 'Atención',
      modal_content: 'Cantidad máxima seleccionable superada. El último lote seleccionado se desactivará.',
    },
    sub_drawer_2: {
      title: 'Próxima disponibilidad',
      subtitle_1: 'Fecha',
      subtitle_2: 'Disponibilidad total',
    },
    sub_drawer_3: {
      title_1: 'Lotes seleccionados',
      title_short_lots: 'Lotes con metros limitados',
      title_2: 'Metros a granel',
      label_prospect_dates: 'Fecha',
      label_delivery: 'Fecha de entrega',
      label_status: 'Estado',
      status_immediateAvailability: 'disponibilidad inmediata',
      status_futureAvailability: 'disponibilidad futura',
      status_remainings: 'no disponible',
      label_total: 'Total',
      //tooltip_delivery_info: 'La data di consegna potrebbe subire variazioni',
      tooltip_delivery_info: 'La fecha de entrega está sujeta a cambios debido al tiempo de devolución del artículo en stock.',
      button_add_to_cart: 'Añadir al carrito ',

      support_disclaimer: 'lote disponible en en un soporte diferente al preferido',
      error_cart: '¡Se ha producido un error! No se puede crear un carrito',
      info_add_to_cart: 'Artículos añadidos correctamente al carrito',
      error_add_to_cart: '¡Se ha producido un error! Artículos no añadidos al carrito',
      error_nothing_to_add: 'No hay metros o lotes para poner en el carrito',
      modal_content: 'Algunos de los lotes seleccionados están disponibles en un medio diferente al preferido. ¿Aún desea continuar con la adición al carrito?',
      modal_cancel_button: "Cancelar"
    },
  },

  cartPage: {
    title: 'Tus pedidos',
    subtitle1: 'Producto',
    subtitle2: 'N. piezas/Cant',
    subtitle3: 'Fecha de entrega',
    cart_row: ' piezas | Tot.  ',
    cart_delete: '¿Quieres eliminar el producto?',
    cart_delete_response: 'Producto eliminado',
    cart_empty: 'Carrito vacío',
    info_confirm: 'Gracias por su pedido!',
    error_confirm: '¡Se ha producido un error! No se puede enviar el pedido',
    error_delete_row: '¡Se ha producido un error! No se puede eliminar la línea de orden',
    error_email_format: "Formato de correo electrónico incorrecto",

    modal_title: '¡Gracias por su compra!',
    modal_content: "El pedido se ha procesado correctamente",

    drawer: {
      title1: 'Metros totales',
      title2: 'Numero de piezas',
      title3: 'Metros de lotes',
      title4: 'Metros que quedan',
      button: 'Confirmar',
      express_shipment: 'Envío a través de mensajerío expreso',
      external_document: 'N. documento externo',
    },
  },

  documentalPage: {
    title: 'Documental',
    category: {
      category1: 'N. Documento',
      category2: 'Fecha del documento',
      category3: 'Download',
    },
    input_docType: {
      order: 'Pedidos',
      ddt: 'DDT',
      packinglist: 'Lista de bultos',
      invoice: 'Facturas',
    },
  },

  customersPage: {
    searchbar_placeholder: 'Busca',
    checkbox_active: 'Activos',
    checkbox_inactive: 'Inhabilitados',
    button_add_user: 'Añadir usuario',

    option_expired_password: 'CADUCIDAD DE LA CONTRASEÑA',
    option_company: 'SOCIEDAD',

    button_extended: 'Ampliado',
    button_limited: 'Limitado',
    button_expiration_days: '30+ días',
    button_days: 'días',

    drawer_password: {
      title: 'La contraseña caducará en',
      subtitle_days: 'días',
      button_generate: 'Genera nueva contraseña',
      button_confirm: 'Confirmar',

      error_password: '¡Se ha producido un error! No se puede generar una nueva contraseña',
      info_modify_password: 'Contraseña modificada con éxito',
      error_modify_password: '¡Se ha producido un error! Contraseña no modificada',
    },

    drawer_language: {
      title1: 'Idioma en uso: ',
      italian: 'ITALIANO',
      english: 'INGLÉS',
      spanish: 'ESPAÑOL',
      title2: 'Asignar un idioma: ',
      button_confirm: 'Confirmar',

      info_changed_language: 'Idioma correctamente modificado en: ',
      error_add_user: '¡Se ha producido un error! No se puede insertar nuevo usuario',
    },

    drawer_info: {
      title1: 'Información de perfil con ID: ',
      label_name: 'Nombre',
      label_surname: 'Apellido',
      label_email: 'Email',
      label_business_name: 'Sociedad',
      title2: 'Tipo de perfil',
      label_erp_users: 'Usuarios ERP',
      button_confirm: 'Confirmar',
      placeholder_erp_users: 'Busque aquí su usuario ERP',

      info_modify_user: 'Usuario modificado con éxito',
      error_modify_user: '¡Se ha producido un error! No se puede guardar la información del usuario modificado',
      error_erp_users: '¡Se ha producido un error! No se puede cargar usuarios de ERP',

      modal_title: '¡Atención! ',
      modal_context: 'Estás cambiando el rol del usuario',
      modal_okText: 'Entiendo',
      modal_okType: 'danger',
    },

    customer_row: {
      info_active: 'El estado Activo del usuario ha cambiado correctamente',
      error_active: '¡Se ha producido un error! No se puede modificar el estado del usuario',
      info_limited: 'Modificación satisfactoria de la visibilidad del catálogo del usuario',
      error_limited: '¡Se ha producido un error! No se puede modificar la visibilidad del catálogo del usuario',
    }

  },

  domain: {
    select_order_by: {
      date: {
        asc: 'Fecha ascendente',
        desc: 'Fecha discendente',
      }
    },
  },

  homePage: {
    greeting: 'Bienvenido a'
  },

  misc: {
    erps_selected: 'Usuarios seleccionados',
  },
  error: {
    user_not_valid: 'Usuario no válido',
    password_not_valid: 'Password no válida',
    user_or_password_wrong: 'Usuario o contraseña incorrectos',
  },
  warning: {
    warning_cart_two_minutes: '¡El carrito se vaciará en menos de 2 minutos!',
    warning_cart_ten_seconds: '¡El carrito se vaciará en 10 segundos!',
  },
  messages: {},
  placeholder: {},
  confirm: {},
  labels: {},
}
