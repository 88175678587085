import Users from 'api/Users'
import MultipleSelection from 'components/MultipleSelection'
import Spacer from 'components/Spacer'
import { useEffect, useState } from 'react'
import { ErpUsers, User } from 'types'
import { showError } from 'utils'

export default function AddUserPageStep2({
  newUser, setNewUser,
  selectedErps,
  setSelectedErps
}: {
  newUser: User
  setNewUser: (user: User) => void
  selectedErps: string[]
  setSelectedErps: React.Dispatch<React.SetStateAction<string[]>>
}) {

  const [erpUsers, setErpUsers] = useState<ErpUsers[]>([])

  const convertErpUsers = (e: string) => {
    if (e.length === 0) {
      setSelectedErps([])
      return
    }
    var newErpUsers: ErpUsers[] = []
    var erpSelected: string[] = []
    const erpArray: string[] = String(e).split(',')
    for (let i = 0; i < erpArray.length; i++) {
      var newErp = {
        code: erpArray[i].substring(0, 6),
        name: erpArray[i].substring(7, erpArray[i].length)
      }
      erpSelected.push(erpArray[i].substring(0, 6) + ' - ' + erpArray[i].substring(7, erpArray[i].length))
      newErpUsers.push(newErp)
    }
    setSelectedErps(erpSelected)
    setNewUser({ ...newUser, erpUsers: newErpUsers })
  }

  const getErpUsers = async (value: string, page: string, itemPerPage: string) => {
    try {
      const res = await Users.getErpUserByName(value, page, itemPerPage)
      setErpUsers(res.elements)
    } catch (error) {
      showError('Impossibile scaricare gli utenti erp')
    }
  }

  useEffect(() => {
    getErpUsers('', '0', '3000')
  }, [])

  return (
    <>
      <MultipleSelection
        showSearch={true}
        mode="multiple"
        defaultValues={selectedErps}
        onChangeSelection={(e) => {
          convertErpUsers(e)
          //setNewUser({ ...newUser, erpUsers: e })
        }}
        title="Utenze ERP"
        erpUsersArray={erpUsers}
        placeholder="Cerca qui l'utenza ERP"
        popOver={true}
        selected={selectedErps}
      ></MultipleSelection>
      <Spacer /> <Spacer /> <Spacer />
    </>
  )
}
