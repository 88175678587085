import { api } from 'api/api'
import Auth from 'api/Auth'
import { LANGUAGES, User } from 'types'

class AppStore {
  token?: string | null
  loggedUser!: User
  loggedErpUser!: string

  async init() {
    this.token = await localStorage.getItem('token')
    if (this.token) {
      api.setHeader('Authorization', `Bearer ${this.token}`)
      try {
        this.loggedUser = await Auth.loggedUser()
      } catch (error) {
        api.setHeader('Authorization', '')
      }
    }
  }

  async setToken(token?: string) {
    this.token = token
    if (token) {
      await localStorage.setItem('token', token)
      api.setHeader('Authorization', `Bearer ${token}`)
    } else await localStorage.removeItem('token')
  }

  getLanguageValue() {
    return this.loggedUser.language === LANGUAGES.ITALIAN
      ? 'it'
      : this.loggedUser.language === LANGUAGES.ENGLISH
      ? 'en'
      : 'es'
  }
}

export default new AppStore()
