import styled from 'styled-components'
import itaLanguage from 'assets/iconsUserRow/it.png'
import enLanguage from 'assets/iconsUserRow/en.png'
import esLanguage from 'assets/iconsUserRow/es.png'
import Spacer from 'components/Spacer'
import ButtonDrawer from 'components/ButtonDrawer'
import { Button, Drawer, InputNumber } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import {
  AvaliabilityResponse,
  BatchesSelected,
  CatalogueItem,
  CheckedLists,
  IndexedLotAvailability,
  LotAvailabilityInfo,
  LotsMetersSelectable,
  LotsMetersSelected,
  LotsSelected,
  LOT_TYPES,
  QuantityAvailabilityPerDate,
} from 'types'
import { AppStore, showError } from 'utils'
import Catalogue from 'api/Catalogue'
import i18n from 'translations/i18n'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import FutureAvailabilityDrawer from './FutureAvailabilityDrawer'
import RecapDrawer from './RecapDrawer'
import LotAvailabilityDrawer from './LotsAvailabilityDrawer'
import _ from 'lodash'

export default function ResearchDrawer({
  objectClicked,
  loggedErpUser,
}: {
  objectClicked?: CatalogueItem
  loggedErpUser: string
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [lotAndAvailabilityResponse, setLotAndAvailabilityResponse] = useState<AvaliabilityResponse | undefined>()
  const [discount, setDiscount] = useState<number>(0)
  const [futureAvailability, setFutureAvailability] = useState<QuantityAvailabilityPerDate[] | undefined>(undefined)

  const [descriptionToDisplay, setDescriptionToDisplay] = useState<string>('')
  const [today, setToday] = useState<string>('')
  const [drawerSelected, setDrawerSelected] = useState('')
  const [visible, setVisible] = useState(false)

  const [bulkMeters, setBulkMeters] = useState<string>('0')
  const [totalMetersToShip, setTotalMetersToShip] = useState<string>('0')

  const [batches, setBatches] = useState<BatchesSelected>({
    longLength: [],
    shortLength: [],
  })
  const [lotNumber, setLotNumber] = useState<LotsSelected>({
    longLength: '0',
    shortLength: '0',
  })
  const [lotMeters, setLotMeters] = useState<LotsMetersSelected>({
    longLength: '0',
    shortLength: '0',
  })
  const [LotCheckedList, setLotCheckedList] = useState<CheckedLists>({
    longLength: [],
    shortLength: [],
  })
  const [selectableLotMeters, setSelectableLotMeters] = useState<LotsMetersSelectable>({
    longLength: '0',
    shortLength: '0',
  })

  const getAndSetAvailability = useCallback(async () => {
    try {
      setLoading(true)
      const limitedCatalogue = AppStore.loggedUser.catalogueVisibility === 'NORMAL' ? true : false
      Catalogue.getCurrentAvailability(
        objectClicked?.articleNo,
        objectClicked?.variantCode,
        loggedErpUser,
        limitedCatalogue
      )
        .then((res) => {
          setLotAndAvailabilityResponse(res)
          setDiscount(res.lotAvailability.shortLots.discount)
          setBulkMeters('0')
          setTotalMetersToShip('0')
          setLoading(false)
          setBatches(() => ({
            longLength: [],
            shortLength: [],
          }))
          setLotCheckedList(() => ({
            longLength: [],
            shortLength: [],
          }))
          setLotNumber(() => ({
            longLength: '0',
            shortLength: '0',
          }))
          setLotMeters(() => ({
            longLength: '0',
            shortLength: '0',
          }))
          setSelectableLotMeters(() => ({
            longLength: res.lotAvailability.longLots.totalLength.toFixed(2),
            shortLength: res.lotAvailability.shortLots.totalLength.toFixed(2),
          }))
          const description =
            objectClicked?.matched === 'C' ? objectClicked.crossreferenceNo : objectClicked?.variantDescription
          setDescriptionToDisplay(description!)
          setLoading(false)
        })
        .catch(() => {
          showError(i18n.t('availabilityPage.main.error_item_availability'))
          setLotAndAvailabilityResponse(undefined)
          setLoading(false)
        })
      //setLotAndAvailabilityResponse(currentAvailabilityRes)

      Catalogue.getFutureAvailability(
        objectClicked?.articleNo,
        objectClicked?.variantCode,
        loggedErpUser,
        limitedCatalogue
      )
        .then((res) => {
          setFutureAvailability(res)
        })
        .catch((error) => {
          //console.log(error)
        })
      //setFutureAvailability(futureAvailabilityRes)
    } catch (error) {
      //showError(i18n.t('availabilityPage.main.error_item_availability'))
      showError('Errore')
      setLoading(false)
    }
  }, [objectClicked, loggedErpUser])

  const getBulkAvailability = (fullBulkAvailability: boolean): QuantityAvailabilityPerDate[] => {
    const currentBulkAvailability = lotAndAvailabilityResponse?.quantityAvailability![0]
    var bulkAvailability: QuantityAvailabilityPerDate[] = fullBulkAvailability
      ? _.cloneDeep(lotAndAvailabilityResponse?.quantityAvailability!)
      : []
    if (!futureAvailability) return bulkAvailability
    for (let i = 0; i < futureAvailability!.length; i++) {
      if (futureAvailability![i].quantity !== currentBulkAvailability!.quantity) {
        bulkAvailability.push(futureAvailability![i])
      }
    }
    return bulkAvailability
  }

  const getSelectableMeters = (totalLotAvailability: number | undefined, lotType: LOT_TYPES): void => {
    const totalLotMeters: number = Number(lotMeters.longLength) + Number(lotMeters.shortLength)
    const residualAvailability: number =
      Number(lotAndAvailabilityResponse?.quantityAvailability[0].quantity) - totalLotMeters
    var availabilityToCompare =
      totalLotAvailability! < residualAvailability ? totalLotAvailability! : residualAvailability
    if (lotType.match(LOT_TYPES.LONG)) {
      let newValue =
        Number(selectableLotMeters.longLength) < availabilityToCompare
          ? Number(selectableLotMeters.longLength)
          : availabilityToCompare
      setSelectableLotMeters((prevState) => ({
        ...prevState,
        longLength: newValue.toFixed(2),
      }))
    }
    if (lotType.match(LOT_TYPES.SHORT)) {
      let newValue =
        Number(selectableLotMeters.shortLength) < availabilityToCompare
          ? Number(selectableLotMeters.shortLength)
          : availabilityToCompare
      setSelectableLotMeters((prevState) => ({
        ...prevState,
        shortLength: newValue.toFixed(2),
      }))
    }
  }

  const handleTotalLotMeters = (newValue: number, lotType: LOT_TYPES): void => {
    if (lotType.match(LOT_TYPES.LONG)) {
      setLotMeters((prevState) => ({
        ...prevState,
        longLength: newValue.toFixed(2),
      }))
    }
    if (lotType.match(LOT_TYPES.SHORT)) {
      setLotMeters((prevState) => ({
        ...prevState,
        shortLength: newValue.toFixed(2),
      }))
    }
  }

  const handleTotalLotNumber = (newValue: number, lotType: LOT_TYPES): void => {
    if (lotType.match(LOT_TYPES.LONG)) {
      setLotNumber((prevState) => ({
        ...prevState,
        longLength: newValue.toFixed(2),
      }))
    }
    if (lotType.match(LOT_TYPES.SHORT)) {
      setLotNumber((prevState) => ({
        ...prevState,
        shortLength: newValue.toFixed(2),
      }))
    }
  }

  const handleSelectableLotMeters = (newValue: number, lotType: LOT_TYPES): void => {
    if (lotType.match(LOT_TYPES.LONG)) {
      setSelectableLotMeters((prevState) => ({
        ...prevState,
        longLength: newValue.toFixed(2),
      }))
    }
    if (lotType.match(LOT_TYPES.SHORT)) {
      setSelectableLotMeters((prevState) => ({
        ...prevState,
        shortLength: newValue.toFixed(2),
      }))
    }
  }

  const showDrawer = (availableLotMeters?: number, lotType?: LOT_TYPES) => {
    if (availableLotMeters) {
      getSelectableMeters(availableLotMeters, lotType!)
    }
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }

  const onClickLanguage = (e) => {
    if (objectClicked) getTechnicalSheet(objectClicked.articleNo, e)
  }

  const getTechnicalSheet = async (itemCode: string, language: string) => {
    try {
      const res = await Catalogue.getTechnicalSheet(itemCode, language)
      downloadPDF(res, itemCode, language)
    } catch (error) {
      showError('Impossibile scaricare pdf')
    }
  }

  const onOpenRecapDrawer = async () => {
    if (bulkMeters !== '0' || batches.longLength.length !== 0 || batches.shortLength.length !== 0) {
      setTotalMetersToShip(bulkMeters)
      showDrawer()
      setDrawerSelected('recap')
    }
  }

  const downloadPDF = (pdf: string, itemCode: string, language: string) => {
    showPdfInNewTab(pdf, itemCode + '_' + language + '.pdf')
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement('a')
    const fileName = itemCode + '_' + language + '.pdf'
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  const showPdfInNewTab = (base64Data, fileName) => {
    let pdfWindow = window.open('')
    pdfWindow?.document.write(
      '<html<head><title>' + fileName + '</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>'
    )
    pdfWindow?.document.write(
      "<body><embed width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(base64Data) +
        "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>"
    )
  }

  const getIndexedLotAvailability = (lotAvailability: LotAvailabilityInfo[]): IndexedLotAvailability[] => {
    var indexedLotAvailability: IndexedLotAvailability[] = []
    for (var i = 0; i < lotAvailability!.length; i++) {
      let lotAvailable: IndexedLotAvailability = {
        index: i,
        batch: lotAvailability![i].batch,
        lot: lotAvailability![i].lotNumber,
        meter: Number(lotAvailability![i].quantity),
        support: lotAvailability![i].support,
      }
      indexedLotAvailability.push(lotAvailable)
    }

    return indexedLotAvailability
  }

  useEffect(() => {
    setFutureAvailability(undefined)
    getAndSetAvailability()
    setToday(
      new Date().toLocaleDateString('it-IT', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'Europe/Rome',
      })
    )

    return () => {
      setLotAndAvailabilityResponse(undefined)
    }
  }, [objectClicked, getAndSetAvailability, setFutureAvailability])

  const isSameDate = (value1?: string): boolean => {
    return value1 === today
  }

  const isThereAvailabilityToday = (): boolean => {
    return isSameDate(
      new Date(lotAndAvailabilityResponse?.quantityAvailability[0].dateAvailable!).toLocaleDateString('it-IT', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'Europe/Rome',
      })
    )
      ? Number(lotAndAvailabilityResponse?.quantityAvailability[0].quantity) > 0
        ? true
        : false
      : false
  }

  const isThereFutureAvailability = (): boolean => {
    if (!futureAvailability) return false
    return getBulkAvailability(false).length > 0
  }

  const showDiscountLabel = (): string => {
    var discountLabel: string = ''
    if (AppStore.getLanguageValue().match('it')) {
      discountLabel = '!! Sconto ' + discount + '% !!'
    } else {
      if (AppStore.getLanguageValue().match('en')) {
        discountLabel = '!! ' + discount + '% discount !!'
      } else {
        discountLabel = '!! ' + discount + '% de descuento !!'
      }
    }
    return discountLabel
  }

  // --
  //const showModal = () => { setModalVisibility(true) }

  // --
  // const closeModal = () => {
  //   setModalVisibility(false)
  // }

  return (
    <CustomDrawer>
      {!loading && (
        <>
          <DrawerTitle>{i18n.t('availabilityPage.drawer.title') + descriptionToDisplay}</DrawerTitle>
          <DrawerSubtitle>{objectClicked?.articleNo}</DrawerSubtitle>
          <Spacer />
          <DrawerSubsubtitle>{i18n.t('availabilityPage.drawer.title_1')}</DrawerSubsubtitle>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '-3%' }}>
            <CustomButton>
              <img style={{ width: '2vw' }} onClick={(e) => onClickLanguage('ITALIAN')} src={itaLanguage} alt="" />
            </CustomButton>
            <CustomButton>
              <img style={{ width: '2vw' }} onClick={(e) => onClickLanguage('ENGLISH')} src={enLanguage} alt="" />
            </CustomButton>
            <CustomButton disabled>
              <img style={{ width: '2vw' }} src={esLanguage} alt="" />
            </CustomButton>
            <Spacer />
          </div>
          <Spacer />
          {/**Bottone per le disp matricolate */}
          <DrawerTitleSmallLeft>{i18n.t('availabilityPage.drawer.title_2')}</DrawerTitleSmallLeft>
          <ButtonDrawer
            style={{ margin: 'auto' }}
            text={
              isThereAvailabilityToday() && lotAndAvailabilityResponse?.lotAvailability.longLots.list.length !== 0
                ? i18n.t('availabilityPage.drawer.button_2')
                : i18n.t('availabilityPage.drawer.button_2_1')
            }
            onClick={() => {
              if (lotAndAvailabilityResponse?.lotAvailability.longLots.list.length === 0) {
                return
              }
              if (isThereAvailabilityToday()) {
                showDrawer(lotAndAvailabilityResponse?.lotAvailability.longLots.totalLength, LOT_TYPES.LONG)
                setDrawerSelected('avaliable')
              }
            }}
            disabled={false}
          ></ButtonDrawer>
          <DrawerSubsubtitle>
            {/* {i18n.t('availabilityPage.drawer.label_batches')}: {lotNumbers} | {i18n.t('availabilityPage.drawer.label_meters')}: {Number(lotMeters).toFixed(2)}m */}
            {i18n.t('availabilityPage.drawer.label_batches')}: {parseInt(lotNumber.longLength)} |{' '}
            {i18n.t('availabilityPage.drawer.label_meters')}: {Number(lotMeters.longLength).toFixed(2)}m
          </DrawerSubsubtitle>
          <Spacer />
          {/**Nuovo bottone disp pezze corte */}
          <DrawerTitleSmallLeft>{i18n.t('availabilityPage.drawer.label_short_lots')}</DrawerTitleSmallLeft>
          {discount > 0 && <DiscountLabel>{showDiscountLabel()}</DiscountLabel>}
          <ButtonDrawer
            style={{ margin: 'auto' }}
            text={
              lotAndAvailabilityResponse?.lotAvailability.shortLots.list.length !== 0
                ? i18n.t('availabilityPage.drawer.button_2')
                : i18n.t('availabilityPage.drawer.button_2_1')
            }
            onClick={() => {
              if (lotAndAvailabilityResponse?.lotAvailability.shortLots.list.length === 0) {
                return
              }
              if (isThereAvailabilityToday()) {
                showDrawer(lotAndAvailabilityResponse?.lotAvailability.shortLots.totalLength, LOT_TYPES.SHORT)
                setDrawerSelected('discount')
              }
            }}
            disabled={false}
          ></ButtonDrawer>
          <DrawerSubsubtitle>
            {i18n.t('availabilityPage.drawer.label_batches')}: {parseInt(lotNumber.shortLength)} |{' '}
            {i18n.t('availabilityPage.drawer.label_meters')}: {Number(lotMeters.shortLength).toFixed(2)}m
          </DrawerSubsubtitle>
          {/*Bottone per le disp future*/}
          <DrawerTitleSmallLeft>{i18n.t('availabilityPage.drawer.title_3')}</DrawerTitleSmallLeft>
          <ButtonDrawer
            style={{ margin: 'auto' }}
            withLoader={futureAvailability === undefined}
            text={
              isThereFutureAvailability()
                ? i18n.t('availabilityPage.drawer.button_3')
                : i18n.t('availabilityPage.drawer.button_3_1')
            }
            onClick={() => {
              if (isThereFutureAvailability()) {
                showDrawer()
                setDrawerSelected('future')
              }
            }}
            disabled={false}
          ></ButtonDrawer>
          <Spacer /> <Spacer />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <div>
              <DrawerTitleSmall>{i18n.t('availabilityPage.drawer.left_button')}</DrawerTitleSmall>
              <InputNumber
                min={'0'}
                max={'999999999999'}
                defaultValue={bulkMeters}
                parser={(value) => (value! === '' ? value!.replace('', '0') : value!)}
                onChange={(e) => {
                  if (typeof e === 'string' || e === null) {
                    setBulkMeters(0 + '')
                  } else {
                    if (String(e).includes('.')) {
                      setBulkMeters(
                        parseFloat(String(e).split('.')[0] + '.' + String(e).split('.')[1].slice(0, 2)).toFixed(2)
                      )
                    } else {
                      setBulkMeters(e + '')
                    }
                  }
                }}
              />
            </div>
            <div>
              <DrawerTitleSmall>{i18n.t('availabilityPage.drawer.right_button')}</DrawerTitleSmall>
              {lotAndAvailabilityResponse?.quantityAvailability.length! > 0 && (
                <DrawerSubsubtitle>
                  {/* Se la quantità disponibile del giorno è negativa mostra 0.00m */}
                  {isSameDate(
                    new Date(lotAndAvailabilityResponse?.quantityAvailability[0].dateAvailable!).toLocaleDateString(
                      'it-IT',
                      { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'Europe/Rome' }
                    )
                  )
                    ? Number(lotAndAvailabilityResponse?.quantityAvailability[0].quantity) > 0
                      ? Number(lotAndAvailabilityResponse?.quantityAvailability[0].quantity).toFixed(2) + 'm'
                      : '0.00m'
                    : '0.00m'}
                </DrawerSubsubtitle>
              )}
              {lotAndAvailabilityResponse?.quantityAvailability.length! === 0 && (
                <DrawerSubsubtitle>0.00m</DrawerSubsubtitle>
              )}
            </div>
          </div>
          <Spacer />
          {/* Bottone che apre la tendina riepilogo */}
          {(bulkMeters !== '0' || batches.longLength.length !== 0 || batches.shortLength.length !== 0) && (
            <ButtonDrawer
              text={i18n.t('availabilityPage.drawer.button')}
              style={{ margin: 'auto' }}
              onClick={() => {
                if (
                  (bulkMeters !== '0' || batches.longLength.length !== 0 || batches.shortLength.length !== 0) &&
                  futureAvailability !== undefined
                ) {
                  onOpenRecapDrawer()
                } else {
                  //showInfo(i18n.t('availabilityPage.drawer.error_msg'))
                }
              }}
              disabled={
                bulkMeters === '0' && batches.longLength.length === 0 && batches.shortLength.length === 0 ? true : false
              }
            ></ButtonDrawer>
          )}
          {bulkMeters === '0' && batches.longLength.length === 0 && batches.shortLength.length === 0 && (
            <ButtonStyle style={{ margin: 'auto' }}>{i18n.t('availabilityPage.drawer.button')}</ButtonStyle>
          )}
        </>
      )}
      {loading && (
        <div style={{ marginTop: '50%' }}>
          <LoadingIcons />
        </div>
      )}
      {drawerSelected === 'avaliable' && lotAndAvailabilityResponse && (
        <Drawer width={'35.7vw'} closable={false} onClose={onClose} visible={visible}>
          <LotAvailabilityDrawer
            quantityAvailable={Number(selectableLotMeters.longLength)}
            lotAvailability={getIndexedLotAvailability(lotAndAvailabilityResponse.lotAvailability.longLots.list)}
            lotType={LOT_TYPES.LONG}
            defaultCheckboxList={LotCheckedList.longLength}
            totalLotChecked={lotNumber.longLength}
            totalMetersChecked={lotMeters.longLength}
            totalBatchesChecked={batches.longLength}
            handleTotalLotMeters={handleTotalLotMeters}
            handleTotalLotNumber={handleTotalLotNumber}
            handleQtyAvailable={handleSelectableLotMeters}
          />
        </Drawer>
      )}
      {drawerSelected === 'discount' && lotAndAvailabilityResponse && (
        <Drawer width={'35.7vw'} closable={false} onClose={onClose} visible={visible}>
          <LotAvailabilityDrawer
            quantityAvailable={Number(selectableLotMeters.shortLength)}
            lotAvailability={getIndexedLotAvailability(lotAndAvailabilityResponse.lotAvailability.shortLots.list)}
            lotType={LOT_TYPES.SHORT}
            defaultCheckboxList={LotCheckedList.shortLength}
            totalLotChecked={lotNumber.shortLength}
            totalMetersChecked={lotMeters.shortLength}
            totalBatchesChecked={batches.shortLength}
            handleTotalLotMeters={handleTotalLotMeters}
            handleTotalLotNumber={handleTotalLotNumber}
            handleQtyAvailable={handleSelectableLotMeters}
          />
        </Drawer>
      )}
      {drawerSelected === 'future' && (
        <Drawer width={'25.5vw'} closable={false} onClose={onClose} visible={visible}>
          <FutureAvailabilityDrawer quantityAvailable={getBulkAvailability(false)} />
        </Drawer>
      )}
      {/* Tendina aggiuntiva per completare l'inserimento al carrello  */}
      {drawerSelected === 'recap' && (
        <Drawer width={'25.5vw'} closable={false} onClose={onClose} visible={visible}>
          <RecapDrawer
            objectClicked={objectClicked!}
            lotNumbers={lotNumber}
            metersNumber={lotMeters}
            totalMetersToShip={totalMetersToShip}
            batchesSelected={batches}
            bulkAvailability={getBulkAvailability(true)}
            faveSupportCodes={lotAndAvailabilityResponse?.lotAvailability.preferredSupportCode!}
            onClick={onClose}
          />
        </Drawer>
      )}

      {/* <ModalStyled
        visible={modalVisibility}
        closable={false}
        width={350}
        footer={[<ButtonModaStyle onClick={closeModal} >OK</ButtonModaStyle>]}
        style={{
          top: 220,
          left: '37.5%'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '3.5%' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CloseCircleOutlined style={{ fontSize: '24px', color: '#e74c3c' }} />
            <p style={{ fontSize: '25px', marginLeft: '8px', lineHeight: '1.2' }}>
              {i18n.t('availabilityPage.sub_drawer_1.modal_title')}
            </p>
          </div>
          <Spacer />
          <p style={{ fontSize: '18px', marginLeft: '33px' }}>
            {i18n.t('availabilityPage.drawer.modal_content')}
          </p>
        </div>
      </ModalStyled> */}
    </CustomDrawer>
  )
}

const ButtonStyle = styled.button<{}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 24px;

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;

  font-size: 1.2vw !important;
  padding: 12px 38px !important;

  border-color: #d9d9d9;
  border-width: 0.1vw;

  /* Neutral/Light Grey */
  background: white;
  color: #a1a2aa;
  border-radius: 24px;

  justify-content: center;
  // :hover {
  //   background: #bba391;
  //   color: white;
  // }
`
// --
// const ButtonModaStyle = styled.button<{ invertedColor: boolean }>`
//   cursor: pointer;
//   font-family: Rajdhani;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 1.2vw;
//   padding: 2px 20px;

//   background: ${({ invertedColor }) => (invertedColor ? '#bba391' : '#d8d7db')};
//   color: ${({ invertedColor }) => (invertedColor ? 'white' : 'black')};
//   border-radius: 24px;
//   border-style: none;
//   :hover {
//     background: #bba391;
//     color: white;
//   }
// `
const DrawerTitleSmallLeft = styled.h5`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4vw;

  color: #28293d;
  text-align: center;
  margin-bottom: auto;
  margin-top: 2%;
`

const CustomDrawer = styled.div`
  width: 100%;
  flex: 1;
  padding: 10px;
`

const CustomButton = styled(Button)`
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
  background: trasparent;
  border: none;
  width: 3vw;
  height: 2vw;
`

const DrawerTitle = styled.h3`
  margin-bottom: -2%;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8vw;

  display: flex;
  align-items: center;
  text-align: center;

  color: #28293d;
  justify-content: center;
  line-height: 1;
`
const DrawerTitleSmall = styled.h5`
  margin-top: 0.2em;
  margin-bottom: 0;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4vw;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Neutral/Black */

  color: #28293d;
  justify-content: center;
`
const DrawerSubtitle = styled.h4`
  margin-bottom: -2%;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;

  display: flex;
  align-items: center;
  text-align: center;
  color: #bba391;

  justify-content: center;
`
const DrawerSubsubtitle = styled.h5`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Neutral/Grey */

  color: #a1a2aa;

  justify-content: center;
  text-align: center;
`
const DiscountLabel = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-size: 1.1vw;
  font-weight: bold;
  border: 1px solid #ffffff;
  border-bottom-width: 2px;
  border-radius: 25px;
  margin: auto;
  background-color: #dc251c;
  width: 12vw;
  color: #ffffff;
  text-align: center;
  padding: 0 0.4em -0;
  margin-top: -2%;
  margin-bottom: 2%;
`
// --
// const ModalStyled = styled(Modal)`
//   .ant-modal-content{
//     border-radius: 24px;
//   }
// `
