import styled from 'styled-components'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import deleteIcon from 'assets/icons/delete_forever.png'
import deleteGreyIcon from 'assets/icons/delete_grey.png'
import deleteYes from 'assets/icons/delete_yes.png'
import deleteNo from 'assets/icons/delete_no.png'
import { useState } from 'react'
import i18n from 'translations/i18n'
import Catalogue from 'api/Catalogue';
import { reloadApp } from 'utils/router';
import { showError } from 'utils';

export default function RecentSearchRow(props: { element: string; itemId: string; onClick?: (data: string) => void }) {

  const [hover, setHover] = useState(false)
  const [temporaryDeleteOption, setTemporaryDeleteOption] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteRecentSearch = async (itemId: string) => {
    try {
      setLoading(true)
      await Catalogue.deleteRecentSearch(itemId)
      reloadApp()
      setLoading(false)
    } catch (error) {
      showError(i18n.t('availabilityPage.main.error_delete_recent_search'))
      setLoading(false)
    }
  }

  return (
    <>
      {!temporaryDeleteOption && (
        <RowContainer >
          <Icon onClick={() => setTemporaryDeleteOption(true)} src={deleteIcon} />
          <SearchDetail onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={props.onClick}>
            <ItemDescriptionText>{hover ? props.element : props.element} </ItemDescriptionText>
            <SearchText>{i18n.t('availabilityPage.main.left_text_button')}</SearchText>
          </SearchDetail>
        </RowContainer>
      )}
      {temporaryDeleteOption && (
        <RowContainer>
          <Icon src={deleteGreyIcon} />
          <DeleteContainer>
            <DeleteText>{i18n.t('availabilityPage.main.delete_message')}</DeleteText>
            <DeleteIconsContainer>
              {!loading && (
                <>
                  <Icon onClick={() => { deleteRecentSearch(props.itemId) }} src={deleteYes} />
                  <Icon onClick={() => setTemporaryDeleteOption(false)} src={deleteNo} />
                </>
              )}
              {loading && (<LoadingIconsStyled />)}
            </DeleteIconsContainer>
          </DeleteContainer>
        </RowContainer>
      )}
    </>
  )
}

const RowContainer = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  width: 36.736vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  margin: auto;
  margin-top: 1.3vw;
  cursor: pointer;
  :hover {
    box-shadow: 0px 1px 4px rgb(40 41 61 / 4%), 0px 8px 12px rgb(96 97 112 / 16%);
    border-radius: 50px;
  }
`

const SearchDetail = styled.div`
  width: 33vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ItemDescriptionText = styled.h4`
  font-weight: bold;
  font-size: 1.4vw;
  color: #28293d;
  display: flex;
  align-items: center;
  margin-top: 1vw;
`
const SearchText = styled.h5`
  font-weight: 600;
  font-size: 1.3vw;
  color: #a1a2aa;
  display: flex;
  align-items: center;
  text-align: right;
  margin-right: 1.667vw;
  margin-top: 12px;
`
const Icon = styled.img`
  width: 1.5vw;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
`

const DeleteIconsContainer = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
`

const DeleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 5%;
`

const DeleteText = styled.h4`
  font-weight: 500;
  font-size: 1.3vw;
  color: #28293d;
  display: flex;
  align-items: center;
  margin-top: 12px;
`

const LoadingIconsStyled = styled(LoadingIcons)`
  width: 24px;
  height: 24px;
  margin-left: 20px; 
  margin-top: auto; 
  margin-bottom: auto; 
`