import { Route, Redirect } from 'react-router-dom';
import { MenuLinkDomain } from 'types/permissions.domain.service';

const GuardedRoute = (props: {
    path: string,
    permission: string,
    userPermissions: string[],
    children: any
}) => {

    const checkPermission = (): boolean => {
        if(props.userPermissions.includes(props.permission)) {
            localStorage.setItem("index", MenuLinkDomain.get(props.permission)?.position!.toString()!)
            return true;
        };
        return false;
    }

    return (checkPermission() ? <Route {...props}/> : <Redirect to="/" />);
};

export default GuardedRoute;