import { useState } from "react"
import { CatalogueItem, PaginationResource } from "types"
import EmptyRow from "./EmptyRow"
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import { ResearchDrawer } from "./Drawer"
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import { Drawer } from "antd"
import ProductRow from "./ProductRow"
import Cart from "api/Cart"

export default function ProductListComponent(props: {
  loading: boolean
  productList: PaginationResource<CatalogueItem>
  itemToSearch: string
  postRecentSearch: (itemId: string, itemDescription: string) => Promise<void>
  getFullCatalogue: (numberPage: number, value: string) => void
  handlePageNumber: React.Dispatch<React.SetStateAction<number>>
  handleScroll: (e: any) => void
}) {

  const cookies = new Cookies()
  const [drawerValue, setDrawerValue] = useState<CatalogueItem>()
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
    getTimerFromServer()
  }

  const getTimerFromServer = async () => {
    try {
      const res = await Cart.getCartIfExist()
      const timer: string = 'timer' + cookies.get('loggedErpUser')
      localStorage.setItem(timer, res.summary.expiringDateTime)
    } catch (error) { }
  }

  return (
    <>
      {!props.loading &&
        props.productList?.elements.map((n: CatalogueItem, index) => (
          <ProductRow
            key={index}
            onClick={() => {
              showDrawer()
              setDrawerValue(n)
              props.postRecentSearch(
                n.articleNo,
                n.matched === 'C' && n.crossreferenceNo ? n.crossreferenceNo : n.articleDescription
              )
            }}
            element={n}
          />
        ))}
      {!props.loading && props.productList?.elements.length === 0 && (
        <p style={{ marginLeft: '40px' }}>
          <EmptyRow text="Nessun valore trovato" />
        </p>
      )}
      {props.loading && <LoadingIcons />}
      <CustomerDrawer
        title=""
        width={'26vw'}
        height={'410px'}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <ResearchDrawer
          objectClicked={drawerValue}
          loggedErpUser={cookies.get('loggedErpUser')}
        />
      </CustomerDrawer>
    </>
  )
}

const CustomerDrawer = styled(Drawer)``