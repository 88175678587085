export enum DOCUMENTAL {
    PACKINGLIST = "PACKINGLIST",
    ORDER = "ORDER",
    DDT = 'DDT',
    INVOICE = 'INVOICE',
};

export const DocumentalDomain = [
    {
        permissions: ["menu.documentary"],
        label: "documentalPage.input_docType.order",
        value: DOCUMENTAL.ORDER
    },
    {
        permissions: ["menu.documentary"],
        label: "documentalPage.input_docType.invoice",
        value: DOCUMENTAL.INVOICE
    },
    {
        permissions: ["menu.logistic_documents"],
        label: "documentalPage.input_docType.ddt",
        value: DOCUMENTAL.DDT
    },
    {
        permissions: ["menu.logistic_documents"],
        label: "documentalPage.input_docType.packinglist",
        value: DOCUMENTAL.PACKINGLIST
    }
];

