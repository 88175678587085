import styled from 'styled-components'

export default function Button({
  text,
  inverted,
  onClick,
  ...props
}: {
  inverted?: boolean
  text?: string
  onClick?: () => void
  style?: any
}) {
  return (
    <ButtonStyle invertedColor={inverted} style={props.style} onClick={onClick}>
      {text}
    </ButtonStyle>
  )
}

const ButtonStyle = styled.button<{ invertedColor: boolean }>`
  /* Neutral/Light Grey */
  background: ${({ invertedColor }) => (invertedColor ? '#bba391' : '#d8d7db')};
  color: ${({ invertedColor }) => (invertedColor ? 'white' : 'black')};
  cursor: pointer;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3vw !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 100px !important;
  border-radius: 24px;
  border-style: none;
  justify-content: center;

  :hover {
    background: #bba391;
    color: white;
  }
`
