import styled from 'styled-components'

export default function Spacer() {
  return <SpacerDiv></SpacerDiv>
}

const SpacerDiv = styled.div`
  width: 8px;
  height: 8px;
`
