import styled from 'styled-components'

export default function Text(props: { title?: string; hideText?: boolean; defaultValue?: string }) {
  return (
    <Container>
      <Title>{props.title}</Title>
      <InputCore>{props.defaultValue}</InputCore>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //padding: 0px 0px 24px;
  margin-top: -2%;
`
const Title = styled.div`
  width: 20vw;
  position: static;

  left: 0px;
  top: calc(50% - 22px / 2 - 4px);

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6vw;

  color: rgba(0, 0, 0, 0.85);
  text-align: left;
  margin-right: 2%;
`
const InputCore = styled.h2`
  display: flex;
  font-size: 1.6vw;
  justify-content: center;

  align-items: flex-start;
  /* padding: 0px 24px; */
  left: 0px;
  right: 0px;
  top: 15px;
  bottom: 0px;
  background: #d8d7db;
  height: 6.3vh;
  width: 3.3vw;
  padding: 1%;

  /* border: 1px solid #d9d9d9; */
  box-sizing: border-box;
  border-radius: 3vw;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-right: 6%;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  margin-top: -1%;
`
