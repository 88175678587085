import { ArrowDownOutlined, ArrowUpOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import styled from 'styled-components'

export default function SortingButton(props: {
  sortState: number,
  sortingKey: string,
  onChangeSortState: (newStatus: number, sortingKey: string) => void
}) {

  enum SORT {
    DEFAULT = 0,
    ASC = 1,
    DESC = 2
  }

  const [status, setStatus] = useState<number>(0)

  const changeStatus = (): void => {
    if (status === SORT.DEFAULT) {
      setStatus(SORT.ASC)
      props.onChangeSortState(SORT.ASC, props.sortingKey)
    }
    if (status === SORT.DESC) {
      setStatus(SORT.DEFAULT)
      props.onChangeSortState(SORT.DEFAULT, props.sortingKey)
    }
    if (status === SORT.ASC) {
      setStatus(SORT.DESC)
      props.onChangeSortState(SORT.DESC, props.sortingKey)
    }
  }

  useEffect(() => {
      setStatus(props.sortState)
  }, [props.sortState])

  return (
    <div onClick={changeStatus}>
      {status === SORT.DEFAULT && (
        <SortButtonDefault>
          <UpOutlined style={{ fontSize: '0.7vw' }} />
          <DownOutlined style={{ fontSize: '0.7vw' }} />
        </SortButtonDefault>
      )}
      {status === SORT.DESC && (
        <ArrowDownOutlined style={{ fontSize: '1vw' }} />
      )}
      {status === SORT.ASC && (
        <ArrowUpOutlined style={{ fontSize: '1vw' }} />
      )}
    </div>
  )
}

const SortButtonDefault = styled.div`
  width: 1vw; 
  display: flex;
  flex-direction: column;
  justify-content: center;
`

