import { AppStore } from "utils";
import LineChartPage from "./LineChartPage";
import styled from 'styled-components';
//import i18n from "translations/i18n";
import sample7 from 'assets/sampleFabric7.jpg'

export default function HomePage() {
    return (
        <>
            {AppStore.loggedUser.permissions.includes("menu.dashboard") ? <LineChartPage /> : 
                <Page>
                    <CenterPiece img={sample7}>
                        {/* <RectangleShape>
                            <BigTitle>{i18n.t('homePage.greeting')}</BigTitle>
                            <BigTitle>MyVay</BigTitle>
                        </RectangleShape> */}
                        {/* <Row>
                            <ImgContainer img={sample6}/>
                        </Row> */}
                    </CenterPiece>
                </Page>
            }
        </>
    );
};

const Page = styled.div`
    height: 100vh; 
    margin: auto;
    filter: opacity(0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-content: center;
    align-items: center;
    line-height: 1;
`;

// const BigTitle = styled.div`
//     font-family: cormorant-garamond,serif;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 3.7vw;
//     display: flex;
//     text-align: left;
//     color: #28293d;
//     place-content: center;
//     margin-left: -2.5vw;
// `;

// const ImgContainer = styled.div<{img: string}>`
//     background-image: url(${({ img }) => (img ? img : '')});
//     width: 36vw;
//     height: 80vh;
//     background-size: cover;
//     border-radius: 50px 50px 50px 50px;;
//     margin-bottom: 11vw; */
//     box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
//     margin: 0.5vw;
//     background-size: cover;
//     background-position: center;
// `;

const CenterPiece = styled.div`
    background-image: url(${({ img }) => (img ? img : '')});
    display: flex;
    flex-direction: column;
    background-size: cover;
    margin: auto;
    position: fixed;
    width: 76vw;
    height: 100vh;
    //background-position-y: center;
`

// const Row = styled.div`
//     display: flex;
//     flex-direction: row;
//     margin: 0.1vw;
// `;

// const RectangleShape = styled.div<{img: string}>`
//     background-image: url(${({ img }) => (img ? img : '')});
//     width: 28vw;
//     height: 24vh;
//     display: flex; 
//     justify-content: center; 
//     flex-direction: column;
//     border-radius: 50px 50px 50px 50px;
//     background-size: cover;
//     background-position: center;
//     margin: 0.5vw;
//     position: absolute;
// `;