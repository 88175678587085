import styled from 'styled-components';
import Spacer from 'components/Spacer';
import SpecificOrderRow from 'components/SpecificOrderRow';
import { OrderResponse } from 'types';
import { useEffect, useState } from 'react';
import Orders from 'api/Orders';
import { ReactComponent as LoadingIcons } from 'assets/loading.svg';
import i18n from 'translations/i18n';

export default function OrderHistoryDrawer({
  documentNumber,
  expressShipment,
  externalDocumentNo,
  email
}: {
  documentNumber: string | undefined,
  expressShipment: string | undefined,
  externalDocumentNo: string | undefined,
  email: string | undefined
}) {

  const [myOrders, setMyOrders] = useState<OrderResponse[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOrderById = async (value?: string) => {
      try {
        setLoading(true);
        const res = await Orders.getOrderById(value ? value : '');
        setLoading(false);
        setMyOrders(res);
      } catch (error) { }
    }
    getOrderById(documentNumber)
  }, [documentNumber])

  const getTotalMeters = (): string => {
    let tot: number = 0;
    myOrders?.forEach(item => {
      tot += Number(item.quantity);
    })
    return tot.toFixed(2);
  }

  return (
    <>
      {!loading && (
        <Drawer>
          <DrawerTitle>
            {i18n.t('orderHistoryPage.drawer.title')} {documentNumber}
          </DrawerTitle>
          <Spacer /> <Spacer /> <Spacer /> <Spacer />
          <HeadersContainer>
            <RowDetails>
              <Product>
                <ProductCol>{i18n.t('orderHistoryPage.drawer.product_column')}</ProductCol>
              </Product>
              <DeliveryDate>
                <DeliveryDateCol>{i18n.t('orderHistoryPage.drawer.delivery_date_column')}</DeliveryDateCol>
              </DeliveryDate>
              <Meters>
                <MetersCol>{i18n.t('orderHistoryPage.drawer.meters_column')}</MetersCol>
              </Meters>
            </RowDetails>
          </HeadersContainer>
          <Scrollbar>
            <Spacer /> <Spacer />
            {myOrders && myOrders.map((n, index) => <SpecificOrderRow key={index} element={n} />)}
            <Spacer /><Spacer />
          </Scrollbar>
          <Spacer /> <Spacer /> <Spacer />
          <RowDetails style={{ justifyContent: 'space-between' }}>
            <TotalMetersDetail>{i18n.t('orderHistoryPage.drawer.total_meters')}</TotalMetersDetail>{' '}
            <TotalMetersDetail>{getTotalMeters()}MT</TotalMetersDetail>
          </RowDetails>
          <Spacer /> <Spacer />
          <Line/>
          <RowDetails style={{ justifyContent: 'flex-end' }}>
            <ShipmentDetail>{expressShipment === 'true' ? i18n.t('orderHistoryPage.drawer.express_shipment') : i18n.t('orderHistoryPage.drawer.standard_shipment')}</ShipmentDetail>
          </RowDetails>
          <Line/>
          <RowDetails style={{ justifyContent: 'space-between' }}>
            <ExtraInfoDetail>{i18n.t('orderHistoryPage.drawer.externalDocumentNo')}</ExtraInfoDetail>
            <ExtraInfoDetail>{externalDocumentNo !== '' ? externalDocumentNo : '------'}</ExtraInfoDetail>
          </RowDetails>
          <RowDetails style={{ justifyContent: 'space-between' }}>
            <ExtraInfoDetail>Email</ExtraInfoDetail>
            <ExtraInfoDetail>{email !== '' ? email : '------'}</ExtraInfoDetail>
          </RowDetails>
        </Drawer>
      )}
      {loading && <LoadingIcons />}
    </>
  )
}

const Drawer = styled.div`
  width: 100%;
  font-family: Rajdhani;
  font-style: normal;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
`;
const DrawerTitle = styled.h1`
  font-weight: 600;
  font-size: 2vw;
  color: #774a28;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -3%;
  place-content: center;
`;
const HeadersContainer = styled.div`
  width: 27vw;
`;
const ProductCol = styled.h2`
  font-weight: 600;
  font-size: 1.3vw;
  color: #28293d;
  display: flex;
  align-items: center;
  text-align: left;
`;
const DeliveryDateCol = styled.h2`
  font-weight: bold;
  font-size: 1.3vw;
  color: #28293d;
  display: flex;
  justify-content: center;
`;
const MetersCol = styled.h2`
  font-weight: 600;
  font-size: 1.3vw;
  color: #28293d;
  display: flex;
  align-items: center;
  text-align: right;
`;
const TotalMetersDetail = styled.h2`
  font-weight: 600;
  font-size: 1.4vw;
  display: flex;
  color: #28293d;
  margin-top: auto;
  margin-bottom: auto;
`;
const ShipmentDetail = styled.h2`
  font-weight: 500;
  font-size: 1.2vw;
  color: #28293d;
`;
const ExtraInfoDetail = styled.h2`
  font-weight: 600;
  font-size: 1.2vw;
  color: #774a28;
`;
const RowDetails = styled.div`
  margin-left: 0.8vw;
  width: 24vw;
  display: flex;
  flex-direction: row;
`;
const Product = styled.div`
  width: 10vw;
  text-align: left;
`;
const DeliveryDate = styled.div`
  width: 10vw;
  text-align: center;
`;
const Meters = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 4.5vw;
  text-align: right;
`;
const Scrollbar = styled.div`
  height: 43vh;
  width: 28vw;
  overflow-y: scroll;
  border-bottom: 1px solid #c1c1c1;
`;
const Line = styled.hr`
  width: 100%;
`;