import styled from 'styled-components'
import activatedIcon from 'assets/iconsUserRow/activated.png'
import deactivatedIcon from 'assets/iconsUserRow/deactivated.png'
import passwordIcon from 'assets/iconsUserRow/password.png'
import passwordWhiteIcon from 'assets/iconsUserRow/passwordWhite.png'
import extendedIcon from 'assets/iconsUserRow/extended.png'
import limitedIcon from 'assets/iconsUserRow/limited.png'
import extendedWhiteIcon from 'assets/iconsUserRow/extendedWhite.png'
import limitedWhiteIcon from 'assets/iconsUserRow/limitedWhite.png'
import itaIcon from 'assets/iconsUserRow/it.png'
import engIcon from 'assets/iconsUserRow/en.png'
import espIcon from 'assets/iconsUserRow/es.png'
import { useState } from 'react'
import { CATALOGUE_VISIBILITY, User } from 'types'
import Users from 'api/Users'
import { AppStore, showError, showInfo } from 'utils'
import i18n from 'translations/i18n'
import { Tooltip } from 'antd'

//Valori che definiscono l'intervallo di giorni in cui l'icona cambia colore
const redKey: number = 2
const yelloKey: number = 8

export default function CustomerRow({
  user,
  pswExpiration,
  onPasswordClick,
  onUserClick,
  onLanguageClick,
  ...props
}: {
  user: User
  pswExpiration: string
  onPasswordClick: (password: string) => void
  onUserClick: (username: string) => void
  onLanguageClick: (language: string) => void
}) {

  const [hover, setHover] = useState(false)
  const [hoverIcon, setHoverIcon] = useState(0)
  const [activeIcon, setActiveIcon] = useState(user.active)
  const [limited, setLimited] = useState<CATALOGUE_VISIBILITY>(user.catalogueVisibility)

  const patchUserIsActive = async () => {
    try {
      await Users.patchUserById(user.id,
        [{
          op: 'replace',
          path: '/active',
          value: !activeIcon
        }])
      setActiveIcon(!activeIcon)
      showInfo(i18n.t('customersPage.customer_row.info_active'))
    } catch (error) {
      showError(i18n.t('customersPage.customer_row.error_active'))
    }
  }

  const patchUserLimitatedAccount = async () => {
    try {
      await Users.patchUserById(user.id,
        [{
          op: 'replace',
          path: '/catalogueVisibility',
          value:
            limited === CATALOGUE_VISIBILITY.EXTENDED ?
              CATALOGUE_VISIBILITY.NORMAL :
              CATALOGUE_VISIBILITY.EXTENDED,
        }])
      AppStore.loggedUser.catalogueVisibility =
        limited === CATALOGUE_VISIBILITY.EXTENDED ?
          CATALOGUE_VISIBILITY.NORMAL :
          CATALOGUE_VISIBILITY.EXTENDED

      setLimited(limited === CATALOGUE_VISIBILITY.NORMAL ?
        CATALOGUE_VISIBILITY.EXTENDED :
        CATALOGUE_VISIBILITY.NORMAL)
      showInfo(i18n.t('customersPage.customer_row.info_limited'))
    } catch (error) {
      showError(i18n.t('customersPage.customer_row.error_limited'))
    }
  }

  return (
    <CustomerRowContainer onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <RightIcon src={user.active ? activatedIcon : deactivatedIcon} onClick={patchUserIsActive} />
      <CompanyUser onClick={() => onUserClick(user.username)}>
        <Tooltip
          placement="topLeft"
          title={user.businessName}
          overlayInnerStyle={{ fontSize: '1vw' }}>
          <CompanyText>{user.businessName}</CompanyText>
        </Tooltip>
        <UserText>{user.name + ' ' + user.surname}</UserText>
      </CompanyUser>

      <CustomerOption
        onMouseEnter={() => setHoverIcon(1)}
        onMouseLeave={() => setHoverIcon(0)}
        onClick={patchUserLimitatedAccount}
      >
        {limited === CATALOGUE_VISIBILITY.NORMAL && (
          <VisibilityIcon src={hoverIcon === 1 ? limitedWhiteIcon : limitedIcon} />
        )}
        {limited === CATALOGUE_VISIBILITY.EXTENDED && (
          <VisibilityIcon src={hoverIcon === 1 ? extendedWhiteIcon : extendedIcon} />
        )}
        {hover && (
          <IconText>
            {limited === CATALOGUE_VISIBILITY.NORMAL ?
              i18n.t('customersPage.button_limited') :
              i18n.t('customersPage.button_extended')}
          </IconText>
        )}
      </CustomerOption>

      <PasswordInfo
        onMouseEnter={() => setHoverIcon(2)}
        onMouseLeave={() => setHoverIcon(0)}
        onClick={() => onPasswordClick(pswExpiration)}
        expiration={Number(pswExpiration)}
      >
        <PasswordIcon
          src={hoverIcon === 2 ? passwordWhiteIcon : passwordIcon}
          expiration={Number(pswExpiration)}
        />
        {hover && (
          <PasswordText>
            {Number(pswExpiration) > 30 ?
              i18n.t('customersPage.button_expiration_days') :
              pswExpiration + i18n.t('customersPage.button_days')}
          </PasswordText>
        )}
      </PasswordInfo>

      <CustomerOption onClick={() => { onLanguageClick(user.language) }}>
        <Icon src={user.language === 'ITALIAN' ? itaIcon : user.language === 'SPANISH' ? espIcon : engIcon} />
        {hover && (<IconText style={{ marginTop: '8%' }}>{user.language.toUpperCase()}</IconText>)}
      </CustomerOption>

    </CustomerRowContainer>
  )
}

const CustomerRowContainer = styled.div`
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1vw;
  padding: 1vw 2vw;

  :hover {
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
    border-radius: 3vw;
    /* border-bottom: none; */
  }
`
const RightIcon = styled.img`
  width: 2vw;
  height: 2vw;
  :hover {
    cursor: pointer;
  }
`
const CompanyUser = styled.div`
  cursor: pointer;
  width: 1vw;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 1vw;
`
const CompanyText = styled.p`
  min-width: calc(100% - 10px);
  max-width: calc(100% - 10px);
  font-weight: 500;
  font-size: 2vw;
  color: #28293d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const UserText = styled.p`
  font-size: 1.3vw;
  color: #000000;
`
const CustomerOption = styled.div`
  width: 5vw;
  height: 4vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  :hover {
    background: #bba391;
    border-radius: 24px;
  }
`
const Icon = styled.img`
  width: 1.5vw;
  flex-grow: 0;
  margin: 5px 0px;
  align-self: center;
`
const IconText = styled.div`
  font-size: 1vw;
  color: #28293d;
  align-items: center;
  text-align: center;
  
  ${CustomerOption}:hover & {
    filter: brightness(9.5);
  }
`
const PasswordInfo = styled.div<{ expiration: number }>`
  cursor: pointer;
  width: 5vw;
  height: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :hover {
    background: ${({ expiration }) =>
    parseInt(expiration) < redKey ? '#FF3B3B' : parseInt(expiration) < yelloKey ? '#FFCC00' : '#bba391'};
    border-radius: 24px;
  }
`
const PasswordIcon = styled.img<{ expiration: number }>`
  width: 1.5vw;
  align-self: stretch;
  margin: auto;

  path {
    fill: ${({ expiration }) =>
    parseInt(expiration) < redKey ? '#FF3B3B' : parseInt(expiration) < yelloKey ? '#FFCC00' : '#28293D'};
  }

  ${PasswordInfo}:hover & {
    path {
      fill: white;
    }
  }
`
const PasswordText = styled.div`
  font-size: 1vw;
  color: #28293d;
  align-items: center;
  text-align: center;
  
  ${PasswordInfo}:hover & {
    filter: brightness(9.5);
  }
`
const VisibilityIcon = styled.img`
  width: 1.5vw;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
`




