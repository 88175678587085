import styled from 'styled-components';
import { Select } from 'antd';
import { Spacer, LineChart, MultipleSelection } from 'components';
import { useEffect, useState } from 'react';
import Stats from 'api/Stats';
import { PurchasedProductName, StatsResponse } from 'types';
import { ReactComponent as LoadingIcons } from 'assets/loading.svg';
import { AppStore } from 'utils';
import i18n from 'translations/i18n';
import Cookies from 'universal-cookie';
import { LineChartDomain } from 'types/dashboard.domain.service';

const { Option } = Select;
export default function LineChartPage() {

  const cookies = new Cookies();

  const [erpUsersCodes, setErpUsersCodes] = useState<string[]>([]);
  const [lastPurchasedProducts, setLastPurchasedProducts] = useState<PurchasedProductName[]>([]);
  const [defaultLastPurchasedProducts, setDefaultLastPurchasedProducts] = useState<PurchasedProductName[]>([]);

  const [amountAndQuantityPerMonth, setAmountAndQuantityPerMonth] = useState<StatsResponse[]>([]);
  const [showQuantity, setShowQuantity] = useState(true);

  const [loading, setLoading] = useState(false);

  const [filterERP, setFilterERP] = useState<string | string[]>('');
  const [filterOrder, setFilterOrder] = useState<string>('');

  const getLastPurchasedProducts = async () => {
    try {
      const res = await Stats.getLastPurchasedProducts();
      setLastPurchasedProducts(res);
      setDefaultLastPurchasedProducts(JSON.parse(JSON.stringify(res)));
    } 
    catch (error) { }
  }

  const getamountandquantitypermonth = async (months: number, erpUserCodes?: string | string[], productCode?: string) => {
    const loggedErpUser: string[] = [cookies.get('loggedErpUser')];
    try {
      setLoading(true);
      const res = await Stats.getAmountAndQuantityPerMonth(months, erpUserCodes ? erpUserCodes : loggedErpUser, productCode);
      setLoading(false);
      setAmountAndQuantityPerMonth(res);
    } catch (error) { }
  }

  function onChangeSortModifier(value: string) {
    setShowQuantity(LineChartDomain[0].value === value);
  }

  const filterProductsBySelectedErp =(selectedErp:Array<string> | string)=>{
    
  let clone = defaultLastPurchasedProducts;
  
  let products:PurchasedProductName[] = [];

  selectedErp as string [] && (selectedErp as string[]).forEach(element=>{

      const temp:PurchasedProductName[] = clone.filter(item=> item.erpuser  === element);
      if(temp.length > 0){
        temp.forEach(ele=> products.push(ele));
      }
    });
     setLastPurchasedProducts(products);
  }

  useEffect(() => {
    var valuesArray: string[] = [];
    AppStore.loggedUser.erpUsers.map((erp) => {
      return valuesArray.push(erp.code);
    })
    setErpUsersCodes(valuesArray);
    getLastPurchasedProducts();
    getamountandquantitypermonth(
      60,
      filterERP !== '' ? filterERP : undefined,
      filterOrder !== '' ? filterOrder : undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Header>
        <Title>{i18n.t('dashboard_page.title')}</Title>
        <Subtitle>{i18n.t('dashboard_page.subtitle')}</Subtitle>
        <Spacer /> <Spacer /> <Spacer />
      </Header>
      <Filters>
          <HeaderSelect
            defaultValue={LineChartDomain[0].value}
            showSearch
            placeholder={i18n.t('dashboard_page.select_filter_by.placeholder')}
            optionFilterProp="children"
            onChange={onChangeSortModifier}
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            { LineChartDomain.map((o, index) => {
              return (<Option key={index} value={o.value}>{i18n.t(o.label)}</Option>);
            })}
          </HeaderSelect>
        <HeaderMultiSelect>
          <MultipleSelection
            showSearch={false}
            mode="multiple"
            defaultValues={[cookies.get('loggedErpUser')]}
            title=""
            valuesArray={erpUsersCodes}
            placeholder={i18n.t('dashboard_page.select_filter_by_code.placeholder')}
            onChangeSelection={(e) => {
              setFilterERP(e)
              getamountandquantitypermonth(60, e, filterOrder !== '' ? filterOrder : undefined)
              filterProductsBySelectedErp(e)
            }}
          ></MultipleSelection>
        </HeaderMultiSelect>
        <HeaderMultiSelect>
          <MultipleSelection
            showSearch={true}
            mode=""
            defaultValues={[]}
            title=""
            valuesArray={lastPurchasedProducts.map((e) => {
              return e.name + ' - ' + e.description
            })}
            placeholder={i18n.t('dashboard_page.select_filter_by_purchase.placeholder')}
            onChangeSelection={(e) => {        
               if(!e){
                setFilterOrder('');
                getamountandquantitypermonth(60, filterERP !== '' ? filterERP : undefined, undefined);
                return;
               }
              setFilterOrder(e.substring(0, e.indexOf(' ')))
              getamountandquantitypermonth(60, filterERP !== '' ? filterERP : undefined, e.substring(0, e.indexOf(' ')))
            }}
          ></MultipleSelection>
        </HeaderMultiSelect>
      </Filters>
      {loading && <LoadingIcons />}
      {!loading && showQuantity && <LineChart products={amountAndQuantityPerMonth} yAxis={true} />}
      {!loading && !showQuantity && <LineChart products={amountAndQuantityPerMonth} yAxis={false} />}
    </Container>
  )
}

const Container = styled.div`
  background-color: white;
  margin-left: 10vw;
  margin-top: 5vh;
`;

const Header = styled.div`
  width: 50vw;
`;

const HeaderSelect = styled(Select)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 24px;
  padding: 3%;
  width: 10.278vw;
  height: 6vh;
  background: #ffffff;
  border: none;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  font-size: 1vw;

  align-items: center;
  margin: auto;
  margin-left: 2.222vw;
  &:hover {
    border: gray;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  }
`;

const HeaderMultiSelect = styled.div`
  width: 18vw;
  margin: auto;
  margin-top: 8px;
`;

const Filters = styled.div`
  place-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
  width: 49.306vw;
  align-items: center;
`;
const Title = styled.div`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 500;
  font-size: 3.5vw;
  /* line-height: 46px; */
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;

  place-content: center;
`;

const Subtitle = styled.div`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;

  place-content: center;
`;
