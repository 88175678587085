import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  AddUserPageStep1,
  AddUserPageStep2,
  AddUserPageStep3,
  AddUserPageStep4,
  AddUserPageStep5
} from '.'
import { Button } from 'components'
import { step1, step2, step3, step4, step5 } from 'assets/addUser'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import { CATALOGUE_VISIBILITY, LANGUAGES, ROLES, User } from 'types'
import Users from 'api/Users'
import { showError, showInfo } from 'utils'
import styled from 'styled-components'

export default function AddUserPage() {
  const emptyUser = {
    id: -1,
    active: true,
    catalogueVisibility: CATALOGUE_VISIBILITY.NORMAL,
    language: LANGUAGES.ITALIAN,
    username: '',
    pwd: '',
    email: '',
    name: '',
    surname: '',
    businessName: '',
    roles: [ROLES.BASIC],
    erpUsers: [],
    permissions: []
  }
  const [step, setStep] = useState(1)
  const [newUser, setNewUser] = useState<User>(emptyUser)
  const [loading, setLoading] = useState(false)

  const [selectedErps, setSelectedErps] = useState<string[]>([])

  const createNewUser = async () => {
    try {
      setLoading(true)
      await Users.createUser(newUser)
      setNewUser(emptyUser)
      showInfo('Utente aggiunto correttamente')
      setLoading(false)
    } catch (error) {
      showError('Impossibile inserire il nuovo utente')
    }
  }

  const createNewCredentials = async () => {
    try {
      const res = await Users.getNewCredential()
      setNewUser({ ...newUser, pwd: res.pwd, username: res.username })
    } catch (error) { }
  }

  const prevStep = () => setStep(step - 1)

  const nextStep = async () => {
    //step1
    if (step === 1) {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (newUser.name !== '' && newUser.surname !== '' && newUser.email !== '' && newUser.businessName !== '') {
        if (re.test(newUser.email)) return setStep(step + 1)
        else return showError('Formato mail non corretto')
      } else {
        return showError('Riempi tutti i campi')
      }
    }
    //step 2
    if (step === 2 && selectedErps.length < 1) return showError('Aggiungi almeno un utenza ERP')
    //step3
    if (step === 3) await createNewCredentials()
    //step4
    if (step === 4) await createNewUser()
    setStep(step + 1)
  }

  return (
    <Container>
      <Title>Aggiungi utente</Title>
      {step === 1 && <Image src={step1} />}
      {step === 2 && <Image src={step2} />}
      {step === 3 && <Image src={step3} />}
      {step === 4 && <Image src={step4} />}
      {step === 5 && <Image src={step5} />}
      <Card>
        {!loading && step === 1 && <AddUserPageStep1 newUser={newUser} setNewUser={setNewUser} />}
        {!loading && step === 2 && (
          <AddUserPageStep2
            newUser={newUser}
            setNewUser={setNewUser}
            selectedErps={selectedErps}
            setSelectedErps={setSelectedErps} />
        )}
        {!loading && step === 3 && <AddUserPageStep3 newUser={newUser} setNewUser={setNewUser} />}
        {!loading && step === 4 && <AddUserPageStep4 newUser={newUser} setNewUser={setNewUser} />}
        {!loading && step === 5 && <AddUserPageStep5 />}
        {!loading && step > 1 && step < 5 && (
          <ButtonsContainer>
            <Button onClick={prevStep} text="Indietro" />
            <Button onClick={nextStep} text="Conferma" />
          </ButtonsContainer>
        )}
        {/* Se sono all'ultimo step, allora torna alla dashboard */}
        {!loading && step === 5 && (
          <ButtonsContainer>
            <Link to="/admin">
              <Button style={{ width: '100%' }} text="Torna al pannello amministrativo" />
            </Link>
          </ButtonsContainer>
        )}
        {/* Se sono all'ultimo step, allora torna alla dashboard */}
        {!loading && step === 1 && (
          <ButtonsContainer>
            <Link to="/admin">
              <Button text="Annulla" onClick={() => setNewUser(emptyUser)} />
            </Link>
            <Button onClick={nextStep} text="Conferma" />
          </ButtonsContainer>
        )}
        {loading && <LoadingIcons />}
      </Card>
    </Container>
  )
}

const Container = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;
  padding-top: 60px;
`
const Title = styled.h1`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 300;
  font-size: 2vw;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #000000;
`
const Image = styled.img`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 70vw;
  margin-top: 40px !important;
  justify-content: center;
  margin: auto;
`
const Card = styled.div`
  width: 50vw;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 24px;
  margin: auto;
  margin-top: 2vw !important;
  padding-left: 4vw; 
  padding-right: 4vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  text-align: -webkit-center;
`
const ButtonsContainer = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-around; 
`