import styled from 'styled-components';

import { Button, MultipleSelection } from 'components';
import { User } from 'types';
import { AppStore, showError } from 'utils';
import Cookies from 'universal-cookie';
import i18n, { changeLanguage } from 'translations/i18n';
import { useEffect, useRef } from 'react';

export default function ErpUsersCard({
  onLoginCallback,
  onLoginCallback2,
}: {
  onLoginCallback: React.Dispatch<React.SetStateAction<User>>
  onLoginCallback2: React.Dispatch<React.SetStateAction<string>>
}) {
  const cookies = new Cookies();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])

  window.onbeforeunload = (e) => {
    if (mounted.current === true) AppStore.setToken('');
  }

  const onClick = () => {
    if (AppStore.loggedErpUser) {
      onLoginCallback2(AppStore.loggedErpUser);
      onLoginCallback(AppStore.loggedUser);
      cookies.set('loggedErpUser', AppStore.loggedErpUser, { path: '/' });
      changeLanguage(AppStore.getLanguageValue());
      cookies.set('language', AppStore.getLanguageValue());
    } else showError(i18n.t('login_page.erp_user.error_no_selection'));
  }

  const getBusinessName = (erpCode: string) => {
    const erpUsers = AppStore.loggedUser.erpUsers;
    for (let i = 0; i < erpUsers.length; i++) {
      if (erpCode === erpUsers[i].code) {
        cookies.set('loggedBusinessName', erpUsers[i].name);
        return;
      }
    }
  }

  return (
    <>
      <Title>{i18n.t('login_page.erp_user.title')}</Title>
      <FormContainer>
        <MultipleSelection
          showSearch={false}
          mode=""
          defaultValues={[]}
          title={i18n.t('login_page.erp_user.select_erp_user.label')}
          erpUsersArray={AppStore.loggedUser.erpUsers}
          placeholder={i18n.t('login_page.erp_user.select_erp_user.value')}
          onChangeSelection={(e) => (
            // eslint-disable-next-line
            getBusinessName(e),
            AppStore.loggedErpUser = e
          )}
        ></MultipleSelection>
      </FormContainer>

      <CustomButton onClick={onClick} text={i18n.t('login_page.erp_user.button')} />
    </>
  )
}

const FormContainer = styled.div`
  width: 22vw;
  margin-bottom: 2vw;
`;
const Title = styled.h1`
  place-content: center;

  margin-top: 1vw;
  margin-bottom: 1vw;
  font-size: 4vw;

  font-family: cormorant-garamond, serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  text-align: center;

  color: #28293d;
`;

const CustomButton = styled(Button)`
  font-size: 1.3em !important;
  padding: 12 - 180px !important;
  margin-bottom: 1vw !important;
`;
