import styled from 'styled-components'
import i18n from 'translations/i18n'

export default function ChartRow(props: {
  number: string
  date: string
  mode: string
  externalDocumentNo: string
  onClick1: () => void
}) {
  return (
    // 0: Spedito, 1: Spedito parziale, 2: Inserito, 3: Approvato
    <Container onClick={props.onClick1}>
      <TextBox>
        <LeftLeftText>
          {'N.' + props.number}
        </LeftLeftText>
      </TextBox>
      <TextBox>
        <LeftText>{props.date}</LeftText>
      </TextBox>
      <TextBox>
        <LeftText>{props.externalDocumentNo === "" ? "------" : props.externalDocumentNo}</LeftText>
      </TextBox>
      <TextBox>
        <RightText type={props.mode}>
          {props.mode}
        </RightText>
      </TextBox>
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
  background: #ffffff;
  width: 51vw;

  font-family: Rajdhani;
  font-style: normal;

  margin-left: 4px;
  margin-top: 2vh;
  margin-bottom: 12px;
  margin-right: 4px;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  flex-direction: row;
  :hover {
    box-shadow: 0px 1px 4px rgb(40 41 61 / 4%), 0px 8px 12px rgb(96 97 112 / 16%);
    border-radius: 50px;
  }
`
const TextBox = styled.div`
  width: 13vw;
  text-align: center;
  margin: auto;
`
const LeftLeftText = styled.h4`
  margin-top: 1vw;
  margin-bottom: 3vh;
  font-weight: 500;
  font-size: 1.3vw;

  color: #28293d;
`
const LeftText = styled.h4`
  font-weight: 600;
  font-size: 1.3vw;
  /* Neutral/Grey */
  color: #a1a2aa;
`

const RightText = styled.h5<{ type: string }>`
  font-weight: 600;
  font-size: 1.3vw;

  color: ${({ type }) =>
    type === i18n.t('orderHistoryPage.orderStatus.spedito')
      ? '#06C270'
      : type === i18n.t('orderHistoryPage.orderStatus.speditoParziale')
        ? '#FFCC00'
        : type === i18n.t('orderHistoryPage.orderStatus.approvato')
          ? '#0057FF'
          : type === i18n.t('orderHistoryPage.orderStatus.inPreparazione')
            ? '#1890ff'
            : '#774A28'};

`
