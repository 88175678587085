import { DOCUMENTAL, DocumentalResponse, PaginationResource } from 'types'
import Cookies from 'universal-cookie'
import { api, responseErrorCheck } from './api'

export default class Documental {
  //Get paginated list of all documents
  static getDocumentList(
    type: DOCUMENTAL,
    page: number,
    itemPerPage: number,
    sortModifier: string) {
    const cookies = new Cookies()
    var url =
      'document/list/' + cookies.get('loggedErpUser') + '/' + type +
      '?page=' + page +
      '&itemPerPage=' + itemPerPage +
      '&orderBy=Document_Date&sortModifier=' + sortModifier
    return api
      .get<PaginationResource<DocumentalResponse>>(url, {})
      .then((res) => responseErrorCheck(res))
  }

  //Download document in logged user language
  static downloadDocument(type: string, number: string, language: string) {
    const cookies = new Cookies()
    var url = 'document/download/' + cookies.get('loggedErpUser') + '/' +
      type + '/' + number + '/' + language
    return api
      .get<string>(url, {})
      .then((res) => responseErrorCheck(res))
  }
}
