import Spacer from 'components/Spacer'
import moment from 'moment'
import styled from 'styled-components'
import i18n from 'translations/i18n'
import { QuantityAvailabilityPerDate } from 'types'

export default function FutureAvailabilityDrawer({
  quantityAvailable
}: {
  quantityAvailable?: QuantityAvailabilityPerDate[]
}) {
  return (
    <Drawer>
      <DrawerTitle>{i18n.t('availabilityPage.sub_drawer_2.title')}</DrawerTitle>
      <Spacer /><Spacer /><Spacer />
      <DetailsContainer>
        <TitleLeftColumn>{i18n.t('availabilityPage.sub_drawer_2.subtitle_1')} </TitleLeftColumn>
        <TitleRightColumn>{i18n.t('availabilityPage.sub_drawer_2.subtitle_2')}</TitleRightColumn>
      </DetailsContainer>
      <Spacer /><Spacer /><Spacer /><Spacer />
      {quantityAvailable &&
        quantityAvailable.map((n, index) => (
          <div key={index}>
            <DetailsContainer>
              <RowDetail>
                {moment(n.dateAvailable).format('DD/MM/YYYY')}
              </RowDetail>
              <RowDetail>{n.quantity}m</RowDetail>
            </DetailsContainer>
            <Spacer /><Spacer /><Spacer />
          </div>
        ))}
    </Drawer>
  )
}

const Drawer = styled.div`
  width: 100%;
  flex: 1;
`
const DetailsContainer = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0% 8%;
`
const DrawerTitle = styled.h3`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration-line: underline;
  color: #774a28;
`
const TitleLeftColumn = styled.div`
  display: flex;
  align-items: center;
  color: #774a28;
`
const TitleRightColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  color: #28293d;
`
const RowDetail = styled.div`
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #28293d;
`
