import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover, Select, Space } from 'antd'
import styled from 'styled-components'
import i18n from 'translations/i18n'
import { ErpUsers } from 'types'
import Spacer from './Spacer'

const { Option } = Select

export default function MultipleSelection({
  title,
  placeholder,
  defaultValues,
  erpUsersArray,
  valuesArray,
  width,
  onChangeSelection,
  mode,
  showSearch,
  popOver,
  selected
}: {
  title?: string
  placeholder?: string
  defaultValues: string[] | string
  valuesArray?: string[] | string
  erpUsersArray?: ErpUsers[]
  width?: string
  mode: string
  showSearch: boolean
  popOver?: boolean
  selected?: any

  onChangeSelection: (v: string) => void
}) {


  const getPopover = () => {
    return selected.map((element: ErpUsers) => {
      return <p key={element.code}>{element.code} - {element.name}</p>
    })
  }

  return (
    <Container style={{ width: width }}>
      {popOver && (
        <Popover
          title={<b>{i18n.t('misc.erps_selected')}</b>}
          content={getPopover}
          trigger="hover"
          placement="leftTop">
          <TitleIcon><Title><InfoCircleOutlined style={{ fontSize: '0.9vw' }} /> {title}</Title></TitleIcon>
        </Popover>
      )}
      {!popOver && (<Title>{title}</Title>)}
      <Spacer /> <Spacer />
      <Space direction="vertical" style={{ width: '100%' }}>
        <Selection
          showSearch={showSearch}
          allowClear
          mode={mode ? mode : ''}
          placeholder={placeholder}
          defaultValue={defaultValues}
          optionFilterProp="children"
          filterOption={(inputValue, option) =>
            erpUsersArray &&  erpUsersArray.length > 0 ?
            (option.children.join('').toLowerCase().includes(inputValue.toLowerCase())) :
            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 
            || option.props.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          } onChange={(e) => {
            onChangeSelection(e)
          }}
          style={{ width: '100%' }}
          maxTagCount="responsive"
        >
          {erpUsersArray &&
            typeof erpUsersArray != 'string' &&
            erpUsersArray.map((erpUser, index) =>
              <Option
                key={index}
                value={erpUser.code}>
                {erpUser.code} - {erpUser.name}
              </Option>)}
          {valuesArray &&
            typeof valuesArray != 'string' &&
            valuesArray.map((value, index) =>
              <Option
                key={index}
                value={value + ''}>
                {value}
              </Option>)}
        </Selection>
      </Space>
    </Container>
  )
}

const Selection = styled(Select)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 24px;
  padding: 3%;
  align-items: center;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 20px;
  height: 6vh;
  font-size: 0.8vw;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  background: white;
  &:hover {
    border: gray;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 24px;
`
const Title = styled.div`
  position: static;
  /* width: 400px; */

  left: 0px;
  top: calc(50% - 22px / 2 - 4px);

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;

  color: rgba(0, 0, 0, 0.85);
  text-align: left;
`
const TitleIcon = styled.div`
  display: flex;
  flex-direction: row;
`
