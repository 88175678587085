import styled from 'styled-components';
import i18n from 'translations/i18n';
import { OrderResponse, ORDER_STATUSES } from 'types';
import Spacer from './Spacer';

export default function SpecificOrderRow(props: { element: OrderResponse; onClick?: () => void }) {

  const getOrderStatus = (value: string): string =>  {
    switch (value) {
      case ORDER_STATUSES.SPEDITO:
        return '' + i18n.t('orderHistoryPage.orderStatus.spedito');
      case ORDER_STATUSES.INSERITO:
        return '' + i18n.t('orderHistoryPage.orderStatus.inserito');
      case ORDER_STATUSES.SPEDITO_PARZIALE:
        return '' + i18n.t('orderHistoryPage.orderStatus.spedito_parziale');
      case ORDER_STATUSES.APPROVATO:
        return '' + i18n.t('orderHistoryPage.orderStatus.approvato');
      case ORDER_STATUSES.PROMESSA:
      case ORDER_STATUSES.RICHIESTA:
        return '' + i18n.t('orderHistoryPage.orderStatus.in_preparazione');
      default:
        return '-';
    }
  }

  return (
    <Container onClick={props.onClick}>
      <Row>
        <Product>
          <ProductDetail>{props.element.crossRefDescription !== '' ? props.element.crossRefDescription : props.element.description}</ProductDetail>
          <OrderStatus type={getOrderStatus(props.element.statusLine)}>
            {getOrderStatus(props.element.statusLine)}
          </OrderStatus>
        </Product>
        <DeliveryDate>
          <DeliveryDateDetail>
            {props.element.shipmentDate !== '' ?
              props.element.shipmentDate :
              <span style={{ marginLeft: '6%', color: "#d9363e" }}>
                {i18n.t('orderHistoryPage.deliveryDateStatus.not_available')}
              </span>
            }
          </DeliveryDateDetail>
          {getOrderStatus(props.element.statusLine) === 'Richiesta' && (
            <DeliveryDateStatus type={getOrderStatus(props.element.statusLine)}>
              {i18n.t('orderHistoryPage.deliveryDateStatus.estimated_date')}
            </DeliveryDateStatus>
          )}
        </DeliveryDate>
        <Meters>
          {props.element.quantity + '' + props.element.unitMeasure}
        </Meters>
      </Row>
      <Spacer /><Spacer /><Spacer />
    </Container>
  )
}

const Container = styled.div`
  background: #ffffff;
  font-family: Rajdhani;
  font-style: normal;
  font-size: 1.2vw;
  margin-left: 0.5vw;
`;

const Row = styled.div`
  width: 24vw;
  display: flex;
  flex-direction: row;
  margin-left: 0.5vw;
`;

const Product = styled.div`
  width: 9.5vw;
  text-align: left;
`;

const ProductDetail = styled.div`
  font-size: 1.2vw;
  display: flex;
  align-items: center;
  color: #28293d;
`;

const OrderStatus = styled.div<{ type: string }>`
  font-weight: 500;
  font-size: 0.9vw;
  /* identical to box height, or 157% */

  color: ${({ type }) =>
    type === i18n.t('orderHistoryPage.orderStatus.spedito')
      ? '#06C270'
      : type === i18n.t('orderHistoryPage.orderStatus.spedito_parziale')
        ? '#FFCC00'
        : type === i18n.t('orderHistoryPage.orderStatus.approvato')
          ? '#0057FF'
          : type === i18n.t('orderHistoryPage.orderStatus.in_preparazione')
            ? '#ff7818'
            : '#774A28'};
`;

const DeliveryDate = styled.div`
  width: 9.5vw;
  text-align: center;
`;

const DeliveryDateDetail = styled.div`
  font-size: 1.2vw;
`;

const DeliveryDateStatus = styled.div`
  font-weight: 500;
  font-size: 0.9vw;
  color: #d9363e;
`;

const Meters = styled.div`
  width: 4.4vw;
  display:flex;
  justify-content: flex-end;
  text-align: right;
`;





