import styled from 'styled-components'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'

export default function ButtonDrawer({
  withLoader,
  text,
  inverted,
  onClick,
  disabled,
  ...props
}: {
  withLoader?: boolean
  inverted?: boolean
  text?: string
  onClick?: () => void
  disabled: boolean
  style?: any
}) {
  return (
    <>
      {!withLoader &&
        <ButtonStyle invertedColor={inverted} style={props.style} onClick={onClick} disabled={disabled}>
          {text}
        </ButtonStyle>
      }
      {withLoader &&
        <ButtonNoHoverStyled invertedColor={true} style={props.style} onClick={onClick} disabled={disabled}>
          {<LoaderContainer><LoadingIcons style={{ width: '6vw', height: '4vh' }} /></LoaderContainer>}
        </ButtonNoHoverStyled>
      }
    </>
  )
}

const ButtonStyle = styled.button<{}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 24px;

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;

  font-size: 1.2vw !important;
  padding: 10px 25px !important;

  border-color: #d9d9d9;
  border-width: 0.1vw;

  /* Neutral/Light Grey */
  background: white;
  color: #a1a2aa;
  border-radius: 24px;

  justify-content: center;
  :hover {
    background: #bba391;
    color: white;
  }
`
const ButtonNoHoverStyled = styled.button<{}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 24px;

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;

  font-size: 1.2vw !important;
  padding: 10px 25px !important;

  border-color: #d9d9d9;
  border-width: 0.1vw;

  /* Neutral/Light Grey */
  background: white;
  color: #a1a2aa;
  border-radius: 24px;

  justify-content: center;
`
const LoaderContainer = styled.div`
  margin: auto;
  width: 6vw;
  height: 4vh;
`
