import i18n, { TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'universal-cookie'

import en from './en'
import it from './it'
import es from './es'

const resources = {
  it: { translation: it },
  en: { translation: en },
  es: { translation: es },
}
const cookie = new Cookies()
const appStoreLangauge: string = cookie.get('language')

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    lng: appStoreLangauge ?? 'en',

    fallbackLng: appStoreLangauge ?? 'en',

    interpolation: {
      escapeValue: false,
    },
  })

////////////////////////////////////////////////////
// HACK: use languages auto-completion
////////////////////////////////////////////////////
interface TranslationCategory {
  [key: string]: string
}

let TRANSLATION_MAP = it

// replace object values with keys
Object.keys(it).forEach((catKey, idx) => {
  const subCat: TranslationCategory = {}
  Object.keys(it[catKey]).forEach((key) => {
    subCat[key] = `${catKey}.${key}`
  })

  if (idx === 0) {
    TRANSLATION_MAP = {} as typeof it
  }

  TRANSLATION_MAP[catKey] = subCat
})
export { TRANSLATION_MAP as T }
////////////////////////////////////////////////////

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __ = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap)

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __UP = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap).toUpperCase()

export const changeLanguage = (locale: string) => {
  i18n.changeLanguage(locale)
}

export default i18n
