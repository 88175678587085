import styled from 'styled-components'
import Button from 'components/Button'
import { useState } from 'react'
import Spacer from '../Spacer'
import { User } from 'types'
import { showError, showInfo } from 'utils'
import Users from 'api/Users'
import Auth from 'api/Auth'
import i18n from 'translations/i18n'

export default function PasswordDrawer({
  passwordExpiringIn,
  user,
  onClick,
}: {
  passwordExpiringIn: string
  user: User
  onClick: () => void
}) {
  const [newPassword, setNewPassword] = useState('***************')
  const [newUser, setnewUser] = useState<User>({ ...user })

  const generateNewPasssword = async () => {
    try {
      const res = await Users.getNewCredential()
      setNewPassword(res.pwd)
      setnewUser({ ...newUser, pwd: res.pwd })
    } catch (error) {
      showError(i18n.t('customersPage.drawer_password.error_password'))
    }
  }

  const patchUser = async () => {
    try {
      await Users.patchUserById(user.id, [{ op: 'replace', path: '/pwd', value: newUser.pwd }])
      await Auth.newPassword(newUser.name, newUser.surname, newUser.businessName, newUser.email, newUser.language)
      showInfo(i18n.t('customersPage.drawer_password.info_modify_password'))
      onClick()
    } catch (error) {
      showError(i18n.t('customersPage.drawer_password.error_modify_password'))
    }
  }

  return (
    <Drawer>
      <Title>{i18n.t('customersPage.drawer_password.title')}</Title>
      <RemainingDays>{passwordExpiringIn + ' '} {i18n.t('customersPage.drawer_password.subtitle_days')}</RemainingDays>
      <Spacer /> <Spacer />
      <ButtonsContainer>
        <Button text={i18n.t('customersPage.drawer_password.button_generate')} onClick={() => generateNewPasssword()}></Button>
        <Spacer /> <Spacer /><Spacer /> <Spacer />
        <NewPassword>{newPassword}</NewPassword>
        <Spacer /> <Spacer />
        <Button text={i18n.t('customersPage.drawer_password.button_confirm')} onClick={patchUser}></Button>
      </ButtonsContainer>
    </Drawer>
  )
}

const Drawer = styled.div`
  flex: 1;
  margin-left: 2.222vw;
  margin-right: 2.222vw;
`
const Title = styled.h3`
  font-weight: 500;
  font-size: 1.5vw;
  color: #000000;
`
const ButtonsContainer = styled.div`
  text-align: -webkit-center;
`
const RemainingDays = styled.h2`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vw;
  color: #000000;
`
const NewPassword = styled.h2`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 300;
  font-size: 1.9vw;
  color: #000000;
`
