import {
  HomePage,
  AddUserPage,
  CustomerRowPage,
  DisponibilityPage,
  OrderHistoryPage,
  Page,
} from 'pages';
import DocumentsPage from 'pages/DocumentsPage';
import Login from 'pages/Login';
import ShoppingCartPage from 'pages/ShoppingCartPage';
import { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { User } from 'types';
import { PERMISSIONS } from 'types/permissions.domain.service';
import AppStore from 'utils/AppStore';
import GuardedRoute from './GuardedRoute';

export default function Router() {
  const [loggedUser, setloggedUser] = useState<User>(AppStore.loggedUser);
  const [loggedErpUser, setloggedErpUser] = useState<string>(AppStore.loggedErpUser);

  if (!loggedUser && !loggedErpUser) {
    return (
      <Switch>
        <Route path="/" exact>
          <Login onLoginCallback={setloggedUser} onLoginCallback2={setloggedErpUser} />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  };
    

  return (
    <Switch>
      <GuardedRoute 
        path="/documentale" 
        permission='menu.documentary' 
        userPermissions={AppStore.loggedUser.permissions}
      >
        <Page>
          <DocumentsPage permission={PERMISSIONS.MENU_DOCUMENTARY} />
        </Page>
      </GuardedRoute>

      <GuardedRoute 
        path="/documenti-logistici" 
        permission='menu.logistic_documents' 
        userPermissions={AppStore.loggedUser.permissions}
      >
        <Page>
          <DocumentsPage permission={PERMISSIONS.MENU_LOGISTIC_DOCUMENTS}/>
        </Page>
      </GuardedRoute>

      <GuardedRoute 
        path="/carrello" 
        permission='menu.cart' 
        userPermissions={AppStore.loggedUser.permissions}
      >
        <Page>
          <ShoppingCartPage />
        </Page>
      </GuardedRoute>

      <GuardedRoute 
        path="/storico" 
        permission='menu.order_history' 
        userPermissions={AppStore.loggedUser.permissions}
      >
        <Page>
          <OrderHistoryPage />
        </Page>
      </GuardedRoute>

      <GuardedRoute 
        path="/disponibilita-web" 
        permission='menu.availability' 
        userPermissions={AppStore.loggedUser.permissions}
      >
        <Page>
          <DisponibilityPage />
        </Page>
      </GuardedRoute>

      <GuardedRoute 
        path="/add-user" 
        permission='menu.admin' 
        userPermissions={AppStore.loggedUser.permissions}
      >
        <Page>
          <AddUserPage />
        </Page>
      </GuardedRoute>

      <GuardedRoute 
        path="/admin" 
        permission='menu.admin' 
        userPermissions={AppStore.loggedUser.permissions}
      >
        <Page>
          <CustomerRowPage />
        </Page>
      </GuardedRoute>

      <Route path="/" exact>
        {localStorage.setItem("index", "1")}
        <Page>
          <HomePage />
        </Page>
      </Route>

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
