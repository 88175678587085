import { toast, ToastOptions } from 'react-toastify'

export { default as AppStore } from './AppStore'

export const showError = (message: string, options?: ToastOptions) => {
  return toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    ...options,
  })
}

export const showInfo = (message: string, options?: ToastOptions) => {
  return toast.info(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    ...options,
  })
}

export const showTimerTime = (message: string, closeTime: number, options?: ToastOptions) => {
  return toast.warn(message, {
    position: 'top-right',
    autoClose: closeTime,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    ...options,
  })
}
