import Spacer from 'components/Spacer'
import styled from 'styled-components'
import { User } from 'types'

export default function AddUserPageStep4({ newUser, setNewUser }: { newUser: User; setNewUser: (user: User) => void }) {
  return (
    <>
      <SmallTitle>Codice utente</SmallTitle>
      <TextContainer>
        <Text>{newUser.username}</Text>
      </TextContainer>
      <SmallTitle>Password utente</SmallTitle>
      <TextContainer>
        <Text>{newUser.pwd}</Text>
      </TextContainer>
      <SmallTitle>Email utente</SmallTitle>
      <TextContainer>
        <Text>{newUser.email}</Text>
      </TextContainer>
      <Spacer /> <Spacer />
    </>
  )
}

const SmallTitle = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;

  color: rgba(0, 0, 0, 0.85);

  flex: none;
  order: 0;
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align-last: left;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 24px;
  height: 4vh;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 16px;
  align-items: center;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;

  margin-bottom: 20px;
`
const Text = styled.h5`
  width: 395px;
  height: 40px;

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;

  display: flex;
  align-items: center;

  color: #28293d;

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;
`
