import styled from 'styled-components';
import { Button as AntButton, Radio, Input as InputRadio } from 'antd';
import { useState } from 'react';
import { Button, Input, Spacer } from 'components';
import Auth from 'api/Auth';
import { showError, showInfo } from 'utils';
import { CaretLeftOutlined } from '@ant-design/icons';
import { ReactComponent as LoadingIcons } from 'assets/loading.svg';
import i18n from 'translations/i18n';
import TextArea from 'antd/lib/input/TextArea';
import { RadioChangeEvent } from 'antd/lib/radio';
import { reloadApp } from 'utils/router';
import Cookies from 'universal-cookie';

export default function ContactUsCard({
    onClick
}: {
    onClick: (value: boolean) => void
}) {

    const cookies = new Cookies()

    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [businessName, setBusinessname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [subjectValue, setSubjectValue] = useState<number>(1);
    const [subject, setSubject] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const sendMail = async () => {
        const language = cookies.get('language') === 'it' ? 'ITALIAN' : (cookies.get('language') === 'en' ? 'ENGLISH' : 'SPANISH');
        let re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (name === '' || surname === '' || businessName === '' || email === '') return showError(i18n.t('login_page.contact_us.error_fill_form'));
        if (email !== '' && !re.test(email)) return showError(i18n.t('login_page.contact_us.success'));
        if (email !== '' && re.test(email)) {
            if (subjectValue === 1) {
                try {
                    setLoading(true);
                    await Auth.newPassword(name, surname, businessName, email, language);
                    setLoading(false);
                    onClick(true);
                    showInfo(i18n.t('login_page.contact_us.success'));
                    reloadApp();
                } catch (error) {
                    setLoading(true);
                    showError(i18n.t('login_page.contact_us.error_user_not_found'));
                    reloadApp();
                }
            } else {
                if (subjectValue !== 1 && subject !== '' && message !== '') {
                    try {
                        setLoading(true);
                        await Auth.contactSupport(name, surname, businessName, email, subject, message, language);
                        setLoading(false);
                        onClick(true);
                        showInfo(i18n.t('login_page.contact_us.success_send_mail'));
                        reloadApp();
                    } catch (error) {
                        setLoading(true);
                        showError(i18n.t('login_page.contact_us.error_support_mail'));
                        reloadApp();
                    }
                } else {
                    showError(i18n.t('login_page.contact_us.error_no_subject_or_msg'));
                }
            }
        }
    }


    return (
        <>
            <CardTitle>{i18n.t('login_page.contact_us.title')}</CardTitle>
            <BackButton>
                <AntButton onClick={() => onClick(true)} shape="circle" icon={<CaretLeftOutlined />} />
            </BackButton>
            <Spacer />
            {loading && <LoadingIcons />}
            {!loading && (
                <FormContainer>
                    <FullName>
                        <Input
                            type="text"
                            title={i18n.t('login_page.contact_us.input_name.label')}
                            placeholder={i18n.t('login_page.contact_us.input_name.value')}
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Spacer />
                        <Input
                            type="text"
                            title={i18n.t('login_page.contact_us.input_surname.label')}
                            hidetext
                            placeholder={i18n.t('login_page.contact_us.input_surname.value')}
                            defaultValue={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                    </FullName>
                    <Spacer />
                    <Input
                        type="text"
                        title={i18n.t('login_page.contact_us.input_company.label')}
                        hidetext
                        placeholder={i18n.t('login_page.contact_us.input_company.value')}
                        defaultValue={businessName}
                        onChange={(e) => setBusinessname(e.target.value)}
                    />
                    <Spacer />
                    <Input
                        type="text"
                        title={i18n.t('login_page.contact_us.input_email.label')}
                        hidetext
                        placeholder={i18n.t('login_page.contact_us.input_email.value')}
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Spacer />
                    <SubjectOptions>
                        <Title>{i18n.t('login_page.contact_us.input_type_support.label')}</Title>
                        <Spacer />
                        <Radio.Group
                            defaultValue={1}
                            value={subjectValue}
                            onChange={(e: RadioChangeEvent) => {
                                setSubjectValue(e.target.value)
                                if (e.target.value === 1) setSubject(i18n.t('login_page.contact_us.subject_options.forgot_password'))
                                if (e.target.value === 2) setSubject(i18n.t('login_page.contact_us.subject_options.new_user'))
                                if (e.target.value === 3) setSubject('')
                            }}
                        >
                            <OptionsContainer>
                                <RadioOption value={1}>{i18n.t('login_page.contact_us.subject_options.forgot_password')}</RadioOption>
                                <RadioOption value={2}>{i18n.t('login_page.contact_us.subject_options.new_user')}</RadioOption>
                            </OptionsContainer>
                            <Spacer /><Spacer />
                            <OptionsContainer>
                                <RadioOption value={3}>
                                    {i18n.t('login_page.contact_us.subject_options.other')}
                                </RadioOption>
                                {subjectValue === 3 ?
                                    <InputRadioStyled
                                        placeholder={i18n.t('login_page.contact_us.input_type_support.value')}
                                        onChange={(e) => {
                                            setSubject(e.target.value)
                                        }}
                                    /> : null}
                            </OptionsContainer>

                        </Radio.Group>
                    </SubjectOptions>
                    <Spacer />
                    {subjectValue !== 1 && (
                        <Message>
                            <Title>{i18n.t('login_page.contact_us.input_message.label')}</Title>
                            <TextAreaStyled
                                autoSize={{ minRows: 2, maxRows: 2 }}
                                placeholder={i18n.t('login_page.contact_us.input_message.value')}
                                defaultValue={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Message>
                    )}
                    <Spacer />
                </FormContainer>
            )}
            <Spacer />
            <SendButton onClick={sendMail} text={i18n.t('login_page.contact_us.button')} />
        </>
    )
}


const CardTitle = styled.h1`
    font-family: cormorant-garamond, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 4vw;
    color: #28293d;
    display: flex;
    align-items: center;
    text-align: center;
    place-content: center;
    margin-top: 1vw;
    margin-bottom: 1vw;
`;
const BackButton = styled.div`
    align-self: flex-start;
    margin-left: 7%;
    margin-top: -7%;
`;
const FormContainer = styled.div`
    width: 28vw;
    margin-left: 2vw;
`;
const FullName = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-around;
`;
const Message = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 24px;
`;
const Title = styled.div`
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1vw;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
    position: static;
    top: calc(50% - 22px / 2 - 4px);
`;
const TextAreaStyled = styled(TextArea)`
    height: 10vh;
    background: #ffffff;
    font-size: 1vw !important;
    align-items: flex-start;
    align-self: stretch;
    padding: 0px 24px;
    padding-top: 8px;
    top: 15px;
    border: 1px solid #d9d9d9 !important;
    box-sizing: border-box;
    border-radius: 16px;

    &::placeholder {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 500;
    }
`;
const SubjectOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1vw;
    color: rgba(0, 0, 0, 0.85);
`;
const RadioOption = styled(Radio)`
    font-size: 1vw;
`;
const InputRadioStyled = styled(InputRadio)`
    font-size: 1vw !important;
    width: 400px; 
    height: 4vh;
    marginLeft: 10px;
    margin-top: -5px;
    padding: 0px 24px;
    border: 1px solid #d9d9d9 !important;
    box-sizing: border-box;
    border-radius: 16px;

    &::placeholder {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 500;
    }
`;
const SendButton = styled(Button)`
    font-size: 1.3em !important;
    padding: 12 - 180px !important;
`;
const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;