import styled from 'styled-components';
import download_icon from 'assets/download_icon.png';
import { DOCUMENTAL } from 'types';
import Documental from 'api/Documental';
import { AppStore, showError } from 'utils';
import { useState } from 'react';
import { ReactComponent as LoadingIcons } from 'assets/loading.svg';
import Spacer from './Spacer';

export default function ChartRow(props: { number: string; date: string; type: DOCUMENTAL; onClick1: () => void }) {
  const [loading, setLoading] = useState(false)
  const downloadDocument = async () => {
    try {
      setLoading(true)
      const userLanguage = AppStore.getLanguageValue() === 'it' ? 'ITALIAN' : (AppStore.getLanguageValue() === 'en' ? 'ENGLISH' : 'SPANISH')
      const res = await Documental.downloadDocument(props.type, props.number, userLanguage)
      downloadPDF(res)
    } catch (error) {
      showError('Impossibile scaricare pdf')
      setLoading(false)
    }
  }

  function downloadPDF(pdf: string) {
    showPdfInNewTab(pdf, props.number + '.pdf')
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement('a')
    const fileName = props.number + '.pdf'
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
    setLoading(false)
  }
  function showPdfInNewTab(base64Data, fileName) {
    let pdfWindow = window.open('')
    pdfWindow?.document.write(
      '<html<head><title>' + fileName + '</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>'
    )
    pdfWindow?.document.write(
      "<body><embed width='100%' height='100%' src='data:application/pdf;base64, " +
      encodeURI(base64Data) +
      "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>"
    )
  }

  function convertDate(string_date: string) {
    const _tmp = new Date(Number(string_date)).valueOf()
    let date = new Intl.DateTimeFormat('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(_tmp)

    return date
  }

  return (
    // 0: Spedito, 1: Spedito parziale, 2: Inserito, 3: Approvato
    <Container onClick={props.onClick1}>
      {!loading && (
        <>
          <LeftLeftText style={{width: '13vw', textAlign: 'center' }}>
            {'N.' + props.number}
          </LeftLeftText>
          <LeftText style={{ width: '13vw', textAlign: 'center'}}>{convertDate(props.date)}</LeftText>
          <RightText
            onClick={downloadDocument}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginLeft: 'auto',
              width: '13vw',
              textAlign: 'center',
              margin: 'auto',
            }}
          >
            <p>Download</p><Spacer />
            <img style={{ width: '1.5vw' }} src={download_icon} alt=""></img>
          </RightText>
        </>
      )}
      {loading && <LoadingIcons />}
    </Container>
  )
}

const Container = styled.div`
  background: #ffffff;
  width: 39.5vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 0.5vw;
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
  :hover {
    box-shadow: 0px 1px 4px rgb(40 41 61 / 4%), 0px 8px 12px rgb(96 97 112 / 16%);
    border-radius: 50px;
  }
`

const LeftLeftText = styled.h4`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  margin-top: 1vw;

  font-size: 1.4vw;
  color: #28293d;

  align-self: center;
  cursor: default;
`
const LeftText = styled.h4`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  margin-top: 1vw;

  font-size: 1.5vw;
  color: #a1a2aa;
  cursor: default;
`

const RightText = styled.h5<{ type: string }>`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  margin-top: 1vw;

  font-size: 1.4vw;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Neutral/Grey */

  color: #a1a2aa;
  align-self: center;
`
