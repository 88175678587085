import { useEffect, useState } from 'react'
import {
  Button,
  Input,
  MultipleSelection,
  Spacer,
  Text
} from 'components'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Radio } from 'antd'
import Users from 'api/Users'
import { ErpUsers, ROLES, User } from 'types'
import styled from 'styled-components'
import i18n from 'translations/i18n'
import { showError, showInfo } from 'utils'
import { createPatch } from 'rfc6902'

export default function UserDrawer({
  user,
  onClick,
  closeAndUpdate
}: {
  user: User
  onClick: () => void,
  closeAndUpdate: () => void
}) {

  const [visible, setVisible] = useState<boolean>(false)
  const [newUser, setnewUser] = useState<User>({ ...user })
  const [changeRoleTo, setChangeRoleTo] = useState<ROLES>()
  //To handle multiselect component
  const [erpUsers, setErpUsers] = useState<ErpUsers[]>([])
  const [selected, setSelected] = useState<ErpUsers[]>([])


  const formatErpUsers = (erpArray: any) => {
    let newErpUsers: ErpUsers[] = [];
    let newErp: ErpUsers = { code: '', name: '' }
    for (let i = 0; i < erpArray.length; i++) {
      newErp = erpUsers.filter(item => item.code === erpArray[i])[0]
      newErpUsers.push(newErp);
    }
    newErpUsers = [...newErpUsers];
    setSelected(newErpUsers);
    setnewUser({ ...newUser, erpUsers: newErpUsers })
  }


  const patchUserFields = async () => {
    try {
      await Users.patchUserById(user.id, createPatch(user, newUser));
      showInfo(i18n.t('customersPage.drawer_info.info_modify_user'))
      closeAndUpdate();
    } catch (error) {
      showError(i18n.t('customersPage.drawer_info.error_modify_user'))
    }
  }

  //Return list of all erp codes
  const getErpUsers = async (value: string, page: string, itemPerPage: string) => {
    try {
      const res = await Users.getErpUserByName(value, page, itemPerPage)
      setErpUsers(res.elements)
    } catch (error) {
      showError(i18n.t('customersPage.drawer_info.error_erp_user'))
    }
  }

  useEffect(() => {
    setnewUser(user)
    setSelected(user.erpUsers)
    getErpUsers('', '0', '3000')
  }, [user])

  const confirmChangedRole = () => {
    setnewUser({ ...newUser, roles: [changeRoleTo!] })
    setVisible(false)
  }

  return (
    <Drawer>
      <Title><Text title={i18n.t('customersPage.drawer_info.title1')} defaultValue={user?.id + ''} /></Title>
      <CenterELements>
        <Input
          type="text"
          title={i18n.t('customersPage.drawer_info.label_name')}
          defaultValue={user?.name}
          onChange={(e) => {
            setnewUser({ ...newUser, name: e.target.value })
          }}
        ></Input>
        <Spacer /><Spacer />
        <Input
          type="text"
          title={i18n.t('customersPage.drawer_info.label_surname')}
          defaultValue={user?.surname}
          onChange={(e) => setnewUser({ ...newUser, surname: e.target.value })}
        ></Input>
      </CenterELements>
      <Spacer />
      <Input
        type="text"
        title={i18n.t('customersPage.drawer_info.label_email')}
        defaultValue={user?.email}
        onChange={(e) => setnewUser({ ...newUser, email: e.target.value })}
      ></Input>
      <Spacer />
      <Input
        type="text"
        title={i18n.t('customersPage.drawer_info.label_business_name')}
        defaultValue={user?.businessName}
        onChange={(e) => setnewUser({ ...newUser, businessName: e.target.value })}
      ></Input>
      <Spacer /> <Spacer />
      <ProfileRole>{i18n.t('customersPage.drawer_info.title2')}</ProfileRole>
      <CenterELements>
        <ChoiceGroup defaultValue={newUser.roles[0]}>
        <ChoiceButton
            onChange={(e) => {
              if (e.target.value === ROLES.BASIC) {
                setChangeRoleTo(ROLES.BASIC)
                setVisible(true)
              }
            }}
            value="BASIC"
          >
            BASIC USER
          </ChoiceButton>
          <Spacer />
          <ChoiceButton
            onChange={(e) => {
              if (e.target.value === ROLES.USER) {
                setChangeRoleTo(ROLES.USER)
                setVisible(true)
              }
            }}
            value="USER"
          >
            USER
          </ChoiceButton>
          <Spacer />
          <ChoiceButton
            onChange={(e) => {
              if (e.target.value === ROLES.POWERUSER) {
                setChangeRoleTo(ROLES.POWERUSER)
                setVisible(true)
              }
            }}
            value="POWERUSER"
          >
            ADMIN
          </ChoiceButton>
        </ChoiceGroup>
      </CenterELements>
      <Spacer /><Spacer /><Spacer />
      <Input
        type='text'
        title='Username'
        defaultValue={user.username}
        readOnly={true}
      />
      <MultipleSelection
        showSearch={true}
        mode="multiple"
        onChangeSelection={(e) => { formatErpUsers(e) }}
        title={i18n.t('customersPage.drawer_info.label_erp_users')}
        erpUsersArray={erpUsers}
        placeholder={i18n.t('customersPage.drawer_info.placeholder_erp_users')}
        defaultValues={user.erpUsers.map(item => item.code)}
        popOver={true}
        selected={selected}
      ></MultipleSelection>
      <Button
        style={{ margin: 'auto' }}
        text={i18n.t('customersPage.drawer_info.button_confirm')}
        onClick={() => {
          patchUserFields()
        }}
      ></Button>
      <ModalStyled
        visible={visible}
        closable={false}
        width={350}
        footer={[<ButtonStyle onClick={confirmChangedRole}>OK</ButtonStyle>]}
        style={{
          top: 220,
          left: '35.5%'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '3.5%' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#faad14' }} />
            <p style={{ fontSize: '25px', marginLeft: '8px', lineHeight: '1.2' }}>
              {i18n.t('customersPage.drawer_info.modal_title')}
            </p>
          </div>
          <Spacer />
          <p style={{ fontSize: '17px', marginLeft: '33px' }}>
            {i18n.t('customersPage.drawer_info.modal_context')}
          </p>
        </div>
      </ModalStyled>
    </Drawer>
  )
}

const Drawer = styled.div`
  flex: 1;
  margin-left: 2.3vw;
  margin-right: 2.3vw;
`
const Title = styled.div`
  width: 25vw;
  margin: auto;
`
const CenterELements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ProfileRole = styled.h5`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;
  color: rgba(0, 0, 0, 0.85);
`
const ChoiceGroup = styled(Radio.Group)` 
  display: flex;
  width: 100%;
`
const ChoiceButton = styled(Radio.Button)`
  display: flex;
  flex-direction: row;
  padding: 0px 24px;
  background: #ffffff;
  color: #28293d;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 8px;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  align-items: center;
  color: #28293d;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;

  justify-content: center;
  ::hover {
    background: #bba391;
    color: white;
  }
`
const ButtonStyle = styled.button<{ invertedColor: boolean }>`
  cursor: pointer;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  padding: 2px 20px;

  background: ${({ invertedColor }) => (invertedColor ? '#bba391' : '#d8d7db')};
  color: ${({ invertedColor }) => (invertedColor ? 'white' : 'black')};
  border-radius: 24px;
  border-style: none;
  :hover {
    background: #bba391;
    color: white;
  }
`
const ModalStyled = styled(Modal)`
  .ant-modal-content{
    border-radius: 24px;
  }
`