import { admin, logout } from 'assets/icons';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AppStore from 'utils/AppStore';
import { ButtonLeftSider } from 'components';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import i18n from 'translations/i18n';
import { MenuVoices } from 'types';
import { reloadApp } from 'utils/router';
import { showTimerTime } from 'utils';
import Cart from 'api/Cart';
import { MenuLinkDomain, PERMISSIONS } from 'types/permissions.domain.service';

const { Sider, Content } = Layout;

export default function Page({
  children
}: {
  children?: any
}) {

  const [timerCart, setTimerCart] = useState('0');
  const cookies = new Cookies();
  const [menuVoices, setMenuVoices] = useState<MenuVoices[]>();

  const calculateDifference = (dateExp: string): string => {
    let diffTime = new Date(Number(dateExp)).valueOf() - new Date().valueOf();
    let days = diffTime / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;
    if (
      (Math.floor(days) + '').slice(-2) === '0' &&
      (Math.floor(hours) + '').slice(-2) === '0' &&
      (Math.floor(minutes) + '').slice(-2) === '0' &&
      (Math.floor(secs) + '').slice(-2) === '0'
    )
      reloadApp();

    if (diffTime <= 0) {
      const timer: string = 'timer' + cookies.get('loggedErpUser');
      localStorage.setItem(timer, '0');
      const expressShipment = 'expressShipment' + cookies.get('loggedErpUser');
      localStorage.setItem(expressShipment, 'false');
      return '0';
    }

    if (
      (Math.floor(days) + '').slice(-2) === '0' &&
      (Math.floor(hours) + '').slice(-2) === '0' &&
      (Math.floor(minutes) + '').slice(-2) === '2' &&
      (Math.floor(secs) + '').slice(-2) === '0'
    ) {
      cookies.set('popupShowed', true);
      showTimerTime(i18n.t('warning.warning_cart_two_minutes'), 10000);
    };

    // if (
    //   (Math.floor(days) + '').slice(-2) === '0' &&
    //   (Math.floor(hours) + '').slice(-2) === '0' &&
    //   (Math.floor(minutes) + '').slice(-2) < '2' &&
    //   !cookies.get('popupShowed')
    // ) {
    //   cookies.set('popupShowed', true)
    //   showTimerTime(
    //     'Il carrello verrà svuotato tra meno di 2 minuti!',
    //     Number((Math.floor(minutes) + '').slice(-2)) * 60000 + Number((Math.floor(secs) + '').slice(-2)) * 1000
    //   )
    // }

    if (
      (Math.floor(days) + '').slice(-2) === '0' &&
      (Math.floor(hours) + '').slice(-2) === '0' &&
      (Math.floor(minutes) + '').slice(-2) === '0' &&
      (Math.floor(secs) + '').slice(-2) === '10'
    )
      showTimerTime(i18n.t('warning.warning_cart_ten_seconds'), 10000);

    return (Math.floor(minutes) + '').slice(-2) + 'min  ' + (Math.floor(secs) + '').slice(-2) + 'sec';
  };

  const getTimerFromServer = async () => {
    try {
      const res = await Cart.getCartIfExist();
      const timer: string = 'timer' + cookies.get('loggedErpUser');
      localStorage.setItem(timer, res.summary.expiringDateTime);
      setTimerCart(calculateDifference(res.summary.expiringDateTime));
    } catch (error) { }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const timer: string = 'timer' + cookies.get('loggedErpUser');
      if (!localStorage.getItem(timer)) {
        getTimerFromServer();
        return;
      }
      setTimerCart(calculateDifference(localStorage.getItem(timer) + ''));
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => { 
    let menu: MenuVoices[] = [];
    AppStore.loggedUser.permissions.map((p) => {
      return menu.push({
        permission: p,
        position: MenuLinkDomain.get(p)?.position!
      });
    })
    setMenuVoices(menu.sort(function (a, b) {
      var x = a["position"]
      var y = b["position"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    }))
  }, [setMenuVoices]);

  return (
    <Layout>
      <LeftSiderContainer>
        <LeftSider>
          <Title>{i18n.t('sidebar.title')} {AppStore.loggedUser.name}</Title>
          <Subtitle>
            {cookies.get('loggedErpUser')} - {cookies.get('loggedBusinessName')}
          </Subtitle>
          {menuVoices?.filter((p) => {return !p.permission.match(PERMISSIONS.MENU_ADMIN)}).map((p, index) => {
            return (
              <Link to={MenuLinkDomain.get(p.permission)?.path}>
                <ButtonLeftSider
                  key={index}
                  text={i18n.t(MenuLinkDomain.get(p.permission)?.label!)}
                  icon={MenuLinkDomain.get(p.permission)?.icon}
                  selected={Number(localStorage.getItem('index')) === index + 1}
                  onClick={() => localStorage.setItem('index', (index + 1).toString())}
                  children={p.permission.match(PERMISSIONS.MENU_CART) ? (timerCart !== '0' && timerCart) : null}
                />
              </Link>);
          })}
          <ButtonLeftSider
            text={i18n.t('sidebar.menu.logout')}
            icon={logout}
            selected={Number(localStorage.getItem('index')) === 7}
            onClick={() => {
              cookies.remove('loggedErpUser')
              cookies.remove('loggedBusinessName')
              AppStore.setToken()
              localStorage.setItem('index', '1')
              if (timerCart === '0') {
                const timer: string = 'timer' + cookies.get('loggedErpUser')
                localStorage.setItem(timer, '0')
                const expressShipment = 'expressShipment' + cookies.get('loggedErpUser')
                localStorage.setItem(expressShipment, 'false')
              }
              window.location.href = '/'
            }}
          />
        </LeftSider>
        {menuVoices?.find((p) => {return p.permission.match(PERMISSIONS.MENU_ADMIN);}) && (
          <Link to="/admin">
            <ButtonLeftSider
              text={i18n.t('sidebar.menu.admin')}
              icon={admin}
              marginTop="15vh"
              position="absolute"
              bottom="0"
              selected={Number(localStorage.getItem('index')) === MenuLinkDomain.get(PERMISSIONS.MENU_ADMIN)?.position}
              onClick={() => localStorage.setItem('index', MenuLinkDomain.get(PERMISSIONS.MENU_ADMIN)?.position!.toString()!)}
            />
          </Link>
        )}
      </LeftSiderContainer>
      <Layout>
        <MainContent>{children}</MainContent>
      </Layout>
    </Layout>
  );
};

const LeftSiderContainer = styled.div`
  width: 23.958vw;
  height: 100vh;
  background: #ffff;
  /* box-shadow: 44.6468px 89.48px 30px rgb(22 26 51 / 2%), 17.1973px 34.4664px 9.55556px rgb(0 28 203 / 5%),
    3.63789px 7.29096px 2.44444px rgb(22 26 51 / 6%); */
  box-shadow: 44.6468px 89.48px 30px rgba(22, 26, 51, 0.03), 17.1973px 34.4664px 9.55556px rgba(22, 26, 51, 0.0182222),
    3.63789px 7.29096px 2.44444px rgba(22, 26, 51, 0.0117778);
  z-index: 10;
`;

const LeftSider = styled(Sider)`
  width: 100vw !important;
  display: flex;
  background: transparent;
`;

const Title = styled.h1`
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vw;
  color: #28293d;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  margin-top: 2.5vh;
  text-align: center;
`;

const Subtitle = styled.div`
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  color: #28293d;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
  margin-top: -1.5vh;
  margin-bottom: 4.5vh;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 18vw;
  line-height: 1;
  text-align: center;
`;

const MainContent = styled(Content)`
  background: white;
`;
