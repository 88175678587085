import styled from 'styled-components';
import emptyStateImage from 'assets/empty_image.png';

export default function EmptyRow(props: { text: string }) {
  return (
      <Container>
        <CustomImage src={emptyStateImage}></CustomImage>
        <RightText>{"No "+props.text}</RightText>
      </Container>
  )
}

const Container = styled.div`
  background: #ffffff;

  display: flex;
  justify-content: space-between;
  margin-top: 20s%;

  flex-direction: column;
  /* :hover {
    box-shadow: 0px 1px 4px rgb(40 41 61 / 4%), 0px 8px 12px rgb(96 97 112 / 16%);
    border-radius: 50px;
  } */
`

const CustomImage = styled.img`
  width: 20vw;
  height: 20vw;
  align-self: center;
`
const RightText = styled.h5`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  color: #a1a2aa;
  align-self: center;
`
