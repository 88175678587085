import { PERMISSIONS } from "types/permissions.domain.service";
import DocumentsPageContent from "./DocumentsPageContent";

export default function DocumentsPage(props: {
    permission: PERMISSIONS
}) {

    return (
        <>
            {props.permission.match(PERMISSIONS.MENU_DOCUMENTARY) && <DocumentsPageContent permission={props.permission} />}
            {props.permission.match(PERMISSIONS.MENU_LOGISTIC_DOCUMENTS) && <DocumentsPageContent permission={props.permission} />}
        </>
    )
};