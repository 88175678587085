import styled from 'styled-components';

export default function ButtonLeftSider({
  text,
  icon,
  marginTop,
  selected,
  onClick,
  position,
  bottom,
  children
}: {
  text?: string
  icon?: string
  marginTop?: string
  selected: boolean
  position?: string
  bottom?: string
  onClick?: () => void
  style?: any
  children?: any
}) {
  return (
    <ButtonStyle
      selected={selected}
      onClick={onClick}
      marginTop={marginTop}
      bottom={bottom}
      position={position}
    >
      <ImageStyle selected={selected} src={icon}></ImageStyle>
      <Text selected={selected}>{text}</Text>
      <Text selected={selected}>{children}</Text>
    </ButtonStyle>
  );
};

const ButtonStyle = styled.button<{ selected: boolean, position: string, bottom: string, marginTop: string }>`
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 20.069vw;
  height: 6.5vh;
  /* background: #ffffff; */
  background: ${({ selected }) => (selected ? '#bba391' : '#ffffff')};

  border-radius: 0px 40px 40px 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '10px')};
  margin-bottom: 3vh;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 3vh;
  bottom: ${({ bottom }) => (bottom ? bottom : '')};
  position: ${({ position }) => (position ? position : 'relative')};
  :hover {
    background-color: #bba391 !important;
    color: white !important;
  }
`;

const ImageStyle = styled.img<{ selected: boolean }>`
  width: 1.667vw;
  align-self: center;
  margin-left: 3.333vw;
  background-color: #28293d;
  filter: invert(100%);
  background-color: transparent;
  filter: ${({ selected }) => (selected ? 'brightness(9.5) !important' : 'brightness(0)')};
  ${ButtonStyle}:hover & {
    filter: ${({ selected }) => (selected ? 'brightness(9.5) !important' : 'brightness(0)')};
  }
`;

const Text = styled.h4<{ selected: boolean }>`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  align-self: center;
  line-height: normal;

  margin-left: 1.111vw;
  margin-top: 1.333vh;
  margin-bottom: 1.333vh;
  color: ${({ selected }) => (selected ? 'white !important' : '#28293d')};
  ${ButtonStyle}:hover & {
    color: ${({ selected }) => (selected ? 'white !important' : '#28293d')};
    font-weight: 600;
  }
`;
