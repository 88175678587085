import { Input as InputAntd, InputProps } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import styled from 'styled-components'
import Spacer from './Spacer'

interface Props extends InputProps {
  type: string
  title?: string
  placeholder?: string
  hidetext?: boolean
  defaultValue?: string
  readOnly?: boolean
  onChange?: (e: any) => void
}

export default function Input(props: Props) {
  const getIconRender = (visible) => {
    return props.hidetext ? visible ? <EyeTwoTone /> : <EyeInvisibleOutlined /> : undefined
  }

  if (props.type !== 'password' && props.readOnly) {
    return (
      <ContainerReadOnly>
        <TitleReadOnly>{props.title}</TitleReadOnly>
        <Spacer /><Spacer /><Spacer />
        <InputReadOnly
          style={{ width: '100%' }}
          value={props.defaultValue}
          blur
          readOnly
        />
      </ContainerReadOnly>
    )
  }

  return (
    <Container>
      <Title>{props.title}</Title>
      {props.type === 'password' && (
        <InputCorePsw
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          iconrender={(visible: boolean) => getIconRender(visible)}
          onChange={props.onChange}
          {...props}
        />
      )}
      {props.type !== 'password' && (
        <InputCore
          placeholder={props.placeholder}
          //iconrender={(visible: boolean) => getIconRender(visible)}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          {...props}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 24px;
`
const ContainerReadOnly = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px 24px;
`
const TitleReadOnly = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;
  color: rgba(0, 0, 0, 0.85);
`
const InputReadOnly = styled(InputAntd)`
  height: 4.444vh;
  padding: 0px 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9 !important;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 1vw !important;
  margin-top: -1%;
`

const Title = styled.div`
  position: static;
  left: 0px;
  top: calc(50% - 22px / 2 - 4px);

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;

  color: rgba(0, 0, 0, 0.85);
  text-align: left;
`
const InputCore = styled(InputAntd)`
  display: flex;
  height: 4.444vh;

  align-items: flex-start;
  padding: 0px 24px;
  left: 0px;
  right: 0px;
  top: 15px;
  bottom: 0px;
  background: #ffffff;

  border: 1px solid #d9d9d9 !important;
  box-sizing: border-box;
  border-radius: 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  font-size: 1vw !important;

  &::placeholder {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 500;
  }
`
const InputCorePsw = styled(InputAntd.Password)`
  display: flex;
  height: 4.444vh;

  align-items: flex-start;
  padding: 0px 24px;
  left: 0px;
  right: 0px;
  top: 15px;
  bottom: 0px;

  background: #ffffff;

  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  font-size: 1vw;
  &::placeholder {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 500;
    align-self: center;
    font-size: 1vw;
  }
`
// const MentionsStyled = styled(Mentions)`
//   display: flex;
//   height: 4.444vh;

//   align-items: flex-start;
//   padding: 0px 24px;
//   left: 0px;
//   right: 0px;
//   top: 15px;
//   bottom: 0px;
//   background: #ffffff;

//   border: 1px solid #d9d9d9 !important;
//   box-sizing: border-box;
//   border-radius: 16px;
//   flex: none;
//   order: 1;
//   align-self: stretch;
//   flex-grow: 0;
//   margin: 0px 0px;
//   font-size: 1vw !important;

//   &::placeholder {
//     font-family: Rajdhani;
//     font-style: normal;
//     font-weight: 500;
//   }
// `