import styled from 'styled-components'
import { Radio } from 'antd'
import { CATALOGUE_VISIBILITY, User } from 'types'

import itaIcon from 'assets/iconsUserRow/it.png'
import engIcon from 'assets/iconsUserRow/en.png'
import espIcon from 'assets/iconsUserRow/es.png'
import Spacer from 'components/Spacer'

export default function AddUserPageStep3({ newUser, setNewUser }: { newUser: User; setNewUser: (user: User) => void }) {

  return (
    <>
      <SmallTitle>Tipologia di profilo</SmallTitle>
      <ChoiceGroup
        value={newUser.roles.length > 0 ? newUser.roles[0] : ''}
        onChange={(e) => setNewUser({ ...newUser, roles: [e.target.value] })}
      >
        <ChoiceButton value="BASIC">BASIC USER</ChoiceButton>
        <Spacer />
        <ChoiceButton value="USER">USER</ChoiceButton>
        <Spacer />
        <ChoiceButton value="POWERUSER">ADMIN</ChoiceButton>
      </ChoiceGroup>
      <SmallTitle>Ha limitazioni in catalogo?</SmallTitle>
      <ChoiceGroup
        value={newUser.catalogueVisibility}
        onChange={(e) => setNewUser({ ...newUser, catalogueVisibility: e.target.value })}
      >
        <ChoiceButton value={CATALOGUE_VISIBILITY.NORMAL}>SI</ChoiceButton>
        <Spacer />
        <ChoiceButton value={CATALOGUE_VISIBILITY.EXTENDED}>NO</ChoiceButton>
      </ChoiceGroup>
      <SmallTitle>Scegli la lingua</SmallTitle>
      <ChoiceGroup value={newUser.language} onChange={(e) => setNewUser({ ...newUser, language: e.target.value })}>
        <ChoiceButton value="ITALIAN">
          <img style={{ width: '1vw' }} src={itaIcon} alt="" />
        </ChoiceButton>
        <Spacer />
        <ChoiceButton value="ENGLISH">
          <img style={{ width: '1vw' }} src={engIcon} alt="" />
        </ChoiceButton>
        <Spacer />
        <ChoiceButton value="SPANISH">
          <img style={{ width: '1vw' }} src={espIcon} alt="" />
        </ChoiceButton>
      </ChoiceGroup>
      <Spacer /> <Spacer /> <Spacer />
    </>
  )
}

const SmallTitle = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;

  color: rgba(0, 0, 0, 0.85);

  flex: none;
  order: 0;
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 5px;
  text-align-last: left;
`

const ChoiceGroup = styled(Radio.Group)`
  margin-top: 16px;
  display: flex;
`
const ChoiceButton = styled(Radio.Button)`
  display: flex;
  flex-direction: row;
  padding: 0px 24px;
  background: #ffffff;
  color: #28293d;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 8px;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  align-items: center;
  color: #28293d;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;

  justify-content: center;
  ::hover {
    background: #bba391;
    color: white;
  }
`
