import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import LongLotsComponent from 'components/LongLotsComponent';
import ShortLotsComponent from 'components/ShortLotsComponent';
import Spacer from 'components/Spacer';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from 'translations/i18n';
import { Batch, IndexedLotAvailability, LOT_TYPES } from 'types';

export default function LotAvailabilityDrawer(props: {
  quantityAvailable: number,
  lotAvailability?: IndexedLotAvailability[],
  lotType: LOT_TYPES,
  defaultCheckboxList: Number[],
  totalLotChecked: string,
  totalMetersChecked: string,
  totalBatchesChecked: Batch[],
  handleTotalLotMeters: (newValue: number, lotType: LOT_TYPES) => void,
  handleTotalLotNumber: (newValue: number, lotType: LOT_TYPES) => void,
  handleQtyAvailable: (newValue: number, lotType: LOT_TYPES) => void
}) {

  const [sortBatch, setSortBatch] = useState<number>(0);
  const [sortLot, setSortLot] = useState<number>(0);
  const [sortMeter, setSortMeter] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);
  const [totalLots, setTotalLots] = useState(0);
  const [batches, setBatches] = useState<Batch[]>(props.totalBatchesChecked);
  const [lotCheckboxList, setLotCheckboxList] = useState<Number[]>(props.defaultCheckboxList);

  const [temporaryLotIndex, setTemporaryLotIndex] = useState<number>();
  const [temporaryLotMeters, setTemporaryLotMeters] = useState<string>();

  const showModal = () => { setVisible(true); }

  const closeModal = () => {
    props.handleQtyAvailable(props.quantityAvailable + Number(temporaryLotMeters), props.lotType);
    props.handleTotalLotMeters(Number(parseFloat(props.totalMetersChecked).toFixed(2)) - Number(temporaryLotMeters), props.lotType);
    lotCheckboxList.splice(temporaryLotIndex!, 1);
    setVisible(false);
  }

  const onClickCheckbox = (indexedLot: IndexedLotAvailability, check: boolean) => {
    if (check) {
      lotCheckboxList?.push(indexedLot.index);
      props.handleTotalLotMeters(Number(parseFloat(props.totalMetersChecked).toFixed(2)) + Number(indexedLot.meter), props.lotType);
      props.handleQtyAvailable(props.quantityAvailable! - Number(indexedLot.meter), props.lotType);
      if (Number(props.quantityAvailable) - Number(indexedLot.meter) < 0) {
        setTemporaryLotIndex(lotCheckboxList.indexOf(indexedLot.index));
        setTemporaryLotMeters(indexedLot.meter.toString());
        return showModal();
      }
      props.handleTotalLotNumber(totalLots + 1, props.lotType);
      let batch: Batch = {};
      batch.lotNumber = indexedLot.lot;
      batch.quantityBase = indexedLot.meter.toString();
      batch.support = indexedLot.support;
      if (!(batches.filter((e) => e.lotNumber === indexedLot.lot).length > 0)) {
        batches.push(batch);
      }
    } else {
      let index = lotCheckboxList.indexOf(indexedLot.index);
      lotCheckboxList.splice(index, 1);
      for (let i = 0; i < batches.length; i++) {
        if (batches[i].lotNumber === indexedLot.lot) batches.splice(i, 1);
      }
      props.handleQtyAvailable(props.quantityAvailable + Number(indexedLot.meter), props.lotType);
      props.handleTotalLotMeters(Number(parseFloat(props.totalMetersChecked).toFixed(2)) - Number(indexedLot.meter), props.lotType);
      props.handleTotalLotNumber(totalLots - 1, props.lotType);
    }
  }

  useEffect(() => {
    setTotalLots(Number(props.totalLotChecked));
    setLotCheckboxList(props.defaultCheckboxList);
    setBatches(props.totalBatchesChecked);
    
    if (props.lotAvailability?.length === 0 || !props.lotAvailability) {
      setTotalLots(0);
      setLotCheckboxList([]);
      setBatches([]);
    }
    
  }, [props.lotAvailability, props.defaultCheckboxList, props.totalLotChecked, props.totalMetersChecked, props.totalBatchesChecked, props.quantityAvailable])
  
  return (
    <Drawer>
      <DrawerTitle>{i18n.t('availabilityPage.sub_drawer_1.title')}</DrawerTitle>
      <Spacer /><Spacer />
      {props.lotType.match(LOT_TYPES.LONG) && props.lotAvailability &&
        <LongLotsComponent
          sortBatch={sortBatch}
          sortLot={sortLot}
          sortMeter={sortMeter}
          checkboxList={lotCheckboxList}
          defaultAvailability={(sortBatch === 0 && sortLot === 0 && sortMeter === 0)}
          sortedLotAvailability={props.lotAvailability}
          handleClickCheckbox={onClickCheckbox}
          handleSortBatch={setSortBatch}
          handleSortLot={setSortLot}
          handleSortMeter={setSortMeter}
        />
      }
      {props.lotType.match(LOT_TYPES.SHORT) && props.lotAvailability &&
        <ShortLotsComponent
          sortBatch={sortBatch}
          sortLot={sortLot}
          sortMeter={sortMeter}
          checkboxList={lotCheckboxList}
          defaultAvailability={(sortBatch === 0 && sortLot === 0 && sortMeter === 0)}
          sortedLotAvailability={props.lotAvailability}
          handleClickCheckbox={onClickCheckbox}
          handleSortBatch={setSortBatch}
          handleSortLot={setSortLot}
          handleSortMeter={setSortMeter}
        />
      }
      <Spacer /><Spacer />
      <Summary>
        <SummaryDetails lessThanZero={props.quantityAvailable! < 0 ? true : false}>
          <SummaryDetailLeft>
            {i18n.t('availabilityPage.sub_drawer_1.qty_selectable')}
          </SummaryDetailLeft>
          <SummaryDetailRight>
            {Number(props.quantityAvailable).toFixed(2)}m
          </SummaryDetailRight>
        </SummaryDetails>
        <SummaryDetails>
          <SummaryDetailLeft>
            {i18n.t('availabilityPage.sub_drawer_1.qty_selected')}
          </SummaryDetailLeft>
          <SummaryDetailRight>
            {Number(props.totalMetersChecked) === 0 ? '0.00m' : Number(props.totalMetersChecked).toFixed(2) + 'm'}
          </SummaryDetailRight>
        </SummaryDetails>
        <Spacer />
      </Summary>
      <ModalStyled
        visible={visible}
        closable={false}
        width={350}
        footer={[<ButtonStyle onClick={closeModal} >OK</ButtonStyle>]}
        style={{
          top: 220,
          left: '33.5%'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '3.5%' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CloseCircleOutlined style={{ fontSize: '24px', color: '#e74c3c' }} />
            <p style={{ fontSize: '25px', marginLeft: '8px', lineHeight: '1.2' }}>
              {i18n.t('availabilityPage.sub_drawer_1.modal_title')}
            </p>
          </div>
          <Spacer />
          <p style={{ fontSize: '18px', marginLeft: '33px' }}>
            {i18n.t('availabilityPage.sub_drawer_1.modal_content')}
          </p>
        </div>
      </ModalStyled>
    </Drawer>
  )
}

const Drawer = styled.div`
  width: 100%;
  flex: 1;
`
const DrawerTitle = styled.h3`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  /* Main/Darker */
  color: #774a28;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration-line: underline;
`
const Summary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SummaryDetails = styled.div<{ lessThanZero: boolean }>`
  width: 22.5vw;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  display: flex;
  justify-content: space-between;

  color: ${({ lessThanZero }) =>
    lessThanZero ? '#e74c3c' : '#28293d'};
`
const SummaryDetailLeft = styled.div`
  text-align: left;
`
const SummaryDetailRight = styled.div`
  text-align: right;
`
const ButtonStyle = styled.button<{ invertedColor: boolean }>`
  cursor: pointer;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  padding: 2px 20px;

  background: ${({ invertedColor }) => (invertedColor ? '#bba391' : '#d8d7db')};
  color: ${({ invertedColor }) => (invertedColor ? 'white' : 'black')};
  border-radius: 24px;
  border-style: none;
  :hover {
    background: #bba391;
    color: white;
  }
`
const ModalStyled = styled(Modal)`
  .ant-modal-content{
    border-radius: 24px;
  }
`