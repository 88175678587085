import styled from 'styled-components'
import deleteIcon from 'assets/icons/delete_forever.png'
import deleteGreyIcon from 'assets/icons/delete_grey.png'
import deleteYes from 'assets/icons/delete_yes.png'
import deleteNo from 'assets/icons/delete_no.png'
import { useState } from 'react'
import i18n from 'translations/i18n'
import { CartLine } from 'types'
import Cart from 'api/Cart'
import { showError } from 'utils'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import { reloadApp } from 'utils/router'
import Cookies from 'universal-cookie'
import { Popover } from 'antd'

export default function CartRow(props: { line: CartLine }) {

  const cookies = new Cookies()
  const loggedErpUser = cookies.get('loggedErpUser')
  const [loading, setLoading] = useState(false)
  const [temporaryDeleteOption, setTemporaryDeleteOption] = useState(false)

  const deleteCartLine = async (lineNumber: string) => {
    try {
      setLoading(true)
      const res = await Cart.deleteCartLine(lineNumber)
      if (res.lines.length === 0) {
        await Cart.deleteCart()
        const timer: string = 'timer' + loggedErpUser
        localStorage.setItem(timer, '0')
        const expressShipment = 'expressShipment' + loggedErpUser
        localStorage.setItem(expressShipment, 'false')
        localStorage.removeItem('orderEmail' + loggedErpUser)
        localStorage.removeItem('externalDocNo' + loggedErpUser)
      }
      reloadApp()
      setLoading(false)
    } catch (error) {
      showError('Impossibile eliminare riga del carrello')
      setLoading(false)
    }
  }

  return (
    <>
      {!temporaryDeleteOption &&
        <CartRowContainer>
          <Icon onClick={() => setTemporaryDeleteOption(true)} src={deleteIcon} />
          <RowDetails>
            {props.line.crossreferenceNo !== '' && (
              <ProductDetail>
                <Popover
                  title={<b>Crossrefence</b>}
                  content={props.line.crossreferenceNo}
                  trigger='hover'
                  placement='top'
                >
                  {props.line.description}
                </Popover>
              </ProductDetail>
            )}
            {props.line.crossreferenceNo === '' && <ProductDetail>{props.line.description}</ProductDetail>}
            <BatchesDetail>
              {props.line.lotNumber + i18n.t('cartPage.cart_row') + props.line.quantity + 'm'}
            </BatchesDetail>
            <DeliveryDateDetail>{props.line.shipmentDate}</DeliveryDateDetail>
          </RowDetails>
        </CartRowContainer>
      }
      {temporaryDeleteOption &&
        <CartRowContainer>
          <Icon src={deleteGreyIcon} />
          <RowDetails>
            <DeleteText>{i18n.t('cartPage.cart_delete')}</DeleteText>
            <LoaderContainer>
              {!loading && (
                <>
                  <Icon
                    onClick={() => { if (props.line.lineNumber) deleteCartLine(props.line.lineNumber) }}
                    src={deleteYes}
                  />
                  <Icon onClick={() => setTemporaryDeleteOption(false)} src={deleteNo} />
                </>
              )}
              {loading && (
                <LoadingIcons
                  style={{ width: '24px', marginLeft: '20px', marginTop: 'auto', marginBottom: 'auto', height: '24px' }}
                />
              )}
            </LoaderContainer>
          </RowDetails>
        </CartRowContainer>
      }
    </>
  )
}

const CartRowContainer = styled.div`
  background: #ffffff;
  width: 42vw;
  height: 8.222vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-left: 4px;
  margin-top: 2vh;
  margin-bottom: 12px;
  margin-right: 4px;
  
  :hover {
    box-shadow: 0px 1px 4px rgb(40 41 61 / 4%), 0px 8px 12px rgb(96 97 112 / 16%);
    border-radius: 50px;
  }
`
const RowDetails = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 5%;
`
const DeleteText = styled.h4`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  color: #28293d;
  display: flex;
  align-items: center;
  margin-top: 12px;
`
const ProductDetail = styled.h4`
  cursor: pointer;
  width: 13.5vw;
  font-weight: bold;
  font-size: 1.2vw;
  line-height: 1;
  display: flex;
  align-items: center;
  color: #28293d;
  margin-left: 2%;
  margin-right: 18%;
  margin-bottom: -0.5%;
  padding: 1%;
`
const BatchesDetail = styled.h5`
  background: #ffffff;
  width: 12vw;
  height: 2vh;
  font-weight: 500;
  font-size: 1vw;
  color: #28293d;
  display: flex;
  align-items: center;
  place-content: center;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -18%;
  margin-right: 11%;
  padding: 1vw;
`
const DeliveryDateDetail = styled.div`
  width: 12vw;
  height: 2vh;
  font-size: 1vw;
  display: flex;
  align-items: center;
  place-content: center;
  border: 1px solid #d9d9d900;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: -10%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 1vw;
`
const Icon = styled.img`
  cursor: pointer;
  width: 1.5vw;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
`
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`