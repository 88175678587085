import Cart from "api/Cart";
import ButtonDrawer from "components/ButtonDrawer";
import DatePickerComponent from "components/DatePickerComponent";
import Spacer from "components/Spacer";
import { useCallback, useEffect, useState } from "react";
import styled from 'styled-components'
import i18n from "translations/i18n";
import { Batch, BatchesSelected, BatchToCart, CartLine, CatalogueItem, CheckedLists, LotsMetersSelected, LotsSelected, LOT_TYPES, QuantityAvailabilityPerDate } from "types";
import { AppStore, showError, showInfo } from "utils";
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import { reloadApp } from "utils/router";
import moment from "moment";
import { Checkbox, Modal } from "antd";
import _ from "lodash";
import Catalogue from "api/Catalogue";
import Cookies from "universal-cookie";
import { getBusinessDays, getDefaultDateValue } from "utils/deliveryDateCalculator";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function RecapDrawer({
    objectClicked,
    lotNumbers,
    metersNumber,
    totalMetersToShip,
    batchesSelected,
    bulkAvailability,
    faveSupportCodes,
    onClick
}: {
    objectClicked: CatalogueItem,
    lotNumbers: LotsSelected,
    metersNumber: LotsMetersSelected,
    totalMetersToShip: string,
    batchesSelected: BatchesSelected,
    bulkAvailability: QuantityAvailabilityPerDate[],
    faveSupportCodes: string[]
    onClick?: () => void
}) {

    const cookies = new Cookies()
    const loggedUser = cookies.get('loggedErpUser')
    const [bigLoading, setBigLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const [bulkMetersToOrder, setBulkMetersToOrder] = useState<string>('0')
    const [bulkMetersToday, setBulkMetersToday] = useState<string>('')
    const [futureAvailability, setFutureAvailability] = useState<string>('')
    const [residualToOrder, setResidualToOrder] = useState<string>('')
    const [bulkMetersCovered, setBulkMetersCovered] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<Date>()
    const [leadTime, setLeadTime] = useState<number>(0)

    const [dateDeliveryLots, setDateDeliveryLots] = useState<string>()
    const [dateDeliveryBulkMeters, setDateDeliveryBulkMeters] = useState<string>()
    

    const [batchesToOrder, setBatchesToOrder] = useState<BatchesSelected>({
        longLength: [],
        shortLength: []
    })
    const [lotCheckboxList, setLotCheckboxList] = useState<CheckedLists>({
        longLength: [],
        shortLength: []
    })
    const [lotsNumber, setLotsNumber] = useState<LotsSelected>({
        longLength: '0',
        shortLength: '0'
    })
    const [lotMeters, setLotMeters] = useState<LotsMetersSelected>({
        longLength: '0',
        shortLength: '0'
    })

    const [visible, setVisible] = useState<boolean>(false);

    const showModal = () => { setVisible(true); }

    const closeModal = () => {
        setVisible(false);
    }

    const getTotalMetersToOrder = (): number => {
        return Number(lotMeters.longLength) + Number(lotMeters.shortLength)
    }

    const getTotalLotsToOrder = (): number => {
        return Number(lotsNumber.longLength) + Number(lotsNumber.shortLength)
    }

    const setDefaultDate = useCallback((start?: Date, leadTime?: number) => {
        if (start === undefined && leadTime === undefined) setDateDeliveryLots(String(moment(new Date()).add(getBusinessDays(3), 'days').format('YYYY-MM-DD')))
        if (start && leadTime === undefined) {
            const defaultDate = getDefaultDateValue(start!, 3)
            setDateDeliveryBulkMeters(String(defaultDate.format('YYYY-MM-DD')))
        } else {
            const defaultDate = getDefaultDateValue(start!, leadTime!)
            setDateDeliveryBulkMeters(String(defaultDate.format('YYYY-MM-DD')))
        }
        // eslint-disable-next-line
    }, [])

    const setBatchesRecap = useCallback(() => {
        setBatchesToOrder(_.cloneDeep(batchesSelected))
        for (let i = 0; i < batchesSelected.longLength.length; i++) {
            lotCheckboxList.longLength.push(i)
        }
        for (let j = 0; j < batchesSelected.shortLength.length; j++) {
            lotCheckboxList.shortLength.push(j)
        }
        setLotsNumber(_.cloneDeep(lotNumbers))
        setLotMeters(_.cloneDeep(metersNumber))
        setDefaultDate()
        if ((batchesSelected.longLength.length === 0 && batchesSelected.shortLength.length === 0) || !batchesSelected) {
            setBatchesToOrder({
                longLength: [],
                shortLength: []
            })
            setLotCheckboxList({
                longLength: [],
                shortLength: []
            })
            setLotMeters({
                longLength: '0',
                shortLength: '0'
            })
            setLotsNumber({
                longLength: '0',
                shortLength: '0'
            })
        }
    }, [batchesSelected, lotNumbers, metersNumber, setDefaultDate, lotCheckboxList.longLength, lotCheckboxList.shortLength])

    useEffect(() => {
        if (batchesSelected) setBatchesRecap();
        // eslint-disable-next-line
    }, [])

    const onClickCheckbox = (n, check: boolean, _tmp, lotType: LOT_TYPES) => {
        //Handles long lots selected
        if (lotType.match(LOT_TYPES.LONG)) {
            if (check) {
                lotCheckboxList.longLength.push(_tmp)
                setLotMeters((prevState) => ({
                    ...prevState,
                    longLength: String((Number(lotMeters.longLength) + Number(n.quantityBase)).toFixed(2))
                }))
                setLotsNumber((prevState) => ({
                    ...prevState,
                    longLength: String(Number(lotsNumber.longLength) + 1)
                }))
                var longBatch: Batch = {}
                longBatch.lotNumber = n.lotNumber
                longBatch.quantityBase = n.quantity
                if (!(batchesToOrder.longLength.filter((e) => e.lotNumber === n.lotNumber).length > 0)) {
                    batchesToOrder.longLength.push(longBatch)
                }
            } else {
                var longIndex = lotCheckboxList.longLength.indexOf(_tmp)
                lotCheckboxList.longLength.splice(longIndex, 1)
                for (let i = 0; i < batchesToOrder.longLength.length; i++) {
                    if (batchesToOrder.longLength[i].lotNumber === n.lotNumber) {
                        batchesToOrder.longLength.splice(i, 1)
                    }
                }
                setLotMeters((prevState) => ({
                    ...prevState,
                    longLength: String((Number(lotMeters.longLength) - Number(n.quantityBase)).toFixed(2))
                }))
                setLotsNumber((prevState) => ({
                    ...prevState,
                    longLength: String(Number(lotsNumber.longLength) - 1)
                }))
            }
        }
        //Handles short lots selected
        if (lotType.match(LOT_TYPES.SHORT)) {
            if (check) {
                lotCheckboxList.shortLength.push(_tmp)
                setLotMeters((prevState) => ({
                    ...prevState,
                    shortLength: String((Number(lotMeters.shortLength) + Number(n.quantityBase)).toFixed(2))

                }))
                setLotsNumber((prevState) => ({
                    ...prevState,
                    shortLength: String(Number(lotsNumber.shortLength) + 1)
                }))
                var shortBatch: Batch = {}
                shortBatch.lotNumber = n.lotNumber
                shortBatch.quantityBase = n.quantity
                if (!(batchesToOrder.shortLength.filter((e) => e.lotNumber === n.lotNumber).length > 0)) {
                    batchesToOrder.shortLength.push(shortBatch)
                }
            } else {
                var shortIndex = lotCheckboxList.shortLength.indexOf(_tmp)
                lotCheckboxList.shortLength.splice(shortIndex, 1)
                for (let j = 0; j < batchesToOrder.shortLength.length; j++) {
                    if (batchesToOrder.shortLength[j].lotNumber === n.lotNumber) {
                        batchesToOrder.shortLength.splice(j, 1)
                    }
                }
                setLotMeters((prevState) => ({
                    ...prevState,
                    shortLength: String((Number(lotMeters.shortLength) - Number(n.quantityBase)).toFixed(2))

                }))
                setLotsNumber((prevState) => ({
                    ...prevState,
                    shortLength: String(Number(lotsNumber.shortLength) - 1)
                }))
            }
        }

    }

    const setBulkRecap = useCallback(async () => {
        try {
            setBigLoading(true)
            //const limitedCatalogue = AppStore.loggedUser.catalogueVisibility === 'NORMAL' ? true : false
            const res = await Catalogue.getDeliveryProspect(
                objectClicked?.articleNo,
                bulkAvailability,
                Number(totalMetersToShip),
            )
            setBulkMetersToday(res.immediateAvailability)
            const leadTime = Number(res.leadTimeDays)
            setLeadTime(Number(res.leadTimeDays))
            var lastDate
            if (Number(res.immediateAvailability) < Number(totalMetersToShip) && res.quantityAvailability.length !== 0) {
                lastDate = new Date(res.quantityAvailability[res.quantityAvailability.length - 1].dateAvailable)
                setStartDate(lastDate)
            }

            var futureCoverage = '0'
            res.quantityAvailability.map((p) => {
                return futureCoverage = String(Number(futureCoverage) + Number(p.quantity))
            })
            if (Number(res.immediateAvailability) + Number(futureCoverage) < 0) {
                setBulkMetersCovered(false)
                setFutureAvailability('0')
                setResidualToOrder(totalMetersToShip)
            } else {
                setFutureAvailability(String(Number(futureCoverage)))
                if (Number(totalMetersToShip) - Number(futureCoverage) - Number(res.immediateAvailability) <= 0) {
                    setBulkMetersCovered(true)
                    setResidualToOrder('0')
                    setDefaultDate(lastDate)
                }
                if (Number(totalMetersToShip) - Number(futureCoverage) - Number(res.immediateAvailability) > 0) {
                    setBulkMetersCovered(false)
                    setResidualToOrder(String(Number(totalMetersToShip) - Number(futureCoverage) - Number(res.immediateAvailability)))
                    setDefaultDate(lastDate, leadTime)
                }
            }
            setBigLoading(false)
        } catch (error) { }
    }, [bulkAvailability, objectClicked, totalMetersToShip, setDefaultDate])

    useEffect(() => {
        if (Number(totalMetersToShip) > 0 && totalMetersToShip !== bulkMetersToOrder) {
            setBulkMetersToOrder(totalMetersToShip)
            setBulkRecap()
        }
    }, [objectClicked, totalMetersToShip, bulkMetersToOrder, setBulkRecap])

    useEffect(() => {
        getTimerFromServer()
        // eslint-disable-next-line
    }, [])

    const getTimerFromServer = async () => {
        try {
            const res = await Cart.getCartIfExist()
            const timer: string = 'timer' + cookies.get('loggedErpUser')
            localStorage.setItem(timer, res.summary.expiringDateTime)
        } catch (error) { }
    }

    const getTotalToShip = (lotsMeters: string, bulkMeters: string) => {
        var total = ''
        total += Number(lotsMeters) + Number(bulkMeters)
        return Number(total).toFixed(2)
    }

    const getCartIfExists = async () => {
        closeModal()
        setLoading(true)
        try {
            const res = await Cart.getCartIfExist()
            const timer: string = 'timer' + cookies.get('loggedErpUser')
            localStorage.setItem(timer, res.summary.expiringDateTime)

            //Creo Line Cart
            var _line: CartLine = {
                lotRequestDate: "",
                bulkRequestDate: ""
            }
            _line.code = objectClicked?.articleNo
            //_line.documentNumber
            _line.variantCode = objectClicked?.variantCode
            _line.description = objectClicked?.matched === 'C' ? objectClicked?.crossreferenceNo! : objectClicked?.variantDescription
            _line.quantity = getTotalToShip(getTotalMetersToOrder().toFixed(2), totalMetersToShip)
            _line.lotNumber = getTotalLotsToOrder()
            _line.unitMeasure = 'MT'
            _line.documentType = 'Order'
            _line.lotQuantity = getTotalMetersToOrder()
            _line.lotRequestDate = getTotalLotsToOrder() > 0 ? dateDeliveryLots! : ''
            _line.bulkQuantity = totalMetersToShip
            _line.bulkRequestDate = Number(totalMetersToShip) > 0 ? dateDeliveryBulkMeters! : ''
            let _batches: BatchToCart[] = []
            batchesToOrder.longLength.forEach((b) => {
                let batchToAdd: BatchToCart = {
                    entryNumber: b.entryNumber!,
                    lotNumber: b.lotNumber!,
                    lineNumber: b.lineNumber!,
                    quantityBase: b.quantityBase!
                }
                _batches.push(batchToAdd)
            });
            batchesToOrder.shortLength.forEach((b) => {
                let batchToAdd: BatchToCart = {
                    entryNumber: b.entryNumber!,
                    lotNumber: b.lotNumber!,
                    lineNumber: b.lineNumber!,
                    quantityBase: b.quantityBase!
                }
                _batches.push(batchToAdd)
            });
            _line.batches = _batches
            createCartLine(_line, res.summary.expiringDateTime, false)
        } catch (error) {
            //showError('Carrello non esistente')
            createCart()
        }
    }

    const createCart = async () => {
        try {
            const res = await Cart.createCart()
            if (localStorage.getItem('externalDocNo' + loggedUser) === null) {
                localStorage.setItem('externalDocNo' + loggedUser, '')
            }
            if (localStorage.getItem('orderEmail' + loggedUser) === null) {
                localStorage.setItem('orderEmail' + loggedUser, AppStore.loggedUser.email)
            }
            //Creo Line Cart
            var _line: CartLine = {
                lotRequestDate: "",
                bulkRequestDate: ""
            }
            _line.code = objectClicked?.articleNo
            //_line.documentNumber
            _line.variantCode = objectClicked?.variantCode
            _line.description = objectClicked?.matched === 'C' ? objectClicked?.crossreferenceNo! : objectClicked?.variantDescription
            _line.quantity = getTotalToShip(getTotalMetersToOrder().toFixed(2), totalMetersToShip)
            _line.lotNumber = getTotalLotsToOrder()
            _line.unitMeasure = 'MT'
            _line.documentType = 'Order'
            _line.lotRequestDate = getTotalLotsToOrder() > 0 ? dateDeliveryLots! : ''
            _line.bulkQuantity = totalMetersToShip
            _line.bulkRequestDate = Number(totalMetersToShip) > 0 ? dateDeliveryBulkMeters! : ''
            let _batches: BatchToCart[] = []
            batchesToOrder.longLength.forEach((b) => {
                let batchToAdd: BatchToCart = {
                    entryNumber: b.entryNumber!,
                    lotNumber: b.lotNumber!,
                    lineNumber: b.lineNumber!,
                    quantityBase: b.quantityBase!
                }
                _batches.push(batchToAdd)
            });
            batchesToOrder.shortLength.forEach((b) => {
                let batchToAdd: BatchToCart = {
                    entryNumber: b.entryNumber!,
                    lotNumber: b.lotNumber!,
                    lineNumber: b.lineNumber!,
                    quantityBase: b.quantityBase!
                }
                _batches.push(batchToAdd)
            });
            _line.batches = _batches
            createCartLine(_line, res.summary.expiringDateTime, true)
        } catch (error) {
            showError(i18n.t('availabilityPage.sub_drawer_3.error_cart'))
            setLoading(false)
        }
    }

    const createCartLine = async (line: CartLine, timer: string, reload: boolean) => {
        try {
            await Cart.createCartLine(line)
            const timerStorage: string = 'timer' + cookies.get('loggedErpUser')
            localStorage.setItem(timerStorage, timer)
            showInfo(i18n.t('availabilityPage.sub_drawer_3.info_add_to_cart'))
            onClick!()
            //if (reload) reloadApp()
            reloadApp()
        } catch (error) {
            showError(i18n.t('availabilityPage.sub_drawer_3.error_add_to_cart'))
        }
        setLoading(false)
    }

    const checkSupportCode = (supportCode: string): string => {
        if (!faveSupportCodes.includes(supportCode)) return " (*)";
        return "";
    }

    return (
        <Drawer>
            {!bigLoading &&
                <>
                    <Scrollbar>
                        {/* Lot batches part*/}
                        {batchesSelected.longLength.length !== 0 &&
                            <>
                                <Title>{i18n.t('availabilityPage.sub_drawer_3.title_1')}</Title>
                                <Spacer /><Spacer /><Spacer />
                                <DetailsContainer1>
                                    <TitleLeftColumn style={{ marginLeft: '14.5%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_1.subtitle_2')}
                                    </TitleLeftColumn>
                                    <TitleRightColumn style={{ marginRight: '18%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_1.subtitle_3')}
                                    </TitleRightColumn>
                                </DetailsContainer1>
                                <Spacer /><Spacer /><Spacer />
                                {batchesSelected &&
                                    batchesSelected.longLength.map((n, index) => (
                                        <div key={index}>
                                            <DetailsContainer1>
                                                <RowLeftDetail>
                                                    <HeaderCheckbox
                                                        checked={lotCheckboxList.longLength.includes(index)}
                                                        onChange={(e) => onClickCheckbox(n, e.target.checked, index, LOT_TYPES.LONG)}
                                                        placeholder="default size"
                                                    />
                                                    <Spacer />
                                                    {n.lotNumber} {checkSupportCode(n.support!) ? <Disclaimer><ExclamationCircleOutlined style={{color: '#ab7f47'}}/></Disclaimer> : undefined}
                                                </RowLeftDetail>
                                                <RowRightDetail>{Number(n.quantityBase!).toFixed(2)}m</RowRightDetail>
                                            </DetailsContainer1>
                                            <Spacer />
                                            <Spacer />
                                        </div>
                                    ))
                                }
                                <hr style={{ width: '85%' }} />
                                <DetailsContainer1>
                                    <TotalDetail style={{ marginLeft: '15.5%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_3.label_total')}
                                    </TotalDetail>
                                    <TotalDetail style={{ marginRight: '18%' }}>
                                        {lotMeters.longLength + 'm'}
                                    </TotalDetail>
                                </DetailsContainer1>
                                {/* <Spacer /><Spacer /><Spacer />
                                <DetailsContainer2>
                                    <RowDetail>
                                        <DeliveryDateDetail>
                                            {i18n.t('availabilityPage.sub_drawer_3.label_delivery')}
                                        </DeliveryDateDetail>
                                    </RowDetail>
                                    <RowDetail>
                                        <DatePickerComponent
                                            onChange={
                                                (value: moment.Moment) => {
                                                    setDateDeliveryLots(String(value.format("YYYY-MM-DD")))
                                                }}
                                        />
                                    </ RowDetail>
                                </DetailsContainer2>
                                <Spacer /> */}
                            </>
                        }

                        {/* Lot short batches part*/}
                        {batchesSelected.shortLength.length !== 0 &&
                            <>
                                <Spacer /><Spacer /><Spacer />
                                <Title>{i18n.t('availabilityPage.sub_drawer_3.title_short_lots')}</Title>
                                <Spacer /><Spacer /><Spacer />
                                <DetailsContainer1>
                                    <TitleLeftColumn style={{ marginLeft: '14.5%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_1.subtitle_2')}
                                    </TitleLeftColumn>
                                    <TitleRightColumn style={{ marginRight: '18%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_1.subtitle_3')}
                                    </TitleRightColumn>
                                </DetailsContainer1>
                                <Spacer /><Spacer /><Spacer />
                                {batchesSelected &&
                                    batchesSelected.shortLength.map((n, index) => (
                                        <div key={index}>
                                            <DetailsContainer1>
                                                <RowLeftDetail>
                                                    <HeaderCheckbox
                                                        checked={lotCheckboxList.shortLength.includes(index)}
                                                        onChange={(e) => onClickCheckbox(n, e.target.checked, index, LOT_TYPES.SHORT)}
                                                        placeholder="default size"
                                                    />
                                                    <Spacer />
                                                    {n.lotNumber} {checkSupportCode(n.support!) ? <Disclaimer><ExclamationCircleOutlined style={{color: '#ab7f47'}}/></Disclaimer> : undefined}
                                                </RowLeftDetail>
                                                <RowRightDetail>{Number(n.quantityBase!).toFixed(2)}m</RowRightDetail>
                                            </DetailsContainer1>
                                            <Spacer />
                                            <Spacer />
                                        </div>
                                    ))
                                }
                                <hr style={{ width: '85%' }} />
                                <DetailsContainer1>
                                    <TotalDetail style={{ marginLeft: '15.5%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_3.label_total')}
                                    </TotalDetail>
                                    <TotalDetail style={{ marginRight: '18%' }}>
                                        {lotMeters.shortLength + 'm'}
                                    </TotalDetail>
                                </DetailsContainer1>
                                {/* <Spacer /><Spacer /><Spacer />
                                <DetailsContainer2>
                                    <RowDetail>
                                        <DeliveryDateDetail>
                                            {i18n.t('availabilityPage.sub_drawer_3.label_delivery')}
                                        </DeliveryDateDetail>
                                    </RowDetail>
                                    <RowDetail>
                                        <DatePickerComponent
                                            onChange={
                                                (value: moment.Moment) => {
                                                    setDateDeliveryLots(String(value.format("YYYY-MM-DD")))
                                                }}
                                        />
                                    </ RowDetail>
                                </DetailsContainer2>
                                <Spacer /> */}
                            </>
                        }

                        {(batchesSelected.longLength.length !== 0 || batchesSelected.shortLength.length !== 0) &&
                            (
                                <>
                                    
                                    <Spacer /><Spacer /><Spacer />
                                    <DetailsContainer2>
                                        <RowDetail>
                                            <DeliveryDateDetail>
                                                {i18n.t('availabilityPage.sub_drawer_3.label_delivery')}
                                            </DeliveryDateDetail>
                                        </RowDetail>
                                        <RowDetail>
                                            <DatePickerComponent
                                                onChange={
                                                    (value: moment.Moment) => {
                                                        setDateDeliveryLots(String(value.format("YYYY-MM-DD")))
                                                    }}
                                            />
                                        </ RowDetail>
                                    </DetailsContainer2>
                                    <Spacer /><Spacer /><Spacer />
                                    {((batchesSelected.longLength.find((el) => !faveSupportCodes.includes(el.support!))) || (batchesSelected.shortLength.find((el) => !faveSupportCodes.includes(el.support!)))) &&
                                        <Disclaimer>
                                            <ExclamationCircleOutlined style={{color: '#ab7f47', marginBottom: '1%', marginRight: '1%'}}/>
                                            {i18n.t('availabilityPage.sub_drawer_3.support_disclaimer')}
                                        </Disclaimer>
                                    }
                                    <Spacer />
                                </>
                            )
                        }

                        {(batchesSelected.longLength.length !== 0 || batchesSelected.shortLength.length !== 0) && totalMetersToShip !== '0' && (<hr />)}

                        {/* Bulk meters part */}
                        {totalMetersToShip !== '0' &&
                            <>
                                <Spacer /><Spacer /><Spacer />
                                <Title>{i18n.t('availabilityPage.sub_drawer_3.title_2')}</Title>
                                <Spacer /><Spacer /><Spacer />
                                <DetailsContainer1>
                                    <TitleLeftColumn style={{ marginLeft: '14.5%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_3.label_status')}
                                    </TitleLeftColumn>
                                    <TitleRightColumn style={{ marginRight: '18%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_2.subtitle_2')}
                                    </TitleRightColumn>
                                </DetailsContainer1>
                                <Spacer /><Spacer />

                                {Number(bulkMetersToday) > 0 && (
                                    <DetailsContainer1>
                                        <RowDetail style={{ marginLeft: '15%' }}>
                                            {i18n.t('availabilityPage.sub_drawer_3.status_immediateAvailability')}
                                        </RowDetail>
                                        {Number(bulkMetersToday) >= Number(totalMetersToShip) && (
                                            <RowDetail style={{ marginRight: '18%' }}>
                                                {Number(totalMetersToShip).toFixed(2)}m
                                            </RowDetail>
                                        )}
                                        {Number(bulkMetersToday) < Number(totalMetersToShip) && (
                                            <RowDetail style={{ marginRight: '18%' }}>
                                                {Number(bulkMetersToday).toFixed(2)}m
                                            </RowDetail>
                                        )}
                                    </DetailsContainer1>
                                )}

                                {Number(futureAvailability) > 0 && (
                                    <DetailsContainer1>
                                        <RowDetail style={{ marginLeft: '15%' }}>
                                            {i18n.t('availabilityPage.sub_drawer_3.status_futureAvailability')}
                                        </RowDetail>
                                        <RowDetail style={{ marginRight: '18%' }}>
                                            {Number(futureAvailability).toFixed(2)}m
                                        </RowDetail>
                                    </DetailsContainer1>
                                )}

                                {Number(residualToOrder) > 0 && (
                                    <DetailsContainer1>
                                        <RowDetail style={{ marginLeft: '15%', color: '#de1f1f' }}>
                                            {i18n.t('availabilityPage.sub_drawer_3.status_remainings')}
                                        </RowDetail>
                                        <RowDetail style={{ marginRight: '18%', color: '#de1f1f' }}>
                                            {Number(residualToOrder).toFixed(2)}m
                                        </RowDetail>
                                    </DetailsContainer1>
                                )}

                                <hr style={{ width: '85%' }} />

                                <DetailsContainer1>
                                    <TotalDetail style={{ marginLeft: '15.5%' }}>
                                        {i18n.t('availabilityPage.sub_drawer_3.label_total')}
                                    </TotalDetail>
                                    <TotalDetail style={{ marginRight: '18%' }}>
                                        {Number(totalMetersToShip).toFixed(2)}m
                                    </TotalDetail>
                                </DetailsContainer1>
                                <Spacer /><Spacer /><Spacer />
                                <DetailsContainer2>
                                    <RowDetail>
                                        <DeliveryDateDetail>
                                            {i18n.t('availabilityPage.sub_drawer_3.label_delivery')}
                                        </DeliveryDateDetail>
                                        <Spacer />
                                        {/* <Tooltip  //Se leadtime è !==0
                                        placement="top"
                                        title={i18n.t('availabilityPage.sub_drawer_3.tooltip_delivery_info')}
                                        color="#bba391"
                                    >
                                        <InfoCircleOutlined style={{ fontSize: '13px', color: '#774a28', marginBottom: '10%' }} />
                                    </Tooltip> */}
                                    </RowDetail>
                                    <RowDetail style={{ marginBottom: '2%' }}>
                                        <DatePickerComponent
                                            onChange={
                                                (value: moment.Moment) => {
                                                    setDateDeliveryBulkMeters(String(value.format("YYYY-MM-DD")))
                                                }}
                                            leadTime={leadTime}
                                            startDate={startDate}
                                            bulkMetersCovered={bulkMetersCovered}
                                        />
                                    </ RowDetail>
                                </DetailsContainer2>
                                <Spacer /><Spacer /><Spacer />
                            </>
                        }
                        
                    </Scrollbar>

                    {!loading &&
                        <>
                            <Spacer /><Spacer />
                            <ButtonDrawer
                                text={
                                    i18n.t('availabilityPage.sub_drawer_3.button_add_to_cart') + ' ' +
                                    getTotalToShip(getTotalMetersToOrder().toFixed(2), totalMetersToShip) +
                                    'm'
                                }
                                onClick={() => {
                                    if (totalMetersToShip === '0' && getTotalLotsToOrder() === 0) {
                                        showError(i18n.t('availabilityPage.sub_drawer_3.error_nothing_to_add'))
                                    }
                                    if (batchesToOrder.longLength.find((el) => !faveSupportCodes.includes(el.support!))) return showModal();
                                    getCartIfExists()
                                }}
                                style={{ margin: 'auto' }}
                                disabled={false}
                            />
                            <ModalStyled
                                visible={visible}
                                closable={false}
                                width={350}
                                footer={[<ModalFooter><ButtonStyle onClick={closeModal} >{i18n.t('availabilityPage.sub_drawer_3.modal_cancel_button')}</ButtonStyle><ButtonStyle onClick={getCartIfExists} >OK</ButtonStyle></ModalFooter>]}
                                //footer={[<ButtonStyle onClick={closeModal} >OK</ButtonStyle>]}
                                style={{
                                top: 220,
                                left: '37.5%'
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '3.5%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#ab7f47' }} />
                                    <p style={{ fontSize: '25px', marginLeft: '8px', lineHeight: '1.2' }}>
                                    {i18n.t('availabilityPage.sub_drawer_1.modal_title')}
                                    </p>
                                </div>
                                <Spacer />
                                <p style={{ fontSize: '18px', marginLeft: '33px' }}>
                                    {i18n.t('availabilityPage.sub_drawer_3.modal_content')}
                                </p>
                                </div>
                            </ModalStyled>
                        </>
                    }
                    {loading &&
                        <div style={{ width: '100%', height: '100%', textAlign: 'center', marginTop: '-3%' }}>
                            <LoadingIcons style={{ width: '52px', height: '52px', marginTop: '6%' }} />
                        </div>
                    }
                </>
            }
            {bigLoading && <LoadingIcons></LoadingIcons>}
            
        </Drawer>
    )
}

const Drawer = styled.div`
    width: 100%;
    flex: 1;
`
const Scrollbar = styled.div`
    width: 23.9vw;
    height: 84vh; 
    overflow-y: scroll; 
    border-bottom: 1px solid #774a28;
`
const DetailsContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const DetailsContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
const Title = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2vw;
  margin-right: 20px;
  margin-left: 10px;
  text-decoration-line: underline;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #774a28;
`
const TitleLeftColumn = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2vw;
  display: flex;
  justify-content: flex-start;
  margin-left: 20%;
  color: #a1a2aa;
`
const TitleRightColumn = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2vw;
  display: flex;
  justify-content: flex-end;
  margin-right: 20%;
  color: #28293d;
`
const RowDetail = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #28293d;
`
const RowLeftDetail = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15%;
  color: #28293d;
`
const RowRightDetail = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 18%;
  color: #28293d;
`
const DeliveryDateDetail = styled.h3`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #774a28;
`
const TotalDetail = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  /* identical to box height, or 157% */
  justify-content: center;
  display: flex;
  align-items: center;

  color: #28293d;
`
const HeaderCheckbox = styled(Checkbox)`
  align-self: center;
`
const Disclaimer = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 0.85vw;
  /* identical to box height, or 157% */
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  margin-left: 3%;
`

const ModalStyled = styled(Modal)`
  .ant-modal-content{
    border-radius: 24px;
  }
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 5%;  
`

const ButtonStyle = styled.button<{ invertedColor: boolean }>`
  cursor: pointer;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  padding: 2px 20px;

  background: ${({ invertedColor }) => (invertedColor ? '#bba391' : '#d8d7db')};
  color: ${({ invertedColor }) => (invertedColor ? 'white' : 'black')};
  border-radius: 24px;
  border-style: none;
  :hover {
    background: #bba391;
    color: white;
  }
`