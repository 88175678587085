import {
    carrello,
    carrelloFull,
    dashboard,
    disponibilita,
    documentale,
    storico,
    admin
} from 'assets/icons';

export const MenuLinkDomain = new Map([
    ["menu.home", {"path": "/home", "position":1,"label": "sidebar.menu.home", "icon": dashboard}],
    ["menu.dashboard", {"path": "/dashboard", "position":1, "label": "sidebar.menu.dashboard", "icon": dashboard}],
    ["menu.availability", {"path":"/disponibilita-web", "position":2, "label":"sidebar.menu.availability", "icon": disponibilita}],
    ["menu.cart", {"path": "/carrello", "position":3, "label": "sidebar.menu.cart", "icon": carrello, "icon2": carrelloFull}],
    ["menu.order_history", {"path": "/storico", "position":4, "label": "sidebar.menu.order_history", "icon": storico}],
    ["menu.documentary", {"path": "/documentale", "position":5, "label": "sidebar.menu.documentary", "icon": documentale}],
    ["menu.logistic_documents", {"path": "/documenti-logistici", "position":6, "label": "sidebar.menu.logistic_documents", "icon": documentale}],
    ["menu.admin", {"path": "/admin", "position":8, "label": "sidebar.menu.admin", "icon": admin}]
]);

export enum PERMISSIONS {
    MENU_HOME = "menu.home",
    MENU_DASHBOARD = "menu.dashboard",
    MENU_AVAILABILITY = "menu.availability",
    MENU_CART = "menu.cart",
    MENU_ORDER_HISTORY = "menu.order_history",
    MENU_DOCUMENTARY = "menu.documentary",
    MENU_LOGISTIC_DOCUMENTS = "menu.logistic_documents",
    MENU_ADMIN = "menu.admin"
};