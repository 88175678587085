import styled from 'styled-components'

import { CatalogueItem } from 'types'
import { Typography } from 'antd'

export default function ProductRow(props: { element: CatalogueItem; onClick?: () => void }) {
  return (
    <>
      <Container onClick={props.onClick}>
        <LeftText goldcolor={props.element.matched === 'C' ? true : false}>
          {props.element.matched === 'C' ? props.element.crossreferenceNo : props.element.variantDescription}
        </LeftText>
        <RightText goldcolor={props.element.matched === 'C' ? true : false}>
          {props.element.articleNo + '/' + props.element.variantCode}
        </RightText>
      </Container>
    </>
  )
}

const Container = styled.div`
  cursor: pointer;
  background: transparent;
  width: 36.736vw;
  margin: auto;
  padding: 1vw !important;
  margin-top: 1.3vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  :hover {
    box-shadow: 0px 1px 4px rgb(40 41 61 / 4%), 0px 8px 12px rgb(96 97 112 / 16%);
    border-radius: 50px;
  }
`
const LeftText = styled(Typography) <{ goldcolor: boolean }>`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2vw;
  align-self: center;
  margin-left: 2vw;

  align-items: center;

  color: ${({ goldcolor }) => (goldcolor ? '#28293d' : '#774A28')};
`
const RightText = styled(Typography) <{ goldcolor: boolean }>`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  align-self: center;
  align-items: center;
  margin-right: 2vw;
  color: ${({ goldcolor }) => (goldcolor ? '#28293d' : '#774A28')};
`
