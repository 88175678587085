import { LoginResponse, NewPasswordResponse, User } from 'types'
import { api, responseErrorCheck } from './api'

export default class Auth {
  //Login
  static login(username: string, password: string) {
    return api
      .post<LoginResponse>('auth/login', { username, password })
      .then((res) => responseErrorCheck(res))
  }

  //Send new password request
  static newPassword(
    name: string,
    surname: string,
    businessName: string,
    email: string,
    language: string) {
    return api
      .post<NewPasswordResponse>('auth/newpassword', { name, surname, businessName, email, language })
      .then((res) => responseErrorCheck(res))
  }

  //Send mail to Vay support
  static contactSupport(
    name: string,
    surname: string,
    businessName: string,
    email: string,
    subject: string,
    message: string,
    language: string) {
    return api
      .post<string>('auth/contactSupport', { name, surname, businessName, email, subject, message, language })
      .then((res) => responseErrorCheck(res))
  }

  //Get user's profile
  static loggedUser() {
    return api
      .get<User>('auth/profile', {})
      .then((res) => responseErrorCheck(res))
  }
}
