import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { Spacer, CartRow, EmptyRow } from 'components'
import { CartDrawer } from 'components/Drawer'
import Cart from 'api/Cart'
import { AppStore, showError } from 'utils'
import i18n from 'translations/i18n'
import { CartResponse, Header, LANGUAGES, } from 'types'
import { ReactComponent as LoadingIcons } from 'assets/loading.svg'
import Cookies from 'universal-cookie'
import { reloadApp } from 'utils/router'
import { Modal, Tooltip } from 'antd'
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

export default function ShoppingCartPage() {

  const cookies = new Cookies()
  const loggedErpUser = cookies.get('loggedErpUser')
  const [loading, setLoading] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)
  const [cartLines, setCartLines] = useState<CartResponse>()
  const [visible, setVisible] = useState<boolean>(false)

  const changeExpressShipmentValue = (checked: boolean) => {
    const expressShipment = 'expressShipment' + loggedErpUser
    localStorage.setItem(expressShipment, checked ? 'true' : 'false')
  }
  const changeExternalDocNoValue = (externalDocNo: string) => {
    const expressShipment = 'externalDocNo' + loggedErpUser
    localStorage.setItem(expressShipment, externalDocNo)
  }
  const changeOrderEmailValue = (orderEmail: string) => {
    const expressShipment = 'orderEmail' + loggedErpUser
    localStorage.setItem(expressShipment, orderEmail)
  }

  const onClose = () => {
    const expressShipment = 'expressShipment' + loggedErpUser
    const shipmentValue = localStorage.getItem(expressShipment) === 'true' ? true : false
    const externalDocumentNo = localStorage.getItem('externalDocNo' + loggedErpUser) !== null ? localStorage.getItem('externalDocNo' + loggedErpUser) : ''
    const email = localStorage.getItem('orderEmail' + loggedErpUser) !== null && localStorage.getItem('orderEmail' + loggedErpUser) !== '' ? localStorage.getItem('orderEmail' + loggedErpUser) : AppStore.loggedUser.email
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (shipmentValue || externalDocumentNo !== '' || email !== '') {
      if (email !== '' && !re.test(email!)) {
        showError(i18n.t('cartPage.error_email_format'))
        return
      }
      modifyOrderHeader(shipmentValue, externalDocumentNo!, email!)
    }
    confirmOrder(externalDocumentNo!, email!)
  }

  const getCartIfExists = async () => {
    try {
      setLoading(true)
      const res = await Cart.getCartIfExist()
      setCartLines(res)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const modifyOrderHeader = async (shipmentValue: boolean, externalDocumentNo: string, email: string) => {
    var newHeader: Header = {
      erpUser: loggedErpUser,
      expressShipmentAgent: shipmentValue,
      externalDocumentNumber: externalDocumentNo,
      email: email,
    }
    try {
      await Cart.modifyOrderHeader(newHeader)
    } catch (error) { }
  }

  const showConfirm = () => {
    setVisible(true)
    let secondsToGo = 8

    const timer = setInterval(() => {
      secondsToGo -= 1
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setVisible(false)
      reloadApp()
    }, secondsToGo * 1000);
  }

  const getUserLanguage = (): string => {
    const language = AppStore.getLanguageValue()
    switch (language) {
      case 'en':
        return LANGUAGES.ENGLISH
      case 'es':
        return LANGUAGES.SPANISH
      case 'it':
      default:
        return LANGUAGES.ITALIAN
    }
  }

  const confirmOrder = async (externalDocumentNo: string, email: string) => {
    try {
      setLoadingConfirm(true)
      await Cart.confirmOrder(externalDocumentNo, email, getUserLanguage())
      const timer: string = 'timer' + loggedErpUser
      localStorage.setItem(timer, '0')
      const expressShipment = 'expressShipment' + loggedErpUser
      localStorage.setItem(expressShipment, 'false')
      localStorage.removeItem('orderEmail' + loggedErpUser)
      localStorage.removeItem('externalDocNo' + loggedErpUser)
      setLoadingConfirm(false)
      showConfirm()
    } catch (error) {
      showError(i18n.t('cartPage.error_confirm'))
    }
  }

  useEffect(() => {
    getCartIfExists()
  }, [])

  return (
    <CartPage>
      <Container>
        <PageTitle style={{ marginLeft: '2vw' }}>{i18n.t('cartPage.title')}</PageTitle>
        <Spacer /> <Spacer /> <Spacer />
        <HeaderContainer>
          <div style={{ width: '3vw' }}></div>
          <HeaderTitle style={{ width: '15vw' }}>{i18n.t('cartPage.subtitle1')}</HeaderTitle>
          <HeaderTitle style={{ width: '12vw' }}>{i18n.t('cartPage.subtitle2')}</HeaderTitle>
          <HeaderTitle
            style={{ width: '12vw' }}
          >
            {i18n.t('cartPage.subtitle3')}
            <Tooltip
              placement="bottom"
              title={i18n.t('availabilityPage.sub_drawer_3.tooltip_delivery_info')}
              color="#bba391"
            >
              <InfoCircleOutlined
                style={{
                  fontSize: '1vw',
                  marginTop: '5%',
                  marginLeft: '3%',
                  color: '#00000082'
                }}
              />
            </Tooltip>
          </HeaderTitle>
        </HeaderContainer>
        <Scrollbar>
          {!loading && cartLines?.lines.map((n, index) => { return <CartRow key={index} line={n} /> })}
          {!loading && (cartLines?.lines.length === 0 || cartLines === undefined) && (
            <EmptyRow text={i18n.t('cartPage.cart_empty')} />
          )}
          {loading && <LoadingIcons />}
        </Scrollbar>
      </Container >
      {!loading && !loadingConfirm && cartLines?.lines.length !== 0 && cartLines !== undefined && (
        <CartDrawer
          objectClicked={cartLines}
          onClick={onClose}
          changeExpressShipment={changeExpressShipmentValue}
          changeExternalDocumentNo={changeExternalDocNoValue}
          changeOrderEmail={changeOrderEmailValue}
        />
      )}
      {!loading && loadingConfirm && <LoadingIcons />}
      <ModalStyled
        visible={visible}
        closable={false}
        width={400}
        footer={[<ButtonStyle onClick={() => setVisible(false)}>OK</ButtonStyle>]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '3.5%' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CheckCircleOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
            <p style={{ fontSize: '25px', marginLeft: '8px', lineHeight: '1.2' }}>
              {i18n.t('cartPage.modal_title')}
            </p>
          </div>
          <Spacer />
          <p style={{ fontSize: '18px', marginLeft: '33px' }}>
            {i18n.t('cartPage.modal_content')}
          </p>
        </div>
      </ModalStyled>
    </CartPage>
  )
}
const CartPage = styled.div`
  height: 100vh;
  display: flex; 
  flex-direction: row; 
`
const Container = styled.div`
  background-color: white;
  margin-left: 5vw;
  margin-top: 8vh;
`
const PageTitle = styled.h3`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 56px;
  marginLeft: 2vw;
`
const HeaderContainer = styled.div`
  width: 42vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2vw;
`
const HeaderTitle = styled.h3`
  white-space: nowrap;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw;
  color: #000000;
  display: flex;
`
const Scrollbar = styled.div`
  height: 60vh; 
  width: 45vw;
  overflow-y: scroll; 
`
const ModalStyled = styled(Modal)`
  .ant-modal-content{
    border-radius: 24px;
  }
`
const ButtonStyle = styled.button<{ invertedColor: boolean }>`
  cursor: pointer;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  padding: 2px 20px;

  background: ${({ invertedColor }) => (invertedColor ? '#bba391' : '#d8d7db')};
  color: ${({ invertedColor }) => (invertedColor ? 'white' : 'black')};
  border-radius: 24px;
  border-style: none;
  :hover {
    background: #bba391;
    color: white;
  }
`
