import styled from 'styled-components';
import { Checkbox } from 'antd';
import { IndexedLotAvailability } from 'types';
import Spacer from './Spacer';
import { useEffect, useState } from 'react';
import i18n from 'translations/i18n';
import SortButton from './SortButton';

export default function ShortLotsComponent(props: {
  sortBatch: number
  sortLot: number
  sortMeter: number
  checkboxList: Number[]
  defaultAvailability: boolean
  sortedLotAvailability: IndexedLotAvailability[]
  handleClickCheckbox: (indexedLot: IndexedLotAvailability, check: boolean) => void
  handleSortBatch: React.Dispatch<React.SetStateAction<number>>
  handleSortLot: React.Dispatch<React.SetStateAction<number>>
  handleSortMeter: React.Dispatch<React.SetStateAction<number>>
}) {

  useEffect(() => {
    setLotAvailability(props.sortedLotAvailability);
    return (() => {
      setLotAvailability(undefined);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  enum KEY {
    BATCH = 'batch',
    LOT = 'lot',
    METER = 'meter'
  };

  enum SORT {
    DEFAULT = 0,
    ASC = 1,
    DESC = 2
  };

  const [lotAvailability, setLotAvailability] = useState<IndexedLotAvailability[] | undefined>(props.sortedLotAvailability);

  const changeStatus = (newStatus: number, sortingKey: string): void => {
    switch (sortingKey) {
      case KEY.BATCH:
        props.handleSortBatch(newStatus);
        props.handleSortLot(0);
        props.handleSortMeter(0);
        break;
      case KEY.LOT:
        props.handleSortBatch(0);
        props.handleSortLot(newStatus);
        props.handleSortMeter(0);
        break;
      case KEY.METER:
        props.handleSortBatch(0);
        props.handleSortLot(0);
        props.handleSortMeter(newStatus);
    }
    sortArray(newStatus, sortingKey);
  };

  const sortArray = (newStatus: number, sortingKey: string) => {
    switch (newStatus) {
      case SORT.ASC:
        setLotAvailability(sortByKeyASC(lotAvailability!, sortingKey));
        break;
      case SORT.DESC:
        setLotAvailability(sortByKeyDESC(lotAvailability!, sortingKey));
        break;
      case SORT.DEFAULT:
        setLotAvailability(props.sortedLotAvailability);
    };
  };

  const sortByKeyASC = (indexedLots: IndexedLotAvailability[], sortingKey: string): IndexedLotAvailability[] => {
    return indexedLots.sort(function (a, b) {
      const x = a[sortingKey];
      const y = b[sortingKey];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  };

  const sortByKeyDESC = (indexedLots: IndexedLotAvailability[], sortingKey: string): IndexedLotAvailability[] => {
    return indexedLots.sort(function (a, b) {
      const x = a[sortingKey];
      const y = b[sortingKey];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  };

  return (
    <>
      <HeadersContainer>
        <TitleFirstRow>{i18n.t('availabilityPage.sub_drawer_1.subtitle_1')}
          <SpaceDetail />
          <SortButton sortState={props.sortBatch} sortingKey={KEY.BATCH} onChangeSortState={changeStatus} />
        </TitleFirstRow>
        <TitleSecondRow>
          {i18n.t('availabilityPage.sub_drawer_1.subtitle_2')}
          <SpaceDetail />
          <SortButton sortState={props.sortLot} sortingKey={KEY.LOT} onChangeSortState={changeStatus} />
        </TitleSecondRow>
        <TitleThirdRow>{i18n.t('availabilityPage.sub_drawer_1.subtitle_3')}
          <SpaceDetail />
          <SortButton sortState={props.sortMeter} sortingKey={KEY.METER} onChangeSortState={changeStatus} />
        </TitleThirdRow>
      </HeadersContainer>

      <Spacer /><Spacer /><Spacer /><Spacer />
      <Scrollbar>
        {props.sortedLotAvailability && !props.defaultAvailability &&
          lotAvailability?.map((indexedLot, index) => (
            <div key={index} style={{ display: 'inline-flex', lineHeight: '2' }}>
              <HeaderCheckbox
                checked={props.checkboxList.includes(indexedLot.index)}
                onChange={(e) => props.handleClickCheckbox(indexedLot, e.target.checked)}
                placeholder="default size"
              />
              <RowDetails>
                <SpaceDetail />
                {/* <Tooltip
                      placement="topLeft"
                      title={n.batch ? n.batch : '------'}
                      overlayInnerStyle={{ fontSize: '1vw' }}
                      > */}
                <BatchDetail>{indexedLot.batch ? indexedLot.batch : '------'}</BatchDetail>
                {/* </Tooltip> */}
                <LotDetail>{indexedLot.lot}</LotDetail>
                <MetersDetail>{Number(indexedLot.meter).toFixed(2)}m</MetersDetail>
              </RowDetails>
              <Spacer />
              <Spacer />
            </div>
          ))
        }
        {props.defaultAvailability &&
          lotAvailability?.map((indexedLot, index) => (
            <div key={index} style={{ display: 'inline-flex', lineHeight: '2' }}>
              <HeaderCheckbox
                checked={props.checkboxList.includes(indexedLot.index)}
                onChange={(e) => props.handleClickCheckbox(indexedLot, e.target.checked)}
                placeholder="default size"
              />
              <RowDetails>
                <SpaceDetail />
                <BatchDetail>{indexedLot.batch ? indexedLot.batch : '------'}</BatchDetail>
                <LotDetail>{indexedLot.lot}</LotDetail>
                <MetersDetail>{Number(indexedLot.meter).toFixed(2)}m</MetersDetail>
              </RowDetails>
              <Spacer />
              <Spacer />
            </div>
          ))
        }
      </Scrollbar>
    </>
  );
};

const HeadersContainer = styled.div`
  width: 30.5vw;
  height: 2vh;
  margin-left: 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1vw;
`
const TitleFirstRow = styled.div`
  width: 11.5vw;
  /* Sans/Body/Semibold */
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw;
  /* Main/Darker */
  color: #774a28;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  line-height: 1;
`
const TitleSecondRow = styled.div`
  width: 11.5vw;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw;
  /* Neutral/Grey */
  color: #a1a2aa;
  display: flex;
  justify-content: center;
  //margin-right: 10px;
  //margin-left: 10px;
  text-align: center;
  line-height: 1;
`
const TitleThirdRow = styled.div`
  width: 6.5vw;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  /* Neutral/Black */
  color: #28293d;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  line-height: 1;
`

const Scrollbar = styled.div`
  overflow-y: scroll;
  height: 52vh;
  width: 34vw;
  border-bottom: 1px solid #c1c1c1;

  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  color: #28293d;
`
const RowDetails = styled.div`
  width: 30.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
const HeaderCheckbox = styled(Checkbox)`
  width: 1vw;
  align-self: center;
`
const BatchDetail = styled.p`
  width: 11.5vw;
  flex: 1;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const LotDetail = styled.p`
  width: 11.5vw;
  display: flex;
  justify-content: center;
  overflow-wrap: anywhere;
  text-align: center;
`
const MetersDetail = styled.p`
  width: 6.5vw;
  display: flex;
  justify-content: center;
  overflow-wrap: anywhere;
`
const SpaceDetail = styled.p`
  width: 0.5vw;
`