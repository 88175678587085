import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppStore from 'utils/AppStore'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { Loader, Router } from 'components'

export default function App() {
  const [loading, setloading] = useState(true)

  useEffect(() => {
    const init = async () => {
      await AppStore.init()
      setloading(false)
    }

    init()
  }, [])

  return (
    <BrowserRouter>
      <ToastContainer />
      {!loading && <Router />}
      {loading && (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </div>
      )}
    </BrowserRouter>
  )
}
