import { CartLine, CartResponse, ConfirmOrderRespone, Header } from 'types'
import Cookies from 'universal-cookie'
import { api, responseErrorCheck } from './api'

export default class Cart {
  //Check if there's an existing cart
  static getCartIfExist() {
    const cookies = new Cookies()
    return api
      .get<CartResponse>('erpusers/' + cookies.get('loggedErpUser') + '/cart')
      .then((res) => responseErrorCheck(res))
  }

  //Create a new cart
  static createCart() {
    const cookies = new Cookies()
    return api
      .post<CartResponse>('erpusers/' + cookies.get('loggedErpUser') + '/cart')
      .then((res) => responseErrorCheck(res))
  }

  //Delete cart
  static deleteCart() {
    const cookies = new Cookies()
    return api
      .delete<string>('erpusers/' + cookies.get('loggedErpUser') + '/cart')
      .then((res) => responseErrorCheck(res))
  }

  //Modify current order header
  static modifyOrderHeader(newHeader: Header) {
    const cookies = new Cookies()
    return api
      .post<string>('erpusers/' + cookies.get('loggedErpUser') + '/cart/header', newHeader)
      .then((res) => responseErrorCheck(res))
  }

  //Confirm order
  static confirmOrder(externalOrderNo: string, email: string, language: string) {
    const cookies = new Cookies()
    return api
      .post<ConfirmOrderRespone>('erpusers/' + cookies.get('loggedErpUser') + '/cart/confirm', { externalOrderNo: externalOrderNo, email: email, language: language })
      .then((res) => responseErrorCheck(res))
  }

  //Add new item to cart
  static createCartLine(line: CartLine) {
    const cookies = new Cookies()
    return api
      .post<CartLine[]>('erpusers/' + cookies.get('loggedErpUser') + '/cart/lines', line)
      .then((res) => responseErrorCheck(res))
  }

  //Delete item form cart
  static deleteCartLine(lineNumber: string) {
    const cookies = new Cookies()
    return api
      .delete<CartResponse>('erpusers/' + cookies.get('loggedErpUser') + '/cart/lines/' + lineNumber)
      .then((res) => responseErrorCheck(res))
  }
}
