import { ErpUsers, NewCredential, PaginationResource, User } from 'types'
import { api, responseErrorCheck } from './api'

export default class Users {
  //Create a user
  static createUser(user: User) {
    const newUser: any = user
    delete newUser['id']
    delete newUser['active']
    return api.post<User>('user', newUser).then((res) => responseErrorCheck(res))
  }

  //Return users by search string
  static searchUsers(
    searchStr: string,
    page: number,
    itemPerPage: number,
    orderBy: string,
    sortModifier: string) {
    var url = 'user/search?page=' + page + '&itemPerPage=' + itemPerPage
    url = orderBy ? url + '&orderBy=' + orderBy : url + '&orderBy=BUSINESS_NAME'
    url = sortModifier ? url + '&sortModifier=' + sortModifier : url + '&sortModifier=DESC'
    return api
      .post<PaginationResource<User>>(url, { searchStr })
      .then((res) => responseErrorCheck(res))
  }

  //Return a user by id
  static getUserById(id: number) {
    return api
      .get<User>('user/' + id, { id })
      .then((res) => responseErrorCheck(res))
  }

  //Patch a user
  static patchUserById(id: number, value: any[]) {
    return api
      .patch<User>('user/' + id, value)
      .then((res) => responseErrorCheck(res))
  }

  //Return ERP users by search string
  static getErpUserByName(value: string, page: string, itemPerPage: string) {
    return api
      .get<PaginationResource<ErpUsers>>(
        'user/erpuser?page=' + page + '&itemPerPage=' + itemPerPage + '&searchString=' + value,
        {}
      )
      .then((res) => responseErrorCheck(res))
  }

  //Return new credentials (username and password)
  static getNewCredential() {
    return api.get<NewCredential>('user/newcredentials', {}).then((res) => responseErrorCheck(res))
  }
}
