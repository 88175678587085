import moment from 'moment'

export const getBusinessDays = (daysToAdd: number, startDate?: Date) => {
    var currentDate = new Date()
    if (startDate) {
        const diffTime = startDate!.getTime() - currentDate.getTime()
        const diffDays = Math.floor(diffTime / (1000 * 3600 * 24))
        currentDate.setDate(currentDate.getDate() + (diffDays + 1))
    } else currentDate.setDate(currentDate.getDate() + 1)
    var businessDaysToAdd = daysToAdd
    var effectiveDaysToAdd = 0
    while (businessDaysToAdd > 0) {
        if (moment(currentDate).day() !== 0 &&
            moment(currentDate).day() !== 6) {
            businessDaysToAdd--
        }
        effectiveDaysToAdd++
        currentDate.setDate(currentDate.getDate() + 1)
    }
    return effectiveDaysToAdd
}

export const getDefaultDateValue = (startDate: Date, daysToAdd: number) => {
    if (daysToAdd === undefined || isNaN(daysToAdd) || daysToAdd === 0) {
        daysToAdd = 3
    }
    return moment(startDate).add(getBusinessDays(daysToAdd), 'days')
}