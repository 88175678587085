import { Line } from '@ant-design/charts';
import styled from 'styled-components';
import { LineChartConfig, LineChartDataType, StatsResponse } from 'types';
import { MonthDomain } from 'types/dashboard.domain.service';
import EmptyRow from './EmptyRow';

export default function LineChart({ products, yAxis }: { products: StatsResponse[]; yAxis: boolean }) {
  let scrHeight = window.innerHeight
  let scrWidth = window.innerWidth

  let mywidth = (777 * scrWidth) / 1440
  let myheight = (490 * scrHeight) / 900
  var dataArray: LineChartDataType[] = []

  products.forEach((prod) => {
    dataArray.push({
      mese:  MonthDomain.find((m) => {return m.value === prod.month})?.month! + ' ' + prod.year,
      //mese: convertMonthFromNumber(prod.month) + ' ' + prod.year,
      value: yAxis ? prod.quantity : prod.amount,
    })
  })

  // function convertMonthFromNumber(month: Number): string {
  //   switch (month) {
  //     case 1:
  //       return 'Gen'
  //     case 2:
  //       return 'Feb'
  //     case 3:
  //       return 'Mar'
  //     case 4:
  //       return 'Apr'
  //     case 5:
  //       return 'Mag'
  //     case 6:
  //       return 'Giu'
  //     case 7:
  //       return 'Lug'
  //     case 8:
  //       return 'Ago'
  //     case 9:
  //       return 'Set'
  //     case 10:
  //       return 'Ott'
  //     case 11:
  //       return 'Nov'
  //     case 12:
  //       return 'Dic'
  //     default:
  //       return 'Gen'
  //   }
  // }

  var config: LineChartConfig = {
    data: dataArray,
    width: (627 * scrWidth) / 1440,
    height: (406 * scrHeight) / 900,
    autoFit: false,
    xField: 'mese',
    yField: 'value',
    padding: 'auto',
    smooth: true,
    slider: {
      start: 0.8,
      end: 1,
    },
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
    color: '#BBA391',
  }

  return (
    <>
      <Container style={{ width: mywidth, height: myheight, textAlign: 'center', padding: '30px 0px' }}>
        {dataArray.length >= 1 && <CustomLine {...config} />}
        {dataArray.length === 0 && <EmptyRow text="No values" />}
      </Container>
    </>
  )
}

const Container = styled.div`
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 24px;
`

const CustomLine = styled(Line)`
  font-size: 10vw;
`
