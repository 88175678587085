export const LineChartDomain = [
    {
        label: 'dashboard_page.select_filter_by.option_quantity',
        value:'quantity'
    },
    {
        label: 'dashboard_page.select_filter_by.option_amount',
        value:'amount'
    }
];

export const MonthDomain = [
    {
        month: 'Gen',
        value: 1
    },
    {
        month: 'Feb',
        value: 2
    },
    {
        month: 'Mar',
        value: 3
    },
    {
        month: 'Apr',
        value: 4
    },
    {
        month: 'Mag',
        value: 5
    },
    {
        month: 'Giu',
        value: 6
    },
    {
        month: 'Lug',
        value: 7
    },
    {
        month: 'Ago',
        value: 8
    },
    {
        month: 'Set',
        value: 9
    },
    {
        month: 'Ott',
        value: 10
    },
    {
        month: 'Nov',
        value: 11
    },
    {
        month: 'Dec',
        value: 12
    }
]
