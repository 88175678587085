import styled from 'styled-components'

export default function AddUserPageStep5() {
  return (
    <>
      <BigTitle>Inserimento completato con successo!</BigTitle>
    </>
  )
}

const BigTitle = styled.h3`
  /* Serif/H1/Light */

  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 300;
  font-size: 3vw;
  /* or 121% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
`
