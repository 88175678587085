import { PurchasedProductName, StatsResponse } from 'types'
import { api, responseErrorCheck } from './api'

export default class Stats {
  //Get list of last purchased products
  static getLastPurchasedProducts() {
    return api
      .get<PurchasedProductName[]>('stats/getlastpurchasedproducts', {})
      .then((res) => responseErrorCheck(res))
  }

  //Get statistics for each given months
  static getAmountAndQuantityPerMonth(
    lastXMonths: Number,
    erpUserCodes?: string | string[],
    productCode?: string) {
    return api
      .post<StatsResponse[]>('stats/getamountandquantitypermonth', { lastXMonths, erpUserCodes, productCode })
      .then((res) => responseErrorCheck(res))
  }
}
