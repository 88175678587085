import styled from 'styled-components';
import background from 'assets/login-background.png';
import { useState } from 'react';
import { Button } from 'antd';
import { User } from 'types';
//import ForgotPasswordCard from 'components/ForgotPasswordCard'
import { ErpUsersCard, LoginCard, Spacer } from 'components';
import itaLanguage from 'assets/iconsUserRow/it.png';
import enLanguage from 'assets/iconsUserRow/en.png';
import esLanguage from 'assets/iconsUserRow/es.png';
import { changeLanguage } from 'translations/i18n';
import { reloadApp } from 'utils/router';
import Cookies from 'universal-cookie';
import ContactUsCard from 'components/ContactUsCard';

export default function Login({
  onLoginCallback,
  onLoginCallback2,
}: {
  onLoginCallback: React.Dispatch<React.SetStateAction<User>>
  onLoginCallback2: React.Dispatch<React.SetStateAction<string>>
}) {
  const [index, setIndex] = useState(1)
  const cookie = new Cookies()

  return (
    <Container>
      {index === 1 && 
        <LangOptions>
          <CustomButton>
            <LangButton  onClick={(e) => { changeLanguage('it'); reloadApp(); cookie.set('language', 'it'); }} src={itaLanguage} alt="Set language to italian" />
          </CustomButton>
          <CustomButton>
            <LangButton  onClick={(e) => { changeLanguage('en'); reloadApp(); cookie.set('language', 'en') }} src={enLanguage} alt="Set language to english" />
          </CustomButton>
          <CustomButton>
            <LangButton  onClick={(e) => { changeLanguage('es'); reloadApp(); cookie.set('language', 'es') }} src={esLanguage} alt="Set language to spanish" />
          </CustomButton>
          <Spacer />
        </LangOptions>
      }
      {index !== 1 && <EmptyLangOptions/>}
      <Content>
        <Card>
          {index === 1 && (
            <LoginCard
              onClickMainButtonWithMultipleERPUsers={() => setIndex(2)}
              onLoginCallback={onLoginCallback}
              onClickPasswordForgot={() => setIndex(3)}
              onClickContactUs={() => setIndex(4)}
            />
          )}
          {index === 2 && <ErpUsersCard onLoginCallback={onLoginCallback} onLoginCallback2={onLoginCallback2} />}
          {/* {index === 3 && <ForgotPasswordCard onClick={() => setIndex(1)} />} */}
          {index === 3 && <ContactUsCard onClick={() => setIndex(1)} />}
        </Card>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  background-image: url(${background});
  background-size: cover; 
  background-repeat: no-repeat; 
  height: 100%;
`;
const Content = styled.div`
  display: flex;
  height: 90%;
  justify-content: center;
  // background: url(${background});
  // background-size: cover;
  // background-repeat: no-repeat;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: fit-content;
  align-self: center;
  width: 40vw;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 24px;
  padding-bottom: 1.5vw;
`;

const CustomButton = styled(Button)`
  cursor: pointer;
  margin-left: 12px;
  //margin-right: 12px;
  //background: trasparent;
  border-color: #ffffff00;
  background: rgb(0 0 0 / 0%);
  border: none;
  width: 2vw;
  height: 2vw;
  :hover {
    background: rgb(0 0 0 / 0%);
  }
`;

const LangOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 1.5%;
`;

const EmptyLangOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 5vh;
`;

const LangButton = styled.img`
  width: 2vw;
`;
