import styled from 'styled-components'
import Spacer from 'components/Spacer'
import Button from 'components/Button'
import chart from 'assets/icons/Chart.png'
import i18n from 'translations/i18n'
import { CartResponse } from 'types'
import { Checkbox, Input } from 'antd'
import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import AppStore from 'utils/AppStore'

export default function CartDrawer({
  objectClicked,
  onClick,
  changeExpressShipment,
  changeExternalDocumentNo,
  changeOrderEmail
}: {
  objectClicked?: CartResponse
  onClick: () => void
  changeExpressShipment: (v: boolean) => void
  changeExternalDocumentNo: (externalDocNo: string) => void
  changeOrderEmail: (orderEmail: string) => void
}) {

  const cookies = new Cookies()
  const loggedErpUser = cookies.get('loggedErpUser')
  const [email, setEmail] = useState<string>(localStorage.getItem('orderEmail' + loggedErpUser) !== null ? localStorage.getItem('orderEmail' + loggedErpUser)! : AppStore.loggedUser.email)
  const [externalDocNo, setExternalDocumentNo] = useState<string>(localStorage.getItem('externalDocNo' + loggedErpUser) !== null ? localStorage.getItem('externalDocNo' + loggedErpUser)! : '')
  const [checked, setChecked] = useState<boolean | undefined>()

  useEffect(() => {
    const expressShipment = 'expressShipment' + loggedErpUser
    if (localStorage.getItem(expressShipment) === null) {
      localStorage.setItem(expressShipment, 'false')
    }
    setChecked(localStorage.getItem(expressShipment) === 'true' ? true : false)
    // eslint-disable-next-line
  }, [])

  return (
    <Drawer>
      <DrawerHeader>
        <CartTitle>{i18n.t('cartPage.title')}</CartTitle>
        <CartIcon src={chart} />
      </DrawerHeader>
      <Spacer /> <Spacer /> <Spacer />
      <FirstRow>
        <Title>{i18n.t('cartPage.drawer.title1')}</Title>
        <ContentTitle>
          {(Math.round(Number(objectClicked?.summary.unitValues[0].measureValue) * 100) / 100).toFixed(2) + 'm'}
        </ContentTitle>
      </FirstRow>
      <Spacer />
      <SecondRow>
        <SubContentContainer>
          <SubTitle style={{}}>{i18n.t('cartPage.drawer.title2')}</SubTitle>
          <SubContentDetail>{objectClicked?.summary.batchesNumber}</SubContentDetail>
        </SubContentContainer>
        <div style={{ width: 3 }} />
        <SubContentContainer>
          <SubTitle style={{}}>{i18n.t('cartPage.drawer.title3')}</SubTitle>
          <SubContentDetail>{objectClicked?.summary.metersFromBatch}m</SubContentDetail>
        </SubContentContainer>
      </SecondRow>
      <Spacer />
      <SubContentContainer>
        <SubTitle style={{}}>{i18n.t('cartPage.drawer.title4')}</SubTitle>
        <SubContentDetail>
          {objectClicked &&
            (Math.round(Number(objectClicked?.summary.unitValues[0].measureValue) * 100) / 100).toFixed(2) + 'm'}
        </SubContentDetail>
      </SubContentContainer>
      <Spacer />
      <ExtraInfo>
        <p style={{ marginLeft: '4%' }}>Email</p>
        <ExtraInfoInput
          placeholder={'Email'}
          ordered={true}
          defaultValue={email}
          onChange={(e) => {
            changeOrderEmail(e.target.value)
            setEmail(e.target.value)
          }}
        />
      </ExtraInfo>
      <Spacer /><Spacer />
      <ExtraInfo>
        <p style={{ marginLeft: '4%' }}>{i18n.t('cartPage.drawer.external_document')}</p>
        <ExtraInfoInput
          placeholder={i18n.t('cartPage.drawer.external_document')}
          maxLength={20}
          ordered={true}
          defaultValue={externalDocNo}
          onChange={(e) => {
            changeExternalDocumentNo(e.target.value)
            setExternalDocumentNo(e.target.value)
          }}
        />
      </ExtraInfo>
      <Spacer /><Spacer />
      <Checkbox
        style={{ alignSelf: 'center' }}
        checked={checked!}
        onChange={(e) => {
          changeExpressShipment(e.target.checked)
          setChecked(e.target.checked)
        }}
      >
        {i18n.t('cartPage.drawer.express_shipment')}
      </Checkbox>
      <Spacer /><Spacer /> <Spacer /><Spacer />
      <Button
        style={{ width: '60%', alignSelf: 'center' }}
        inverted
        text={i18n.t('cartPage.drawer.button')}
        onClick={onClick}
      ></Button>
    </Drawer>
  )
}

const Drawer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
  background: #ffffff;
  box-shadow: -44.65px 0px 30px rgba(22, 26, 51, 0.03), -17.2px 0px 9.55556px rgba(22, 26, 51, 0.0182222),
    -3.64px 0px 2.44444px rgba(22, 26, 51, 0.0117778);
  border-radius: 24px 0px 0px 24px;
`
const DrawerHeader = styled.div`
  width: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5vw;
  padding-bottom: 2vh;
  border-bottom: 1px solid #774A28;
`
const CartTitle = styled.h1`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  color: #774a28;
`
const CartIcon = styled.img`
  width: 24px;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
`
const Title = styled.h2`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  align-self: center;
  color: #28293d;
`
const ContentTitle = styled.h5`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: center;
  align-self: center;
  color: #28293d;
`
const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  justify-content: space-around;
  margin: auto;
  text-align: center;
`
const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 65%;
  justify-content: space-around;
  margin: auto;
  text-align: center;
`
const SubContentContainer = styled.div`
  display: flex; 
  flex-direction: column;
`
const SubTitle = styled.h3`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  align-self: center;
  color: #28293d;
`
const SubContentDetail = styled.h5`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  align-self: center;
  color: #28293d;
`
const ExtraInfo = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  width: 13.5vw; 
  margin: auto
`
const ExtraInfoInput = styled(Input)`
  width: 13vw;
  height: 3.5vh;
  border: 1px solid #d9d9d9 !important;
  box-sizing: border-box;
  border-radius: 20px;
  margin: auto;
  &::placeholder {
    text-align:left;
  }
`
