import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Checkbox, Select, Button as AntButton, Drawer } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import CustomerRow from 'components/CustomerRow';
import LanguageDrawer from 'components/Drawer/LanguageDrawer';
import PasswordDrawer from 'components/Drawer/PasswordDrawer';
import UserDrawer from 'components/Drawer/UserDrawer';
import Users from 'api/Users';
import { CATALOGUE_VISIBILITY, LANGUAGES, PaginationResource, ROLES, User } from 'types';
import { ReactComponent as LoadingIcons } from 'assets/loading.svg';
import i18n from 'translations/i18n';
import styled from 'styled-components';
import { EmptyRow } from 'components';
//import { Subject, debounceTime, Subscription } from 'rxjs'

const { Option } = Select;

// const searchObservable = new Subject<string>();
// const searchChange$ = searchObservable.asObservable();

export default function CustomersPage() {

  const [loading, setLoading] = useState(false);
  const [pagedUsers, setPagedUsers] = useState<PaginationResource<User> | undefined>({
    totalPageNumber: '0',
    totalItemNumber: '0',
    pageNumber: '0',
    itemPerPage: '0',
    elements: [],
  });
  //To handle checkbox active/not active
  const [showActiveUsers, setShowActiveUser] = useState(true);
  const [showDeactiveUsers, setShowDeactiveUser] = useState(true);

  const [textFilter, setTextFilter] = useState('');
  const [orderBy, setOrderBy] = useState('BUSINESS_NAME');
  const [sortModifier, setSortModifier] = useState('ASC');

  const scrollRef = useRef<null | HTMLDivElement>(null);
  const [clientHeight, setClientHeight] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageNumber, setMaxPageNumber] = useState(0);
  const [dataRetrieving, setDataRetrieving] = useState<boolean>(false);

  const [drawerVisible, setDrawerVisible] = useState('');
  const [pswExpiringIn, setPswExpiringIn] = useState('9999');

  const emptyUser = {
    id: -1,
    active: true,
    catalogueVisibility: CATALOGUE_VISIBILITY.NORMAL,
    language: LANGUAGES.ITALIAN,
    username: '',
    pwd: '',
    email: '',
    name: '',
    surname: '',
    businessName: '',
    roles: [ROLES.BASIC],
    erpUsers: [],
    permissions: []
  };
  const [toBeModifiedUser, setToBeModifiedUser] = useState<User>(emptyUser);

  const getUsers = async (value: string, pageNumber = 0, orderBy = 'BUSINESS_NAME', sortModifier = 'ASC') => {
    try {
      setLoading(true);
      const res = await Users.searchUsers(value, pageNumber, 50, orderBy, sortModifier);
      if (res) setMaxPageNumber(Number(res.totalPageNumber) - 1);
      if (pageNumber !== 0 && pagedUsers) {
        var _pagResource: PaginationResource<User> = pagedUsers;
        _pagResource.itemPerPage = res.itemPerPage;
        _pagResource.pageNumber = res.pageNumber;
        _pagResource.totalItemNumber = res.totalItemNumber;
        _pagResource.totalPageNumber = res.totalPageNumber;
        _pagResource.elements = pagedUsers.elements.concat(res.elements);
        setPagedUsers(_pagResource);
        setLoading(false);

        if (scrollRef && scrollRef.current) {
          if (clientHeight !== 0) {
            scrollRef.current.scrollTop = clientHeight;
          }
        }
      } else {
        setPagedUsers(res);
      }
      setDataRetrieving(false);
      setLoading(false);
    } catch (error) { }
    // eslint-disable-next-line
  }

  useEffect(() => {
    getUsers('', 0);
    // const seachSubscription: Subscription = searchChange$.pipe(debounceTime(600)).subscribe(term => {
    //   getUsers(term, 0)
    // });
    return () => {
      ////seachSubscription.unsubscribe();
      setPageNumber(0);
      setMaxPageNumber(0);
      setTextFilter('');
    }
    // eslint-disable-next-line
  }, [])

  const handleScroll = (e: Event): void=> {
    var bottom = Math.abs((e.target as HTMLInputElement).scrollHeight - (e.target as HTMLInputElement).scrollTop - (e.target as HTMLInputElement).clientHeight) <= 1;
    setClientHeight((e.target as HTMLInputElement).scrollTop);
    if (bottom && pageNumber < maxPageNumber && !dataRetrieving) {
      setDataRetrieving(true);
      setPageNumber(pageNumber + 1);
      getUsers(textFilter, pageNumber + 1, orderBy, sortModifier);
    }
  }

  const onChangeOrderBy = (value: string): void => {
    setOrderBy(value === '1' ? 'PWD_EXP_DATE' : 'BUSINESS_NAME');
    setPageNumber(0);
    setMaxPageNumber(0);
    setPagedUsers(undefined);
    getUsers(textFilter, 0, value === '1' ? 'PWD_EXP_DATE' : 'BUSINESS_NAME', sortModifier);
  }

  const onChangeSortModifier = (value: string): void => {
    setSortModifier(value === '1' ? 'ASC' : 'DESC');
    setPageNumber(0);
    setMaxPageNumber(0);
    setPagedUsers(undefined);
    getUsers(textFilter, 0, orderBy, value === '1' ? 'ASC' : 'DESC');
  }

  const onChangeText = (value: Event): void => {
    setTextFilter((value.target as HTMLInputElement).value);
    //searchObservable.next((value.target as HTMLInputElement).value)
    getUsers((value.target as HTMLInputElement).value, 0);
  }

  const onClose = (): void => {
    //getUsers(textFilter, 0, orderBy, sortModifier);
    setDrawerVisible('');
  }

  const onCloseAndUpdate = (): void => {
    getUsers(textFilter, 0, orderBy, sortModifier);
    setDrawerVisible('');
  }

  const getDifferenceInDays = (date: string): string=> {
    const newDate = new Date(date);
    var todayDate = new Date();
    var msDiff = new Date(newDate).getTime() - todayDate.getTime();
    var dayRemain = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    return dayRemain + '';
  }

  return (
    <PageLayout>
      <Header>
        <HeaderRow>
          <HeaderInput
            onChange={onChangeText}
            onPressEnter={(e: Event) => {
              getUsers((e.target as HTMLInputElement).value, 0)
            }}
            placeholder={i18n.t('customersPage.searchbar_placeholder')}
            suffix={<SearchOutlined />}
          />
          <HeaderSelect
            defaultValue="2"
            showSearch
            placeholder={i18n.t('customersPage.select_placeholder')}
            optionFilterProp="children"
            onChange={onChangeOrderBy}
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="1">{i18n.t('customersPage.option_expired_password')}</Option>
            <Option value="2">{i18n.t('customersPage.option_company')}</Option>
          </HeaderSelect>
          <HeaderSelect
            defaultValue="1"
            showSearch
            placeholder={i18n.t('customersPage.select_placeholder')}
            optionFilterProp="children"
            onChange={onChangeSortModifier}
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="1">ASC</Option>
            <Option value="2">DESC</Option>
          </HeaderSelect>
        </HeaderRow>

        <HeaderRow>
          <HeaderCheckboxes>
            <HeaderCheckboxText>{i18n.t('customersPage.checkbox_active')}</HeaderCheckboxText>
            <HeaderCheckbox
              checked={showActiveUsers}
              onChange={() => setShowActiveUser(!showActiveUsers)}
              placeholder="default size"
              suffix={<SearchOutlined />}
            />
          </HeaderCheckboxes>
          <HeaderCheckboxes>
            <HeaderCheckboxText>{i18n.t('customersPage.checkbox_inactive')}</HeaderCheckboxText>
            <HeaderCheckbox
              checked={showDeactiveUsers}
              onChange={() => setShowDeactiveUser(!showDeactiveUsers)}
              placeholder="default size"
              suffix={<SearchOutlined />}
            />
          </HeaderCheckboxes>
          <Link to="/add-user">
            <AddUserButton icon={<PlusOutlined />}>{i18n.t('customersPage.button_add_user')}</AddUserButton>
          </Link>
        </HeaderRow>
      </Header>

      <Customers ref={scrollRef} onScroll={handleScroll}>
        {!loading &&
          pagedUsers?.elements.map((user, index) => {
            if ((user.active && showActiveUsers) || (!user.active && showDeactiveUsers))
              return (
                <CustomerRow
                  key={index}
                  onPasswordClick={(passwordExpiration: string) => {
                    setToBeModifiedUser(user)
                    setPswExpiringIn(passwordExpiration)
                    setDrawerVisible('password')
                  }}
                  onUserClick={() => {
                    setToBeModifiedUser(user)
                    setDrawerVisible('user')
                  }}
                  onLanguageClick={() => {
                    setToBeModifiedUser(user)
                    setDrawerVisible('language')
                  }}
                  user={user}
                  pswExpiration={getDifferenceInDays(user.pwdExpiringAt!)}
                />
              )
            return <></>
          })
        }
        {(!loading && pagedUsers?.elements.length === 0) &&
          <EmptyRow  text="Nessuna corrispondenza trovata"/>
        }
        {loading && <LoadingIcons />}
      </Customers>

      <Drawer
        title=""
        width={drawerVisible === 'user' ? '31vw' : '25vw'}
        height={'410px'}
        placement="right"
        onClose={onClose}
        visible={drawerVisible !== ''}
      >
        {drawerVisible === 'language' && (
          <LanguageDrawer
            user={toBeModifiedUser}
            onClick={onClose}
          />)}
        {drawerVisible === 'password' && (
          <PasswordDrawer
            passwordExpiringIn={pswExpiringIn}
            user={toBeModifiedUser}
            onClick={onClose}
          />
        )}
        {drawerVisible === 'user' && (
          <UserDrawer
            user={toBeModifiedUser}
            onClick={onClose}
            closeAndUpdate={onCloseAndUpdate}
          />)}
      </Drawer>
    </PageLayout>
  )
}

const PageLayout = styled.div`
  margin-left: 10vw; 
  margin-right: 21.5vw;
  margin-top: 8vh; 
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4vh;
`;
const HeaderInput = styled(Input)`
  height: 4.7vh;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  //padding: 8px 24px;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border: none;
  border-radius: 24px;
  margin-right: 30px;
  margin-left: 2.3vw;
`;
const HeaderSelect = styled(Select)`
  height: 4.5vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 8px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 24px;
  align-items: center;
  margin-left: 2.3vw;
`;
const HeaderCheckboxes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 24px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 24px;
  margin: 0px 8px;
  margin-left: 2.3vw;
`;
const HeaderCheckboxText = styled.h4`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  display: flex;
  align-items: center;
  align-self: center;
  color: #28293d;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
`;
const HeaderCheckbox = styled(Checkbox)`
  align-self: center;
`;
const AddUserButton = styled(AntButton)`
  height: 4.7vh;
  background: #bba391;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  border-radius: 24px;
  margin-left: 2.3vw;

  :hover {
    background: #bba391;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 24px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
    border-radius: 24px;
    border: none;
    align-items: center;
  }
`;
const Customers = styled.div`
  overflow-y: scroll; 
  height: 68vh;
`;