import { useEffect, useState } from 'react'
import { Radio } from 'antd'
import Button from 'components/Button'
import Spacer from '../Spacer'
import { LANGUAGES, User } from 'types'
import Users from 'api/Users'
import { AppStore, showError, showInfo } from 'utils'
import i18n, { changeLanguage } from 'translations/i18n'
import itaLanguage from 'assets/iconsUserRow/it.png'
import enLanguage from 'assets/iconsUserRow/en.png'
import esLanguage from 'assets/iconsUserRow/es.png'
import styled from 'styled-components'
import Cookies from 'universal-cookie'

export default function LanguageDrawer({
  user,
  onClick
}: {
  user: User;
  onClick: () => void
}) {

  const [languageSelected, setLanguageSelected] = useState<LANGUAGES>(user.language)
  const cookie = new Cookies()

  const patchUser = async () => {
    try {
      await Users.patchUserById(user.id, [{ op: 'replace', path: '/language', value: languageSelected }])
      onClick()
      showInfo(i18n.t('customersPage.drawer_language.info_changed_language') + languageSelected)
      if (user.id === AppStore.loggedUser.id) {
        AppStore.loggedUser.language = languageSelected
        cookie.set('language', AppStore.getLanguageValue())
        changeLanguage(cookie.get('language'))
        window.location.reload()
      }
    } catch (error) {
      showError(i18n.t('customersPage.drawer_language.error_add_user'))
    }
  }

  useEffect(() => {
    setLanguageSelected(user.language)
  }, [user.language])

  return (
    <Drawer>
      <DrawerTitle>{i18n.t('customersPage.drawer_language.title1')}</DrawerTitle>
      <OptionsContainer>
        <div style={{ alignSelf: 'center' }}>
          <LanguageIcon
            src={
              user.language === LANGUAGES.ITALIAN
                ? itaLanguage
                : user.language === LANGUAGES.ENGLISH
                  ? enLanguage
                  : esLanguage
            }
            alt=""
          />
        </div>
        <Spacer />
        <Languages>
          {user.language === LANGUAGES.ITALIAN
            ? i18n.t('customersPage.drawer_language.italian')
            : user.language === LANGUAGES.ENGLISH
              ? i18n.t('customersPage.drawer_language.english')
              : i18n.t('customersPage.drawer_language.spanish')}
        </Languages>
      </OptionsContainer>
      <Spacer /><Spacer /><Spacer /><Spacer /><Spacer />
      <DrawerTitle>{i18n.t('customersPage.drawer_language.title2')}</DrawerTitle>
      <Spacer /><Spacer />
      <OptionsContainer>
        <OptionsGroup optionType="button" buttonStyle="solid" defaultValue={languageSelected}>
          <Option>
            <OptionButton onChange={(e) => setLanguageSelected(e.target.value)} value={LANGUAGES.ITALIAN}>
              <LanguageIcon src={itaLanguage} alt="" />
            </OptionButton>
          </Option>
          <Spacer />
          <Option>
            <OptionButton onChange={(e) => setLanguageSelected(e.target.value)} value={LANGUAGES.ENGLISH}>
              <LanguageIcon src={enLanguage} alt="" />
            </OptionButton>
          </Option>
          <Spacer />
          <Option>
            <OptionButton onChange={(e) => setLanguageSelected(e.target.value)} value={LANGUAGES.SPANISH}>
              <LanguageIcon src={esLanguage} alt="" />
            </OptionButton>
          </Option>
        </OptionsGroup>
      </OptionsContainer>
      <Spacer /><Spacer /><Spacer /><Spacer />
      <Button text={i18n.t('customersPage.drawer_language.button_confirm')} onClick={patchUser}></Button>
    </Drawer>
  )
}

const Drawer = styled.div`
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  flex: 1;
  margin-left: 2.222vw;
  margin-right: 2.222vw;
`
const DrawerTitle = styled.h3`
  font-size: 2vw;
  color: #000000;
`
const LanguageIcon = styled.img`
  width: 2vw;
  align-self: center;
`
const Languages = styled.h5`
  font-size: 1.3vw;
  color: #000000;
  margin-top: 8px;
  align-self: center;
`
const OptionsContainer = styled.div`
  height: 6vh;
  display: flex;
  flex-direction: row;
`
const OptionsGroup = styled(Radio.Group)`
  display: flex;
`
const Option = styled.div`
  height: 6vh;
  display: flex;
  flex-direction: column;
`
const OptionButton = styled(Radio.Button)`
  background: #ffffff;
  font-size: 1vw;
  color: #28293d;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: -7px 4px;
  padding: 12px 24px;
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 16px;
  
  :hover {
    background: #bba391;
    color: white;
  }
`
