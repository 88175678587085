export const OrderByDomain = [
  {
    label: "domain.select_order_by.date.asc",
    value: "ASC"
  },
  {
    label: "domain.select_order_by.date.desc",
    value: "DESC"
  }
];

export enum CATALOGUE_VISIBILITY {
  NORMAL = 'NORMAL',
  EXTENDED = 'EXTENDED',
}
export enum ROLES {
  ADMIN = 'ADMIN',
  USER = 'USER',
  POWERUSER = 'POWERUSER',
  BASIC = 'BASIC'
}
export enum LANGUAGES {
  SPANISH = 'SPANISH',
  ENGLISH = 'ENGLISH',
  ITALIAN = 'ITALIAN',
}

export enum DOCUMENTAL {
  PACKINGLIST = 'PACKINGLIST',
  ORDER = 'ORDER',
  DDT = 'DDT',
  INVOICE = 'INVOICE',
}

export enum ORDER_STATUSES {
  SPEDITO = 'Spedito',
  INSERITO = 'Inserito',
  SPEDITO_PARZIALE = 'Spedito Parziale',
  APPROVATO = 'Approvato',
  PROMESSA = 'Promessa',
  RICHIESTA = 'Richiesta'
}

export enum LOT_TYPES {
  LONG = "LONG",
  SHORT = "SHORT"
}

export interface LoginResponse {
  token: string
}
export interface NewPasswordResponse {
  value: string
}
export interface ContactSupportResponse {
  value: string
}
export interface ErpUsers {
  code: string
  name: string
}

export interface User {
  id: number,
  active: boolean,
  catalogueVisibility: CATALOGUE_VISIBILITY,
  language: LANGUAGES,
  username: string,
  email: string,
  name: string,
  surname: string,
  businessName: string,
  pwdExpiringAt?: string,
  pwd: string,
  roles: ROLES[],
  erpUsers: ErpUsers[],
  permissions: string[]
}

export interface PaginationResource<T> {
  totalPageNumber: string
  totalItemNumber: string
  pageNumber: string
  itemPerPage: string
  elements: T[]
}

export interface OrderResponse {
  code?: string
  documentType?: string
  documentNumber?: string
  lineNumber?: string
  sellToCustomerNumber?: string
  type?: string
  variantCode?: string
  description?: string
  crossRefDescription?: string
  locationCode?: string
  quantity: string
  quantityToShip?: string
  reservedQuantityBase?: string
  unitMeasure?: string
  lineAmount?: string
  promisedDeliveryDate?: string
  shipmentDate?: string
  outstandingQuantity?: string
  lotNumber: Number
  lotQuantity: Number
  batches: Batch[]
  statusLine: string
}
export interface AllOrderResponse {
  status: string
  documentDate: string
  quantity: string
  outstandingQuantity: string
  authorizedAdmin: string
  lines: Line[]
  documentNumber: string
  expressCourierShipment: string
  externalDocumentNo: string
  email: string
}

interface Line {
  code: string
  documentType: string
  documentNumber: string
  lineNumber: string
  sellToCustomerNumber: string
  type: string
  variantCode: string
  description: string
  locationCode: string
  quantity: string
  quantityToShip: string
  reservedQuantityBase: string
  unitMeasure: string
  lineAmount: string
  shipmentDate: string
  outstandingQuantity: string
  lotNumber: Number
  lotQuantity: Number
  batche: BatchToCart[]
  statusLine: string
}
export interface Batch {
  entryNumber?: string
  lotNumber?: string
  lineNumber?: string
  quantityBase?: string
  support?: string
}

export interface BatchToCart {
  entryNumber: string
  lotNumber: string
  lineNumber: string
  quantityBase: string
}

export interface PurchasedProductName {
  name: string;
  description: string;
  erpuser: string;
}

export interface StatsResponse {
  month: Number
  year: Number
  quantity: Number
  currency: string
  amount: Number
}

export interface Currency {
  currencyCode: string
  defaultFractionDigits: Number
  numericCode: Number
  displayName: string
  symbol: string
  numericCodeAsString: string
}

export interface NewCredential {
  username: string
  pwd: string
}
export interface LineChartDataType {
  mese: string
  value: Number
}

export interface LineChartConfig {
  data: LineChartDataType[]
  width: Number
  height?: Number
  autoFit: boolean
  xField: string
  yField: string
  xAxis?: { tickCount: 5 }
  yAxis?: { tickCount: 5 }

  padding: string
  smooth: boolean
  slider: {
    start: 0.8
    end: 1
  }
  point: {
    size: 5
    shape: 'diamond'
  }
  label: {
    style: {
      fill: '#aaa'
    }
  }
  color: '#BBA391'
}

export interface RecentSearchResponse {
  erpUserCode: string
  itemId: string
  itemType: string
  itemDescription: string
}

export interface CatalogueItem {
  articleNo: string
  articleDescription: string
  variantCode: string
  variantDescription: string
  unitOfMeasure: string
  articleCategory: string
  crossreferenceDescription: string | null
  crossreferenceNo: string | null
  matched: string | null
}

export interface AvaliabilityResponse {
  lotAvailability: LotAvailbilityWrapper
  quantityAvailability: QuantityAvailabilityPerDate[]
}

export interface LotAvailbilityWrapper {
  shortLots: ShortLotAvailabilityList
  longLots: LotAvailabilityList
  preferredSupportCode: string[]
}

export interface LotAvailabilityList {
  list: LotAvailabilityInfo[]
  totalLength: number
}

export interface ShortLotAvailabilityList {
  discount: number
  list: LotAvailabilityInfo[]
  totalLength: number
}

export interface LotAvailabilityInfo {
  lotNumber: string
  quantity: string
  batch: string
  support: string
}

export interface QuantityAvailabilityPerDate {
  dateAvailable: string
  quantity: string
}

export interface DeliveryProspectResponse {
  immediateAvailability: string
  quantityAvailability: QuantityAvailabilityPerDate[]
  leadTimeDays: string
}

export interface CartResponse {
  lines: CartLine[]
  summary: CartSummary
}
export interface CartLine {
  code?: string
  documentNumber?: string
  variantCode?: string
  description?: string
  crossreferenceNo?: string
  quantity?: string
  lotNumber?: Number
  unitMeasure?: string
  documentType?: string
  lineNumber?: string
  lotQuantity?: Number
  batches?: Batch[]
  bulkQuantity?: string
  sellToCustomerNumber?: string
  type?: string
  locationCode?: string
  lineAmount?: string
  shipmentDate?: string
  lotRequestDate: string
  bulkRequestDate: string
}

interface CartSummary {
  batchesNumber: Number
  metersFromBatch: Number
  metersBulk: Number
  unitValues: UnitValue[]

  modifiedDate: string
  expiringDateTime: string
}
interface UnitValue {
  measureUnit: string
  measureValue: string
}

export interface ConfirmOrderRespone {
  result: string
  resultDescription: string
}

export interface DocumentalResponse {
  type: DOCUMENTAL
  number: string
  creationDate: string
}

export interface Header {
  erpUser: string
  expressShipmentAgent: boolean
  externalDocumentNumber: string
  email: string
}

export interface BatchesSelected {
  longLength: Batch[]
  shortLength: Batch[]
}

export interface LotsSelected {
  longLength: string
  shortLength: string
}

export interface CheckedLists {
  longLength: Number[]
  shortLength: Number[]
}

export interface LotsMetersSelected {
  longLength: string
  shortLength: string
}

export interface LotsMetersSelectable {
  longLength: string
  shortLength: string
} 

export interface IndexedLotAvailability {
  index: number;
  batch: string;
  lot: string;
  meter: number;
  support: string;
}

export interface MenuVoices {
  permission: string;
  position: number;
}