import Input from 'components/Input'
import Spacer from 'components/Spacer'
import { User } from 'types'

export default function AddUserPageStep1({
  newUser,
  setNewUser
}: {
  newUser: User
  setNewUser: (user: User) => void
}) {
  return (
    <>
      <Input
        defaultValue={newUser.name}
        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
        type="text"
        title="Nome"
        placeholder="Inserisci qui il nome"
      />
      <Spacer />
      <Input
        defaultValue={newUser.surname}
        onChange={(e) => setNewUser({ ...newUser, surname: e.target.value })}
        type="text"
        title="Cognome"
        placeholder="Inserisci qui il cognome"
      />
      <Spacer />
      <Input
        defaultValue={newUser.email}
        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
        type="text"
        title="Email"
        placeholder="Inserisci qui la tua mail"
      />
      <Spacer />
      <Input
        defaultValue={newUser.businessName}
        onChange={(e) => setNewUser({ ...newUser, businessName: e.target.value })}
        type="text"
        title="Ragione sociale"
        placeholder="Inserisci qui la ragione sociale"
      />
      <Spacer />
      <Spacer />
    </>
  )
}
