import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import Spacer from 'components/Spacer';
import { Select } from 'antd';
import { DocumentalResponse, OrderByDomain, PaginationResource } from 'types';
import { EmptyRow } from 'components';

import { ReactComponent as LoadingIcons } from 'assets/loading.svg';
import i18n from 'translations/i18n';
import { showError } from 'utils';
import Documental from 'api/Documental';
import DocumentalRow from 'components/DocumentalRow';
import { MenuLinkDomain, PERMISSIONS } from 'types/permissions.domain.service';
import { DOCUMENTAL, DocumentalDomain } from 'types/documentary.domain.service';

export default function DocumentsPageContent(props: {
  permission: PERMISSIONS
}) {
  const { Option } = Select;
  const [loading, setLoading] = useState<boolean>(false);

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const [myDocumental, setMyDocumental] = useState<PaginationResource<DocumentalResponse>>();
  const [clientHeight, setClientHeight] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [maxPageNumber, setMaxPageNumber] = useState<number>(0);
  const [documentalType, setDocumentalType] = useState<DOCUMENTAL | null>(props.permission.match(PERMISSIONS.MENU_DOCUMENTARY) ? DOCUMENTAL.ORDER : DOCUMENTAL.DDT);
  const [sortModifier, setSortModifier] = useState<string>('DESC');
  const [retrieving, setRetrieving] = useState<boolean>(false);

  const getDocumental = async (type: DOCUMENTAL, pageNumber: number, sortModifier: string) => {
    try {
      setLoading(true);
      const res = await Documental.getDocumentList(type, pageNumber, 50, sortModifier);

      if (res) setMaxPageNumber(Number(res.totalPageNumber) - 1);
      setLoading(false);
      
      if (pageNumber !== 0 && myDocumental && res) {
        let _pagResource: PaginationResource<DocumentalResponse> = myDocumental;
        _pagResource.itemPerPage = res.itemPerPage;
        _pagResource.pageNumber = res.pageNumber;
        _pagResource.totalItemNumber = res.totalItemNumber;
        _pagResource.totalPageNumber = res.totalPageNumber;
        _pagResource.elements = myDocumental?.elements.concat(res.elements);
        setMyDocumental(_pagResource);
        setLoading(false);
        
        if (scrollRef && scrollRef.current){
          if (clientHeight !== 0) {
            scrollRef.current.scrollTop = clientHeight;
          }
        }

      } else setMyDocumental(res);
      setRetrieving(false);
      setLoading(false);
    } catch (error) {
      showError('Impossibile scaricare documentale');
      setLoading(false);
    }
  }

  useEffect(() => {
    switch (props.permission) {
      case PERMISSIONS.MENU_LOGISTIC_DOCUMENTS:
        setDocumentalType(DOCUMENTAL.DDT);
        getDocumental(DOCUMENTAL.DDT, 0, sortModifier);
        break;
      case PERMISSIONS.MENU_DOCUMENTARY:
      default:
        setDocumentalType(DOCUMENTAL.ORDER);
        getDocumental(DOCUMENTAL.ORDER, 0, sortModifier);
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e: any) => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= 1;
    //mi salvo l'altezza per scrollare a quel punto
    setClientHeight(e.target.scrollTop);
    if (bottom && pageNumber < maxPageNumber && !retrieving) {
      setRetrieving(true);
      //incremento il numero di pagina
      setPageNumber(pageNumber + 1);
      //contatto il backend per avere la pagina numero "pageNumber"
      getDocumental(documentalType!, pageNumber + 1, sortModifier);
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      <Container>
          <HeaderText>{i18n.t(MenuLinkDomain.get(props.permission)?.label!)}</HeaderText>
          <Spacer /> <Spacer /> <Spacer />
          <HeaderFirstLine>
            <HeaderSelect
              showSearch
              optionFilterProp="children"
              onChange={(value: string) => {
                setSortModifier(value);
                getDocumental(documentalType!, 0, value);
              }}
              defaultValue={OrderByDomain[1].value}
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { OrderByDomain.map((o, index) => {
                return (<Option key={index} value={o.value}>{i18n.t(o.label)}</Option>);
              })}
            </HeaderSelect>
            <HeaderSelect
              showSearch
              optionFilterProp="children"
              onChange={(value) => {
                if (value !== documentalType) {
                  setPageNumber(0);
                }
                setDocumentalType(value);
                getDocumental(value, 0, sortModifier);
              }}
              defaultValue={documentalType}
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { DocumentalDomain
                  .filter((d) => {return d.permissions.includes(props.permission);})
                  .map((d, index) => {return (<Option key={index} value={d.value}>{i18n.t(d.label)}</Option>);})
              }
            </HeaderSelect>
          </HeaderFirstLine>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '42vw',
              marginBottom: '2vw',
            }}
          >
            <ColHeader><HeaderSubText>{i18n.t('documentalPage.category.category1')}</HeaderSubText></ColHeader>
            <ColHeader><HeaderSubText>{i18n.t('documentalPage.category.category2')}</HeaderSubText></ColHeader>
            <ColHeader><HeaderSubText>{i18n.t('documentalPage.category.category3')}</HeaderSubText></ColHeader>
          </div>
          <div 
            ref={scrollRef} 
            onScroll={handleScroll} 
            style={{ overflowY: 'scroll', height: '52vh', width: '42vw' }}
          >
            {!loading &&
              myDocumental &&
              myDocumental.elements.length > 0 &&
              myDocumental.elements.map((n, index) => {
                return (
                  <DocumentalRow
                    key={index}
                    onClick1={() => { }}
                    number={n.number}
                    date={n.creationDate}
                    type={n.type}
                  />
                )
              })}
            {loading && <LoadingIcons />}
            {!loading && myDocumental && myDocumental.elements.length === 0 && 
              <EmptyRow text={i18n.t(DocumentalDomain.find((d) => {return d.value.match(documentalType!)})?.label!)} />
            }
          </div>
      </Container>
    </div>
  )
}

const Container = styled.div`
  background-color: white;
  margin-left: 9.444vw;
  margin-top: 8vh;
`
const HeaderText = styled.h3`
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;

  display: flex;
  align-items: center;

  color: #000000;
  margin-top: 56px;
  margin-left: 2.222vw;
`
const HeaderSubText = styled.h3`
  width: 14vw;
  margin: auto;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw;
  text-align: center;
  color: #000000;
`
const HeaderSelect = styled(Select)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //padding: 8px 24px;
  padding: 1.5%;
  width: 10.278vw;
  height: 5vh;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 24px;
  align-items: center;
  margin-left: 2.222vw;
`
const HeaderFirstLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`

const ColHeader = styled.div`
  width: 13vw;
  text-align: center;
`
