import RecentSearchRow from "./RecentSearchRow"
import { RecentSearchResponse } from "types"

export default function RecentSearchComponent(props: {
  recentSearch: RecentSearchResponse[]
  handleClickProductFromRecentSearch: (itemId: string, itemDescription: string) => void
}) {

  return (
    <>
      {props.recentSearch?.map((n, index) => {
        return (
          <RecentSearchRow
            key={index}
            onClick={() => {
              props.handleClickProductFromRecentSearch(n.itemId, n.itemDescription)
            }}
            element={n.itemDescription}
            itemId={n.itemId}
          />
        )
      })}
    </>
  )
}