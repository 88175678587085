import { AllOrderResponse, OrderResponse, PaginationResource } from 'types';
import { api, responseErrorCheck } from './api';
import Cookies from 'universal-cookie';

export default class Orders {
  //Get paginated list of orders
  static getOrders(page: number, itemPerPage: number, sortModifier?: string) {
    const cookies = new Cookies()
    let url =
      'erpusers/' + cookies.get('loggedErpUser') +
      '/orders?page=' + page +
      '&itemPerPage=' + itemPerPage;
    url = sortModifier ? url + '&orderBy=Document_Date&sortModifier=' + sortModifier : url;
    return api
      .get<PaginationResource<AllOrderResponse> | undefined>(url, {})
      .then((res) => responseErrorCheck(res));
  }

  //Get details from specific order
  static getOrderById(orderId: string) {
    const cookies = new Cookies();
    return api
      .get<OrderResponse[]>('erpusers/' + cookies.get('loggedErpUser') + '/orders/' + orderId, { orderId })
      .then((res) => responseErrorCheck(res));
  }
}
